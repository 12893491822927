<template>
  <v-app>
    <v-app-bar app clipped-right flat color="white" height="96">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-tooltip
        v-if="$can('calendar_events_access')"
        close-delay="200"
        bottom
        z-index="99"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            to="/backend/apps/calendar"
            active-class="cta white--text"
          >
            <v-icon>{{ icons.mdiCalendar }}</v-icon>
          </v-btn>
        </template>
        Kalender
      </v-tooltip>
      <v-tooltip
        v-if="$can('possible_duplicates_access')"
        close-delay="200"
        bottom
        z-index="99"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :to="{ name: 'list-duplicates' }"
            active-class="cta white--text"
          >
            <v-badge
              bordered
              color="info"
              overlap
              :value="duplicatesCount"
              :content="duplicatesCount"
            >
              <v-icon>{{ icons.mdiAccountSwitch }}</v-icon>
            </v-badge>
          </v-btn>
        </template>
        Duplikate
      </v-tooltip>
      <v-menu
        offset-x
        offset-y
        min-width="400"
        max-width="400"
        max-height="800"
        :close-on-content-click="false"
        @input="fetchDurchwahlen"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>{{ icons.mdiInformation }}</v-icon>
          </v-btn>
        </template>
        <v-card :loading="loadingDurchwahlen" class="pa-5">
          <v-sheet outlined rounded class="pa-5">
            <p class="mb-0">Zentrale: 04841-96141-0</p>
            <p class="mb-0">Fax: 04841-96141-21</p>
            <p class="mb-0">UST-ID Nummer: DE233 992 000</p>
          </v-sheet>
          <v-divider class="my-4" />
          <v-sheet
            v-for="(mitarbeiter, abteilung, index) in durchwahlen"
            :key="`abteilung-${index}`"
            outlined
            rounded
            class="pa-5 mb-4"
          >
            <h4>{{ abteilung.toUpperCase() }}</h4>
            <v-list dense>
              <v-list-item
                v-for="(user, i) in mitarbeiter"
                :key="`mitarbeiter-${i}`"
                class="px-0"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.vorname }} {{ user.nachname }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Telefon: {{ user.telefonnr }}<br />
                    Mobil: {{ user.mobilnr }}<br />
                    E-Mail: {{ user.email }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-sheet>
        </v-card>
      </v-menu>
      <v-spacer />
      <div class="d-flex flex-grow-1 searchbar">
        <v-text-field
          v-if="$vuetify.breakpoint.mdAndUp"
          v-model="searchQuery"
          dense
          :append-icon="
            searchAssociatedOnly
              ? icons.mdiFormatListGroup
              : icons.mdiFormatListBulleted
          "
          hide-details
          :loading="searching"
          :placeholder="searchFieldPlaceholder"
          :prepend-inner-icon="icons.mdiMagnify"
          :disabled="!modelByRoute && !searchFilter"
          outlined
          clearable
          color="cta"
          class="align-center"
          @click:clear="onResetSearch"
          @click:append="sendSearchQueryImmediately(true)"
          @keydown.enter.stop="sendSearchQueryImmediately"
          @input="sendSearchQuery"
        >
          <template v-slot:append-outer>
            <v-menu v-if="!modelByRoute" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>{{ icons.mdiFilter }}</v-icon>
                </v-btn>
              </template>
              <v-list dense nav>
                <v-list-item-group v-model="searchFilter">
                  <v-list-item
                    v-for="(filter, index) in searchFilterItems"
                    :key="`filter-${index}`"
                    :value="filter.value"
                  >
                    <v-list-item-title>
                      {{ filter.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </v-text-field>
        <SearchResults
          :value="showResults"
          :model="searchModel"
          :search-query="searchQuery"
          :search-results="searchResults"
          @select="onResetSearch"
          class="searchresults"
        />
      </div>
      <v-spacer />

      <!-- Status Button -->
      <UserStatusButton v-if="!$hasPermission(authUser, 'Tchibo')" />

      <!-- Aktivitäten / Mitteilungen -->
      <v-menu
        v-if="!$hasPermission(authUser, 'Tchibo')"
        v-model="activitiesMenu"
        offset-x
        offset-y
        :close-on-content-click="false"
        min-width="400"
        max-width="400"
        max-height="800"
        z-index="100"
      >
        <template v-slot:activator="{ on: activities, attrs }">
          <v-btn icon v-bind="attrs" v-on="activities">
            <v-badge
              bordered
              color="error"
              overlap
              :content="unreadActivities.length"
              :value="unreadActivities.length"
              right
            >
              <v-icon>{{ icons.mdiBell }}</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <ActivitiesContainer />
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="drawer"
      color="blue-grey darken-3"
      :temporary="$vuetify.breakpoint.smAndDown"
      :fixed="$vuetify.breakpoint.smAndDown"
    >
      <div class="sidebar-logo pa-4">
        <img
          src="@/assets/img/talis_logo_weiss_gelb.svg"
          alt="Talis Logo"
          style="max-width: 50%"
        />
        <p class="caption white--text text-center mt-2 mb-0">THEKLA</p>
      </div>

      <v-divider />

      <v-list nav dark dense>
        <template v-for="(link, index) in sidebarRoutes">
          <v-list-group
            v-if="!link.gate || (link.children && $can(link.gate))"
            :key="`group-${index}`"
            v-model="link.open"
            active-class="light-blue darken-4 white--text"
          >
            <template v-slot:prependIcon>
              <v-icon :color="link.open ? 'cta' : 'blue-grey lighten-5'">{{
                link.icon
              }}</v-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-title
                class="blue-grey--text text--lighten-5 text-body-2"
                >{{ link.name }}</v-list-item-title
              >
              <v-badge
                v-if="link.name === 'Akquise'"
                color="info"
                dot
                :value="bauberatungenCount || katalogbestellungenCount"
              >
              </v-badge>
            </template>
            <template v-for="(child, i) in link.children">
              <v-list-item
                v-if="!child.gate || $can(child.gate)"
                :key="`child-${i}`"
                :to="child.path"
                exact
                exact-active-class="light-blue darken-4 white--text"
              >
                <v-list-item-icon>
                  <v-icon v-if="child.icon" color="blue-grey lighten-5">{{
                    child.icon
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  class="blue-grey--text text--lighten-5 text-body-2"
                >
                  {{ child.name }}
                </v-list-item-title>
                <v-badge
                  v-if="child.name === 'Kataloganfragen'"
                  color="info"
                  inline
                  :value="katalogbestellungenCount"
                  :content="katalogbestellungenCount"
                ></v-badge>
                <v-badge
                  v-if="child.name === 'Bauberatungsanfragen'"
                  color="info"
                  inline
                  :value="bauberatungenCount"
                  :content="bauberatungenCount"
                ></v-badge>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-item
            v-else-if="!link.gate || $can(link.gate)"
            :key="`link-${index}`"
            :to="link.path"
            active-class="light-blue darken-4 white--text"
            exact-active-class="light-blue darken-4 white--text"
          >
            <v-list-item-icon>
              <v-icon
                :color="
                  $route.matched.some((route) => route.path.includes(link.path))
                    ? 'cta'
                    : 'blue-grey lighten-5'
                "
                >{{ link.icon }}</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="blue-grey--text text--lighten-5 text-body-2"
              >
                {{ link.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <div class="user-panel blue-grey">
        <v-expansion-panels accordion tile>
          <v-expansion-panel class="transparent white--text">
            <v-expansion-panel-header class="pa-3">
              <template #actions>
                <v-icon color="white"> {{ icons.mdiChevronUp }} </v-icon>
              </template>
              <div class="d-flex flex-grow-1 align-center">
                <v-avatar size="40" color="cta">
                  <span class="white--text font-weight-bold">{{
                    userInitials
                  }}</span>
                </v-avatar>
                <div v-if="authUser" class="user-data ml-4">
                  {{ authUser.vorname }} {{ authUser.nachname }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item-group>
                  <template v-for="item in expansionPanelRoutes">
                    <v-list-item
                      v-if="$can(item.gate)"
                      :key="item.path"
                      :to="item.path"
                    >
                      <v-list-item-icon
                        ><v-icon color="white">{{
                          item.icon
                        }}</v-icon></v-list-item-icon
                      >
                      <v-list-item-content>
                        <v-list-item-title class="white--text">{{
                          item.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-item @click="onLogout">
                    <v-list-item-icon
                      ><v-icon color="white">{{
                        icons.mdiLogout
                      }}</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title class="white--text"
                        >Abmelden</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-navigation-drawer>
    <v-main>
      <router-view v-if="ready" :key="$route.fullPath" />
    </v-main>
    <!-- Notifications -->
    <NotificationsContainer />
    <AutoLogout />
    <v-overlay
      v-if="
        (ready === false &&
          (loadingAuthUser ||
            loadingKatalogbestellungen ||
            loadingBauberatungen ||
            loadingActivityNotifications)) ||
        (ready === true && loadingLogout)
      "
      color="white"
      opacity="1"
      class="text-center blue-grey--text"
      z-index="99"
    >
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <p v-if="loadingAuthUser" class="mt-4">Benutzerdaten werden geladen...</p>
      <p v-else-if="loadingKatalogbestellungen" class="mt-4">
        Katalogbestellungen werden geladen...
      </p>
      <p v-else-if="loadingBauberatungen" class="mt-4">
        Bauberatungen werden geladen...
      </p>
      <p v-else-if="loadingActivityNotifications" class="mt-4">
        Mitteilungen werden geladen...
      </p>
      <p v-else class="mt-4">Logout...</p>
    </v-overlay>
    <UpdateAvailableSnackbar />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import isEmpty from "lodash-es/isEmpty";
import debounce from "lodash-es/debounce";
import { DateTime } from "luxon";
import ApiService from "@/services/ApiService";

import NotificationsContainer from "@/components/notifications/NotificationsContainer.vue";
import SearchResults from "@/components/search/SearchResults.vue";
import UserStatusButton from "@/components/UserStatusButton.vue";
import ActivitiesContainer from "@/components/activities/ActivitiesContainer.vue";
import AutoLogout from "@/components/AutoLogout.vue";
import UpdateAvailableSnackbar from "../components/UpdateAvailableSnackbar.vue";

import {
  mdiAccountMultiple,
  mdiAccountCheck,
  mdiAccountCash,
  mdiViewDashboard,
  mdiCalendar,
  mdiInformation,
  mdiFormatListGroup,
  mdiFormatListBulleted,
  mdiMagnify,
  mdiAccountAlert,
  mdiTicket,
  mdiBell,
  mdiChevronDown,
  mdiChevronUp,
  mdiLogout,
  mdiDatabase,
  mdiBasket,
  mdiAccountCircle,
  mdiTextBoxMultiple,
  mdiCog,
  mdiDomain,
  mdiCardAccountDetails,
  mdiCash,
  // mdiWrenchClock,
  mdiFilter,
  mdiFinance,
  mdiAccountGroup,
  mdiAccountSwitch,
  mdiFileDocumentMultiple,
  // mdiPalmTree,
} from "@mdi/js";
import { getError } from "@/util/helpers";

export default {
  name: "Backend",
  data: () => ({
    icons: {
      mdiAccountCheck,
      mdiAccountCash,
      mdiCalendar,
      mdiInformation,
      mdiFormatListGroup,
      mdiFormatListBulleted,
      mdiMagnify,
      mdiAccountAlert,
      mdiTicket,
      mdiBell,
      mdiChevronDown,
      mdiChevronUp,
      mdiLogout,
      mdiDatabase,
      mdiBasket,
      mdiAccountCircle,
      mdiTextBoxMultiple,
      mdiCog,
      mdiDomain,
      mdiCardAccountDetails,
      mdiFilter,
      mdiAccountSwitch,
    },
    drawer: true,
    sidebarRoutes: [
      {
        path: "/backend/dashboard",
        name: "Übersicht",
        icon: mdiViewDashboard,
        gate: "dashboard_access",
        open: false,
        exact: true,
      },
      {
        path: "/backend/users/interessenten",
        name: "Interessenten",
        icon: mdiAccountMultiple,
        gate: "interessenten_access",
        exact: true,
      },
      {
        path: "/backend/users/customers",
        name: "Kunden",
        icon: mdiAccountCheck,
        gate: "kunden_access",
        exact: true,
      },
      {
        path: "/backend/bauvorhaben",
        name: "Bauvorhaben",
        icon: mdiDomain,
        gate: "bauvorhaben_access",
        exact: true,
        children: [
          {
            path: "/backend/bauvorhaben",
            name: "Übersicht",
            gate: "bauvorhaben_access",
            exact: true,
          },
          {
            path: "/backend/bauvorhaben/planung",
            name: "Planung",
            gate: "bauvorhaben_planung_access",
            exact: true,
          },
          {
            path: "/backend/bauvorhaben/calendar",
            name: "Kalender",
            gate: "bauvorhaben_planung_access",
            exact: true,
          },
        ],
      },
      {
        path: "/backend/suppliers",
        name: "Dienstleister",
        icon: mdiCardAccountDetails,
        gate: "suppliers_access",
        exact: false,
      },
      {
        path: "/backend/invoices/all",
        name: "Abrechnung",
        icon: mdiAccountCash,
        gate: "invoices_access",
        exact: false,
      },
      {
        name: "Akquise",
        icon: mdiDatabase,
        open: false,
        gate: "bauberatungen_access",
        children: [
          {
            path: "/backend/katalogbestellungen",
            name: "Kataloganfragen",
            gate: "katalogbestellungen_access",
            exact: true,
          },
          {
            path: "/backend/bauberatungen",
            name: "Bauberatungsanfragen",
            gate: "bauberatungen_access",
            exact: true,
          },
        ],
      },
      {
        name: "Vertrieb",
        icon: mdiBasket,
        open: false,
        children: [
          {
            path: "/backend/wiedervorlagen",
            name: "Wiedervorlagen",
            gate: "wiedervorlagen_access",
            exact: true,
          },
          {
            path: "/backend/referenzen",
            name: "Referenzen",
            exact: true,
          },
        ],
      },
      // {
      //   path: "/backend/tickets",
      //   name: "Tickets",
      //   icon: mdiTicket,
      //   gate: "tickets_access",
      //   visibleFor: ["*"],
      // },
      {
        name: "Verwaltung",
        icon: mdiAccountGroup,
        open: false,
        gate: "timesheets_access",
        children: [
          {
            path: "/backend/timesheets",
            name: "Stunden",
            gate: "timesheets_access",
            exact: true,
          },
          {
            path: "/backend/vacation",
            name: "Urlaub",
            gate: "timesheets_access",
            exact: true,
          },
        ],
      },
      {
        path: "/backend/commissions",
        name: "Provisionen",
        icon: mdiCash,
        gate: "commissions_access",
        exact: true,
      },
      {
        path: "/backend/files",
        name: "Dateiablage",
        icon: mdiFileDocumentMultiple,
        gate: "files_access",
        exact: true,
      },
      {
        name: "Statistik",
        icon: mdiFinance,
        gate: "statistics_access",
        open: false,
        children: [
          {
            path: "/backend/statistics/finanzvorschau",
            name: "Finanzvorschau",
            gate: "statistics_access",
            exact: true,
          },
          {
            path: "/backend/statistics",
            name: "Jahresumsätze",
            gate: "statistics_access",
            exact: true,
          },
          {
            path: "/backend/statistics/finanzauswertung",
            name: "Finanzauswertung",
            gate: "statistics_access",
            exact: true,
          },
          {
            path: "/backend/statistics/ertragsauswertung",
            name: "Ertragsauswertung",
            gate: "statistics_access",
            exact: true,
          },
        ],
      },
      // {
      //   path: "/backend/mitteilungen",
      //   name: "Mitteilungen",
      //   icon: mdiBell,
      //   visibleFor: ["*"],
      // },
      // {
      //   path: "/backend/webmail/inbox",
      //   name: "Posteingang",
      //   icon: mdiInbox,
      //   visibleFor: ["*"],
      // },
    ],
    expansionPanelRoutes: [
      {
        path: "/backend/einstellungen/profil",
        name: "Dein Profil",
        icon: mdiAccountCircle,
        gate: "profile_access",
        open: false,
      },
      {
        path: "/backend/einstellungen/vorlagen/emails",
        name: "Vorlagen",
        icon: mdiTextBoxMultiple,
        gate: "emailtemplates_access",
        open: false,
      },
      {
        path: "/backend/users/mitarbeiter",
        name: "Benutzerverwaltung",
        icon: mdiAccountMultiple,
        gate: "mitarbeiter_access",
        open: false,
      },
      {
        path: "/backend/einstellungen/system",
        name: "System",
        icon: mdiCog,
        gate: "system_settings_access",
        open: false,
      },
    ],
    activitiesMenu: false,
    searchFilterItems: [
      { text: "Interessenten", value: "User" },
      { text: "Kunden", value: "Customer" },
      { text: "Bauvorhaben", value: "Bauvorhaben" },
    ],
    searchFilter: null,
    searchQuery: null,
    searchAssociatedOnly: true,
    searchResults: [],
    searching: false,
    showResults: false,
    durchwahlen: [],
    loadingDurchwahlen: false,
    loadingKatalogbestellungen: false,
    loadingBauberatungen: false,
    ready: false,
  }),
  components: {
    UpdateAvailableSnackbar,
    AutoLogout,
    UserStatusButton,
    ActivitiesContainer,
    NotificationsContainer,
    SearchResults,
  },
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    userInitials() {
      return !isEmpty(this.authUser)
        ? this.authUser.vorname.charAt(0).toUpperCase() +
            this.authUser.nachname.charAt(0).toUpperCase()
        : "";
    },
    awaitingResponse() {
      return this.$store.state.loading.awaitResponse;
    },
    modelByRoute() {
      if (this.$route.path.includes("interessenten")) {
        return "User";
      } else if (this.$route.path.includes("customers")) {
        return "Customer";
      } else if (this.$route.path.includes("bauvorhaben")) {
        return "Bauvorhaben";
      }

      return null;
    },
    searchModel() {
      return this.searchFilter || this.modelByRoute;
    },
    searchFieldPlaceholder() {
      let placeholder = "";
      if (this.modelByRoute === "User" || this.searchFilter === "User") {
        placeholder = this.searchAssociatedOnly
          ? "Finde deine Interessenten..."
          : "Finde alle Interessenten...";
      } else if (
        this.modelByRoute === "Customer" ||
        this.searchFilter === "Customer"
      ) {
        placeholder = this.searchAssociatedOnly
          ? "Finde deine Kunden..."
          : "Finde alle Kunden...";
      } else if (
        this.modelByRoute === "Bauvorhaben" ||
        this.searchFilter === "Bauvorhaben"
      ) {
        placeholder = this.searchAssociatedOnly
          ? "Finde deine Bauvorhaben..."
          : "Finde alle Bauvorhaben...";
      } else {
        placeholder = "Wähle zuerst einen Filter aus...";
      }

      return placeholder;
    },
    duplicatesCount() {
      return this.$store.state.duplicates.count;
    },
    tickets() {
      return this.$store.state.tickets.tickets;
    },
    bauberatungenCount() {
      return this.$store.state.bauberatungen.unfinsihedCount;
    },
    katalogbestellungenCount() {
      return this.$store.state.catalogueRequests.count;
    },
    loading() {
      return this.$store.state.loading.logout;
    },
    loadingAuthUser() {
      return this.$store.state.loading.authUser;
    },
    loadingActivityNotifications() {
      return this.$store.state.loading.activityNotifications;
    },
    loadingLogout() {
      return this.$store.state.loading.logout;
    },
    ...mapGetters("activities", ["unreadActivities"]),
  },
  async mounted() {
    this.initializeWsChannel();
    await this.fetchAuthUser().then(async () => {
      if (this.authUser.twoFactorAuthenticated) {
        if (this.$hasPermission(this.authUser, ["Verwaltung", "Verkauf"])) {
          await this.fetchKatalogbestellungen();
          await this.fetchBauberatungen();
        }
        if (this.$can("possible_duplicates_access")) {
          await this.fetchDuplicatesAndSetCount();
        }
        await this.fetchDurchwahlen();
        await this.fetchActivities();
        this.ready = true;
      } else {
        // Redirect to two factor challenge
        const query = {
          type: this.authUser.twoFactorType,
        };

        if (this.authUser.twoFactorType === "mail") {
          query.resend = true;
        }

        await this.$router.push({
          name: "login-two-factor-challenge",
          query: query,
        });
      }
    });
  },
  watch: {
    activitiesMenu() {
      if (this.activitiesMenu) {
        this.fetchActivities();
      }
    },
    searchQuery(val) {
      if (!val || val.length === 0) {
        this.onResetSearch();
      }
    },
    $route: {
      async handler() {
        await this.fetchAbilities();
      },
      immediate: true,
    },
  },
  methods: {
    initializeWsChannel() {
      let notificationMessage;
      let messageBody;

      Notification.requestPermission();

      this.$echo
        .private(`App.Models.User.${this.authUser.id}`)
        .listen("NewCatalogueRequest", () => {
          this.fetchKatalogbestellungen();
        })
        .listen("DuplicateCreatedOrUpdated", () => {
          this.fetchDuplicatesAndSetCount();
        })
        .listen("MitarbeiterUpdated", async () => {
          await this.fetchAuthUser();
          await this.fetchAbilities();
        })
        .notification((notification) => {
          if (
            notification.type ===
              "App\\Notifications\\CalendarEventReminderNotification" &&
            notification.calendarEvent.notificationType === "Benachrichtigung"
          ) {
            if (
              notification.calendarEvent.startTime &&
              notification.calendarEvent.startDate ===
                notification.calendarEvent.endDate
            ) {
              messageBody =
                DateTime.fromISO(
                  notification.calendarEvent.startTime
                ).toLocaleString(DateTime.TIME_24_SIMPLE) +
                " - " +
                DateTime.fromISO(
                  notification.calendarEvent.endTime
                ).toLocaleString(DateTime.TIME_24_SIMPLE);
            } else if (
              notification.calendarEvent.startTime &&
              notification.calendarEvent.startDate <
                notification.calendarEvent.endDate
            ) {
              messageBody =
                DateTime.fromSQL(
                  notification.calendarEvent.start
                ).toLocaleString(DateTime.DATETIME_SHORT) +
                " - " +
                DateTime.fromSQL(notification.calendarEvent.end).toLocaleString(
                  DateTime.DATETIME_SHORT
                );
            } else {
              messageBody = DateTime.fromISO(
                notification.calendarEvent.startDate
              ).toLocaleString(DateTime.DATE_SHORT);
            }

            if (!("Notification" in window)) {
              notificationMessage = {
                type: "info",
                title: "Terminerinnerung",
                message: messageBody,
              };
              this.$store.commit("notifications/ADD", notificationMessage);
            } else if (Notification.permission === "granted") {
              new Notification(notification.calendarEvent.name, {
                lang: "DE",
                body: messageBody,
                icon: "/img/icons/calendar_grey.png",
                requireInteraction: true,
              });
            } else if (Notification.permission !== "denied") {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  new Notification(notification.calendarEvent.name, {
                    lang: "DE",
                    body: messageBody,
                    icon: "/img/icons/calendar_grey.png",
                    requireInteraction: true,
                  });
                } else {
                  notificationMessage = {
                    type: "info",
                    title: "Terminerinnerung",
                    message: messageBody,
                  };
                  this.$store.commit("notifications/ADD", notificationMessage);
                }
              });
            }
          } else if (
            notification.type ===
            "App\\Notifications\\BauvorhabenUpdatedNotification"
          ) {
            notificationMessage = {
              type: "info",
              title: "Bauvorhaben aktualisiert",
              message: notification.message,
              timeout: 5000,
            };

            this.$store.commit("notifications/ADD", notificationMessage);

            this.fetchActivities();
          } else if (
            notification.type ===
            "App\\Notifications\\VacationUpdatedNotification"
          ) {
            notificationMessage = {
              type: "info",
              title: "Dein Urlaubsantrag wurde bearbeitet",
              message: notification.message,
              timeout: 5000,
            };

            this.$store.commit("notifications/ADD", notificationMessage);

            this.fetchActivities();
          } else if (
            notification.type ===
            "App\\Notifications\\BauberatungCreatedNotification"
          ) {
            notificationMessage = {
              type: "info",
              title: "Neue Bauberatungsanfrage",
              message: notification.message,
              timeout: 10000,
            };

            this.$store.commit("notifications/ADD", notificationMessage);

            this.fetchActivities();
          }
        });
    },
    async fetchDurchwahlen() {
      this.loadingDurchwahlen = true;
      await ApiService.fetchDurchwahlen()
        .then((res) => {
          this.durchwahlen = res.data.collection;
        })
        .finally(() => {
          this.loadingDurchwahlen = false;
        });
    },
    async fetchKatalogbestellungen() {
      this.loadingKatalogbestellungen = true;
      await ApiService.getKatalogbestellungen()
        .then((res) => {
          this.$store.commit(
            "catalogueRequests/SET_COUNT",
            res.data.meta.count
          );
        })
        .finally(() => {
          this.loadingKatalogbestellungen = false;
        });
    },
    async fetchBauberatungen() {
      this.loadingBauberatungen = true;
      await ApiService.fetchBauberatungen()
        .then((res) => {
          this.$store.commit(
            "bauberatungen/SET_UNFINISHED_COUNT",
            res.data.meta.unfinishedCount
          );
        })
        .finally(() => {
          this.loadingBauberatungen = false;
        });
    },
    sendSearchQuery: debounce(async function () {
      const data = {
        query: this.searchQuery,
        model: this.modelByRoute ? this.modelByRoute : this.searchFilter,
        associatedOnly: this.searchAssociatedOnly,
      };

      if (this.searchQuery !== null && this.searchQuery.length > 1) {
        this.searching = true;
        this.showResults = true;
        await ApiService.search(data)
          .then((res) => {
            this.searchResults = res.data.collection;
          })
          .catch((err) => {
            const notification = {
              type: "error",
              title: "Fehler",
              error: getError(err),
            };

            this.$store.commit("notifications/ADD", notification);
          })
          .finally(() => {
            this.searching = false;
          });
      } else {
        this.searchResults = [];
      }
    }, 1000),
    async sendSearchQueryImmediately(click = null) {
      if (click === true) {
        this.searchAssociatedOnly = !this.searchAssociatedOnly;
      }

      const data = {
        query: this.searchQuery,
        model: this.modelByRoute ? this.modelByRoute : this.searchFilter,
        associatedOnly: this.searchAssociatedOnly,
      };

      if (this.searchQuery !== null && this.searchQuery.length > 1) {
        this.searching = true;
        this.showResults = true;
        await ApiService.search(data)
          .then((res) => {
            this.searchResults = res.data.collection;
          })
          .catch((err) => {
            const notification = {
              type: "error",
              title: "Fehler",
              error: getError(err),
            };

            this.$store.commit("notifications/ADD", notification);
          })
          .finally(() => {
            this.searching = false;
          });
      } else {
        this.searchResults = [];
      }
    },
    async fetchAbilities() {
      await ApiService.fetchUserAbilities().then((res) => {
        this.$ability.update([{ subject: "all", action: res.data.data }]);
      });
    },
    commitNotificationAndFetchActivities(notification) {
      this.$store.commit("notifications/ADD", notification);
      this.fetchActivities();
    },
    onResetSearch() {
      this.searchQuery = null;
      this.searchFilter = null;
      this.showResults = false;
    },
    async onLogout() {
      await this.$store.dispatch("loading/logout", true);
      await ApiService.logout()
        .then(() => {
          this.onResetSearch();
          this.$store.dispatch("activities/reset");
          this.$store.dispatch("templates/reset");
          this.$store.dispatch("tickets/reset");
          this.$store.dispatch("user/resetState");
          this.$store.commit("userStatus/SET_STATUSES", []);
          this.$router.replace("/login");
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.$store.dispatch("loading/logout", false);
        });
    },
    ...mapActions("user", ["fetchAuthUser"]),
    ...mapActions("activities", ["fetchActivities"]),
    ...mapActions("search", ["search", "reset"]),
    ...mapActions("duplicates", ["fetchDuplicatesAndSetCount"]),
  },
  beforeRouteUpdate(to, from, next) {
    this.onResetSearch();
    next();
  },
};
</script>

<style lang="scss" scoped>
.sidebar-logo {
  text-align: center;
}
.searchbar {
  position: relative;
}
.searchresults {
  position: absolute;
  top: 56px;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 100;
}
.list-item--wrap {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
.user-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.disabled--active {
  &::before {
    opacity: 0;
  }
  &:hover {
    &::before {
      opacity: 0.08;
    }
  }
}
@media screen and (min-width: 1264px) {
  .v-list-group__items {
    padding-left: 2rem;
  }
}
</style>
