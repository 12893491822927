<template>
  <v-container class="tickets-layout">
    <router-view :key="$route.fullPath" />
  </v-container>
</template>

<script>
export default {
  name: "Layout",
};
</script>

<style scoped></style>
