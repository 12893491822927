import ApiService from "@/services/ApiService";
// import router from "@/router";

export const namespaced = true;

export const state = {
  results: {
    users: [],
  },
  showResults: false,
};

export const mutations = {
  SET_RESULTS(state, results) {
    state.results = results;
  },
  SET_SHOW_RESULTS(state, status) {
    state.showResults = status;
  },
  RESET_STATE(state) {
    state.results = {
      users: [],
    };
    state.showResults = false;
  },
};

export const actions = {
  async search({ commit, dispatch }, data) {
    dispatch("loading/awaitingResponse", true, { root: true });

    if (data.model === "User") {
      dispatch("loading/loadingInteressenten", true, { root: true });
    }
    if (data.model === "Ticket") {
      dispatch("loading/loadingTickets", true, { root: true });
    }

    await ApiService.search(data)
      .then((res) => {
        commit("SET_RESULTS", res.data.collection);
        if (data.model) {
          if (data.model === "User") {
            commit("user/SET_INTERESSENTEN", res.data.data, { root: true });
            commit("user/SET_PAGINATION", res.data.meta, { root: true });
          } else if (data.model === "Ticket") {
            commit("tickets/SET_TICKETS", res.data.data, { root: true });
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
        if (data.model === "User") {
          dispatch("loading/loadingInteressenten", false, { root: true });
        }
        if (data.model === "Ticket") {
          dispatch("loading/loadingTickets", false, { root: true });
        }
      });
  },
  showResults({ commit }, payload) {
    commit("SET_SHOW_RESULTS", payload);
  },
  reset({ commit }) {
    commit("RESET_STATE");
  },
};
