import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  roles: [],
  role: {},
  permissions: [],
  permission: {},
};

export const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_ROLE(state, role) {
    state.role = role;
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
  SET_PERMISSION(state, permission) {
    state.permission = permission;
  },
};

export const actions = {
  fetchRoles({ commit }) {
    ApiService.fetchAllRoles().then((res) => {
      commit("SET_ROLES", res.data);
      // localStorage.setItem("globalRoles", state.roles);
    });
  },
  fetchPermissions({ commit }) {
    ApiService.fetchAllPermissions().then((res) => {
      commit("SET_PERMISSIONS", res.data);
      // localStorage.setItem("globalPermissions", state.permissions);
    });
  },
};
