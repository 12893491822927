<template>
  <div>
    <v-row class="fill-height">
      <v-col cols="12" lg="6">
        <DashboardWidgetWiedervorlagen />
      </v-col>
      <v-col cols="12" lg="6">
        <DashboardWidgetTermine />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardWidgetWiedervorlagen from "@/components/dashboards/widgets/DashboardWidgetWiedervorlagen.vue";
import DashboardWidgetTermine from "@/components/dashboards/widgets/DashboardWidgetTermine.vue";

export default {
  name: "DashboardVertrieb",
  components: {
    DashboardWidgetTermine,
    DashboardWidgetWiedervorlagen,
  },
};
</script>

<style scoped></style>
