export const namespaced = true;

export const state = {
  authUser: false,
  users: false,
  association: false, // Kunden auf Verkäufer verteilen
  generation: false, // Anschreiben generieren
  interessenten: false,
  kunden: false,
  bauvorhaben: false,
  invoices: false,
  mitarbeiter: false,
  verkaeufer: false,
  planung: false,
  sachbearbeiter: false,
  singleUser: false,
  tickets: false,
  ticket: false,
  recentTickets: false,
  updateTicket: false,
  wiedervorlagen: false,
  // ticketComments: false,
  ticketNewComment: false,
  emailtemplates: false,
  duplicates: false,
  activityNotifications: false,
  causedActions: false,
  calendarEvents: false,
  sortings: false,
  statuses: false,
  awaitResponse: false,
  logout: false,
};

export const mutations = {
  LOADING_AUTHUSER(state, payload) {
    state.authUser = payload;
  },
  LOADING_USERS(state, payload) {
    state.users = payload;
  },
  LOADING_ASSOCIATION(state, payload) {
    state.association = payload;
  },
  LOADING_GENERATION(state, payload) {
    state.generation = payload;
  },
  LOADING_INTERESSENTEN(state, payload) {
    state.interessenten = payload;
  },
  LOADING_KUNDEN(state, payload) {
    state.kunden = payload;
  },
  LOADING_BAUVORHABEN(state, payload) {
    state.bauvorhaben = payload;
  },
  LOADING_INVOICES(state, payload) {
    state.invoices = payload;
  },
  LOADING_MITARBEITER(state, payload) {
    state.mitarbeiter = payload;
  },
  LOADING_VERKAEUFER(state, payload) {
    state.verkaeufer = payload;
  },
  LOADING_PLANUNG(state, payload) {
    state.planung = payload;
  },
  LOADING_SACHBEARBEITER(state, payload) {
    state.sachbearbeiter = payload;
  },
  LOADING_SINGLE_USER(state, payload) {
    state.singleUser = payload;
  },
  LOADING_TICKET(state, payload) {
    state.ticket = payload;
  },
  LOADING_TICKETS(state, payload) {
    state.tickets = payload;
  },
  LOADING_RECENT_TICKETS(state, payload) {
    state.recentTickets = payload;
  },
  LOADING_UPDATE_TICKET(state, payload) {
    state.updateTicket = payload;
  },
  LOADING_WIEDERVORLAGEN(state, payload) {
    state.wiedervorlagen = payload;
  },
  LOADING_TICKET_NEW_COMMENT(state, payload) {
    state.ticketNewComment = payload;
  },
  LOADING_EMAILTEMPLATES(state, payload) {
    state.emailtemplates = payload;
  },
  LOADING_DUPLICATES(state, payload) {
    state.duplicates = payload;
  },
  LOADING_ACTIVITY_NOTIFICATIONS(state, payload) {
    state.activityNotifications = payload;
  },
  LOADING_CAUSED_ACTIONS(state, payload) {
    state.causedActions = payload;
  },
  LOADING_CALENDAR_EVENTS(state, payload) {
    state.calendarEvents = payload;
  },
  LOADING_SORTINGS(state, payload) {
    state.sortings = payload;
  },
  LOADING_STATUSES(state, payload) {
    state.statuses = payload;
  },
  AWAITING_RESPONSE(state, payload) {
    state.awaitResponse = payload;
  },
  LOGOUT(state, payload) {
    state.logout = payload;
  },
};

export const actions = {
  loadingAuthUser({ commit }, payload) {
    commit("LOADING_AUTHUSER", payload);
  },
  loadingUsers({ commit }, payload) {
    commit("LOADING_USERS", payload);
  },
  loadingAssociation({ commit }, payload) {
    commit("LOADING_ASSOCIATION", payload);
  },
  loadingGeneration({ commit }, payload) {
    commit("LOADING_GENERATION", payload);
  },
  loadingInteressenten({ commit }, payload) {
    commit("LOADING_INTERESSENTEN", payload);
  },
  loadingKunden({ commit }, payload) {
    commit("LOADING_KUNDEN", payload);
  },
  loadingMitarbeiter({ commit }, payload) {
    commit("LOADING_MITARBEITER", payload);
  },
  loadingVerkaeufer({ commit }, payload) {
    commit("LOADING_VERKAEUFER", payload);
  },
  loadingPlanung({ commit }, payload) {
    commit("LOADING_PLANUNG", payload);
  },
  loadingSachbearbeiter({ commit }, payload) {
    commit("LOADING_SACHBEARBEITER", payload);
  },
  loadingSingleUser({ commit }, payload) {
    commit("LOADING_SINGLE_USER", payload);
  },
  loadingTickets({ commit }, payload) {
    commit("LOADING_TICKETS", payload);
  },
  loadingTicket({ commit }, payload) {
    commit("LOADING_TICKET", payload);
  },
  loadingRecentTickets({ commit }, payload) {
    commit("LOADING_RECENT_TICKETS", payload);
  },
  loadingUpdateTicket({ commit }, payload) {
    commit("LOADING_UPDATE_TICKET", payload);
  },
  loadingWiedervorlagen({ commit }, payload) {
    commit("LOADING_WIEDERVORLAGEN", payload);
  },
  loadingTicketNewComment({ commit }, payload) {
    commit("LOADING_TICKET_NEW_COMMENT", payload);
  },
  loadingEmailtemplates({ commit }, payload) {
    commit("LOADING_EMAILTEMPLATES", payload);
  },
  loadingDuplicates({ commit }, payload) {
    commit("LOADING_DUPLICATES", payload);
  },
  loadingActivityNotifications({ commit }, payload) {
    commit("LOADING_ACTIVITY_NOTIFICATIONS", payload);
  },
  loadingCausedActions({ commit }, payload) {
    commit("LOADING_CAUSED_ACTIONS", payload);
  },
  loadingCalendarEvents({ commit }, payload) {
    commit("LOADING_CALENDAR_EVENTS", payload);
  },
  awaitingResponse({ commit }, payload) {
    commit("AWAITING_RESPONSE", payload);
  },
  logout({ commit }, payload) {
    commit("LOGOUT", payload);
  },
};
