import LayoutVacation from "@/views/backend/vacation/LayoutVacation.vue";

export default [
  {
    path: "vacation",
    meta: { requiresAuth: true, searchFilter: true },
    component: LayoutVacation,
    children: [
      {
        path: "/",
        name: "list-vacations",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/vacation/ListVacations.vue"),
      },
      {
        path: "new",
        name: "new-vacation",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/vacation/NewVacation.vue"),
      },
      {
        path: "accounts",
        name: "list-vacation-accounts",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/vacation/accounts/ListVacationAccounts.vue"),
      },
      {
        path: ":id/edit",
        name: "edit-vacation",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/vacation/EditVacation.vue"),
        props: true,
      },
    ],
  },
];
