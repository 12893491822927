import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  dialog: false,
  content: {
    newEmailtemplate: false,
    editEmailtemplate: false,
    deleteEmailtemplate: false,
  },
  templateList: [],
  singleTemplate: {},
};

export const mutations = {
  SET_DIALOG(state, show) {
    state.dialog = show;
  },
  SET_NEW_EMAILTEMPLATE_CONTENT(state, show) {
    state.content.newEmailtemplate = show;
    state.content.editEmailtemplate = false;
    state.content.deleteEmailtemplate = false;
  },
  SET_EDIT_EMAILTEMPLATE_CONTENT(state, show) {
    state.content.editEmailtemplate = show;
    state.content.newEmailtemplate = false;
    state.content.deleteEmailtemplate = false;
  },
  SET_DELETE_EMAILTEMPLATE_CONTENT(state, show) {
    state.content.deleteEmailtemplate = show;
    state.content.newEmailtemplate = false;
    state.content.editEmailtemplate = false;
  },
  SET_EMAILTEMPLATE_LIST(state, list) {
    state.templateList = list;
  },
  SET_SINGLE_EMAILTEMPLATE(state, singleTemplate) {
    state.singleTemplate = singleTemplate;
  },
  RESET_VALUES(state) {
    state.dialog = false;
    state.content.newEmailtemplate = false;
    state.content.editEmailtemplate = false;
    state.content.deleteEmailtemplate = false;
    state.singleTemplate = {};
  },
  RESET_STATE(state) {
    state.dialog = false;
    state.content.newEmailtemplate = false;
    state.content.editEmailtemplate = false;
    state.content.deleteEmailtemplate = false;
    state.templateList = [];
    state.singleTemplate = {};
  },
};

export const actions = {
  showEmailtemplateDialog({ commit }, val) {
    commit("SET_DIALOG", val);
  },
  showNewEmailtemplateContent({ commit }, val) {
    commit("SET_NEW_EMAILTEMPLATE_CONTENT", val);
  },
  showEditEmailtemplateContent({ commit }, val) {
    commit("SET_EDIT_EMAILTEMPLATE_CONTENT", val);
  },
  showDeleteEmailtemplateContent({ commit }, val) {
    commit("SET_DELETE_EMAILTEMPLATE_CONTENT", val);
  },
  setEmailtemplateList({ commit }, list) {
    commit("SET_EMAILTEMPLATE_LIST", list);
  },
  setSingleEmailtemplate({ commit }, template) {
    commit("SET_SINGLE_EMAILTEMPLATE", template);
  },
  resetValues({ commit }) {
    commit("RESET_VALUES");
  },

  async getEmailtemplates({ commit, dispatch }) {
    try {
      dispatch("loading/loadingEmailtemplates", true, { root: true });
      const res = await ApiService.fetchTemplates();
      commit("SET_EMAILTEMPLATE_LIST", res.data);
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    } finally {
      dispatch("loading/loadingEmailtemplates", false, { root: true });
    }
  },
  async newEmailtemplate({ commit, dispatch }, data) {
    try {
      dispatch("loading/awaitingResponse", true, { root: true });
      const res = await ApiService.storeTemplate(data);
      commit("SET_EMAILTEMPLATE_LIST", res.data);
      commit("RESET_VALUES");
      const notification = {
        type: "success",
        title: "Erfolg",
        message: "E-Mail Vorlage gespeichert.",
      };
      dispatch("notifications/add", notification, { root: true });
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    } finally {
      dispatch("loading/awaitingResponse", false, { root: true });
    }
  },
  async updateEmailtemplate({ commit, dispatch }, data) {
    try {
      dispatch("loading/awaitingResponse", true, { root: true });
      const res = await ApiService.updateTemplate(data.data, data.id);
      commit("SET_EMAILTEMPLATE_LIST", res.data);
      commit("RESET_VALUES");
      const notification = {
        type: "success",
        title: "Erfolg",
        message: "E-Mail Vorlage gespeichert.",
      };
      dispatch("notifications/add", notification, { root: true });
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    } finally {
      dispatch("loading/awaitingResponse", false, { root: true });
    }
  },
  async deleteEmailtemplate({ commit, dispatch }, id) {
    try {
      dispatch("loading/awaitingResponse", true, { root: true });
      const res = await ApiService.deleteTemplate(id);
      commit("SET_EMAILTEMPLATE_LIST", res.data);
      commit("RESET_VALUES");
      const notification = {
        type: "success",
        title: "Erfolg",
        message: "E-Mail Vorlage gelöscht.",
      };
      dispatch("notifications/add", notification, { root: true });
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    } finally {
      dispatch("loading/awaitingResponse", false, { root: true });
    }
  },
  async sendEmail({ dispatch }, { template, user, data }) {
    try {
      dispatch("loading/awaitingResponse", true, { root: true });
      await ApiService.sendNewMail(template, user, data);
      const notification = {
        type: "success",
        title: "Erledigt",
        message: "E-Mail erfolgreich versendet.",
      };
      dispatch("notifications/add", notification, { root: true });
    } catch (err) {
      const notification = {
        type: "success",
        title: "Erfolg",
        message: "E-Mail Vorlage gelöscht.",
      };
      dispatch("notifications/add", notification, { root: true });
    } finally {
      dispatch("loading/awaitingResponse", false, { root: true });
    }
  },
  reset({ commit }) {
    commit("RESET_STATE");
  },
};

export const getters = {
  getEmailtemplates: (state) => state.templateList,
  getSingleTemplate: (state) => state.singleTemplate,
};
