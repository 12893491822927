<template>
  <div>
    <v-row class="fill-height">
      <v-col cols="12">
        <DashboardWidgetKatalogbestellungen />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardWidgetKatalogbestellungen from "@/components/dashboards/widgets/DashboardWidgetKatalogbestellungen.vue";

export default {
  name: "DashboardMarketing",
  components: { DashboardWidgetKatalogbestellungen },
};
</script>

<style scoped></style>
