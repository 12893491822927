<template>
  <v-row>
    <v-col cols="12" md="2">
      <v-card outlined>
        <v-list nav dense>
          <v-list-item
            v-for="(navItem, i) in navigationItems"
            :key="`navItem-${i}`"
            :to="`/backend/einstellungen/system/${navItem.path}`"
          >
            <v-list-item-icon>
              <v-icon>{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ navItem.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="12" md="10">
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiSecurity,
  mdiShieldAccount,
  mdiAccountCash,
  mdiUpload,
  mdiBullhorn,
} from "@mdi/js";
export default {
  name: "SystemSettingsLayout",
  data() {
    return {
      navigationItems: [
        { icon: mdiShieldAccount, name: "Benutzerrollen", path: "roles" },
        { icon: mdiSecurity, name: "Systemrechte", path: "permissions" },
        { icon: mdiAccountCash, name: "Abrechnung", path: "invoices" },
        { icon: mdiBullhorn, name: "Events", path: "events" },
        { icon: mdiUpload, name: "Upload", path: "upload" },
      ],
    };
  },
};
</script>

<style scoped></style>
