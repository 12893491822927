import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  settings: {},
};

export const mutations = {
  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },
};

export const actions = {
  setSettings({ commit }, settings) {
    commit("SET_SETTINGS", settings);
  },
  async fetchSettings({ commit, dispatch }) {
    await ApiService.fetchUserSettings()
      .then((res) => {
        commit("SET_SETTINGS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      });
  },
  updateProfileSettings({ dispatch }, data) {
    dispatch("loading/awaitingResponse", true, { root: true });
    ApiService.updateProfileSettings(data)
      .then(() => {
        dispatch("user/fetchAuthUser", null, { root: true });
        dispatch("fetchSettings");
        const notification = {
          type: "success",
          title: "Erledigt",
          message: "Deine Profileinstellungen wurden gespeichert.",
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },
};

export const getters = {
  settings: (state) => state.settings,
};
