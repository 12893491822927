<template>
  <v-row>
    <v-col cols="12" md="2">
      <v-sheet outlined rounded class="sticky-top">
        <v-list rounded nav>
          <v-list-item
            v-for="(navItem, index) in navItems"
            :key="`nav-item-${index}`"
          >
            <v-list-item-icon>
              <v-icon :color="navItem.iconColor">{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title :class="{ 'mb-3': navItem.children }">
                <router-link
                  :to="navItem.path"
                  class="text-decoration-none"
                  active-class="font-weight-bold"
                  exact
                >
                  {{ navItem.title }}
                </router-link>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-col>
    <v-col cols="12" md="10">
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import { mdiEmail, mdiMail } from "@mdi/js";

export default {
  name: "LayoutTemplates",
  data() {
    return {
      navItems: [
        {
          title: "E-Mail-Vorlagen",
          path: "/backend/einstellungen/vorlagen/emails",
          iconColor: "primary",
          icon: mdiEmail,
        },
        {
          title: "Brief-Vorlagen",
          path: "/backend/einstellungen/vorlagen/briefe",
          iconColor: "primary",
          icon: mdiMail,
        },
      ],
    };
  },
};
</script>

<style scoped>
.sticky-top {
  position: sticky;
  top: 108px;
  z-index: 1000;
}
</style>
