import Vue from "vue";
import ApiService from "@/services/ApiService";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;

Vue.prototype.$echo = new Echo({
  broadcaster: "pusher",
  key: "SlgyrmnAe4D2FCnHGApX",
  wsHost: process.env.VUE_APP_LARAVEL_ECHO_HOST,
  wsPort: parseInt(process.env.VUE_APP_LARAVEL_ECHO_PORT),
  wssPort: parseInt(process.env.VUE_APP_LARAVEL_ECHO_PORT),
  cluster: "eu",
  forceTLS: process.env.VUE_APP_LARAVEL_ECHO_FORCE_TLS === "true",
  encrypted: true,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        ApiService.authBroadcasting({
          socket_id: socketId,
          channel_name: channel.name,
        })
          .then((res) => {
            callback(false, res.data);
          })
          .catch((err) => {
            callback(true, err);
          });
      },
    };
  },
});
