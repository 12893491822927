import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  activities: [],
};

export const mutations = {
  SET_ACTIVITIES(state, activities) {
    state.activities = activities;
  },
};

export const actions = {
  fetchActivities({ commit, dispatch }) {
    dispatch("loading/loadingActivityNotifications", true, { root: true });
    ApiService.fetchActivities()
      .then((res) => {
        commit("SET_ACTIVITIES", res.data.collection);
      })
      .catch(() => {})
      .finally(() => {
        dispatch("loading/loadingActivityNotifications", false, {
          root: true,
        });
      });
  },
  markAsRead({ dispatch }, activity) {
    ApiService.markAsRead(activity)
      .then(() => {
        dispatch("fetchActivities");
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      });
  },
  markAllAsRead({ dispatch }) {
    dispatch("loading/loadingActivityNotifications", true, { root: true });
    ApiService.markAllAsRead()
      .then(() => {
        dispatch("fetchActivities");
      })
      .catch(() => {})
      .finally(() => {
        dispatch("loading/loadingActivityNotifications", false, {
          root: true,
        });
      });
  },
  remove({ dispatch }, activity) {
    dispatch("loading/loadingActivityNotifications", true, { root: true });
    ApiService.deleteActivity(activity)
      .then(() => {
        dispatch("fetchActivities");
      })
      .catch(() => {})
      .finally(() => {
        dispatch("loading/loadingActivityNotifications", false, {
          root: true,
        });
      });
  },
  removeAll({ dispatch }) {
    dispatch("loading/loadingActivityNotifications", true, { root: true });
    ApiService.deleteAll()
      .then(() => {
        dispatch("fetchActivities");
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingActivityNotifications", false, {
          root: true,
        });
      });
  },
  reset({ commit }) {
    commit("SET_ACTIVITIES", []);
  },
};

export const getters = {
  unreadActivities: (state) => {
    return state.activities.filter((activity) => {
      return activity.read_at === null;
    });
  },
  unreadActivitiesCount: (state) => {
    const unread = state.activities.filter((activity) => {
      return activity.read_at === null;
    });
    return unread.length;
  },
};
