import LayoutBauvorhaben from "@/views/backend/bauvorhaben/LayoutBauvorhaben.vue";
import LayoutEditBauvorhaben from "@/views/backend/bauvorhaben/edit/LayoutEditBauvorhaben.vue";

export default [
  {
    path: "bauvorhaben",
    meta: {
      requiresAuth: true,
    },
    component: LayoutBauvorhaben,
    children: [
      {
        path: "/",
        name: "list-bauvorhaben-active",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/bauvorhaben/ListBauvorhaben.vue"),
      },
      {
        path: "done",
        name: "list-bauvorhaben-done",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/bauvorhaben/ListBauvorhaben.vue"),
      },
      {
        path: "all",
        name: "list-bauvorhaben-all",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/bauvorhaben/ListBauvorhaben.vue"),
      },
      {
        path: "planung",
        name: "bauvorhaben-planung",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/bauvorhaben/lists/BauvorhabenPlanung.vue"),
      },
      {
        path: "calendar",
        name: "bauvorhaben-planung-calendar",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import(
            "@/views/backend/bauvorhaben/lists/BauvorhabenPlanungKalender.vue"
          ),
      },
      {
        path: ":id",
        name: "show-bauvorhaben",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/bauvorhaben/ShowBauvorhaben.vue"),
        props: true,
      },
      {
        path: ":id/edit",
        meta: {
          requiresAuth: true,
        },
        component: LayoutEditBauvorhaben,
        props: true,
        children: [
          {
            path: "/",
            name: "edit-bauvorhaben",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/bauvorhaben/edit/EditBauvorhabenKundendaten.vue"
              ),
          },
          {
            path: "bauplanung",
            name: "edit-bauvorhaben-bauplanung",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/bauvorhaben/edit/EditBauvorhabenBauplanung.vue"
              ),
          },
          {
            path: "statik",
            name: "edit-bauvorhaben-statik",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/bauvorhaben/edit/EditBauvorhabenStatik.vue"
              ),
          },
          {
            path: "massen",
            name: "edit-bauvorhaben-massen",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/bauvorhaben/edit/EditBauvorhabenMassen.vue"
              ),
          },
          {
            path: "finanzen",
            name: "edit-bauvorhaben-finanzen",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/bauvorhaben/edit/EditBauvorhabenFinanzen.vue"
              ),
          },
          {
            path: "zusatzgewerke",
            name: "edit-bauvorhaben-zusatzgewerke",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/bauvorhaben/edit/EditBauvorhabenZusatzgewerke.vue"
              ),
          },
          {
            path: "abwicklung",
            name: "edit-bauvorhaben-abwicklung",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/bauvorhaben/edit/EditBauvorhabenAbwicklung.vue"
              ),
          },
        ],
      },
    ],
  },
];
