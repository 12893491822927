<template>
  <v-container fluid>
    <h1 class="my-4">{{ begruessung }}{{ authUser.vorname }}!</h1>

    <v-row class="fill-height">
      <v-col cols="12" md="5">
        <DashboardWidgetVorgaenge />
      </v-col>
      <v-col cols="12" md="4">
        <DashboardWidgetUrlaub />
      </v-col>
      <v-col cols="12" md="3">
        <DashboardWidgetUserStatus />
      </v-col>
    </v-row>

    <DashboardVerwaltung v-if="$hasPermission(authUser, ['Verwaltung'])" />
    <!--    <v-divider v-if="$hasPermission(authUser, 'Admin')" class="my-5" />-->
    <DashboardVertrieb v-if="$hasPermission(authUser, ['Verkauf'])" />
    <DashboardPlanung
      v-if="$hasPermission(authUser, ['Planung', 'Bauleitung', 'Statik'])"
    />
    <DashboardMarketing v-if="$hasPermission(authUser, ['Marketing'])" />
    <DashboardRechnungswesen
      v-if="$hasPermission(authUser, ['Rechnungswesen'])"
    />
  </v-container>
</template>

<script>
import DashboardVerwaltung from "@/components/dashboards/DashboardVerwaltung.vue";
import DashboardVertrieb from "@/components/dashboards/DashboardVertrieb.vue";
import DashboardPlanung from "@/components/dashboards/DashboardPlanung.vue";
import DashboardMarketing from "@/components/dashboards/DashboardMarketing.vue";
import DashboardRechnungswesen from "@/components/dashboards/DashboardRechnungswesen.vue";
import DashboardWidgetUrlaub from "@/components/dashboards/widgets/DashboardWidgetUrlaub.vue";
import DashboardWidgetVorgaenge from "@/components/dashboards/widgets/DashboardWidgetVorgaenge.vue";
import DashboardWidgetUserStatus from "@/components/dashboards/widgets/DashboardWidgetUserStatus.vue";

export default {
  name: "Dashboard",
  components: {
    DashboardWidgetUserStatus,
    DashboardWidgetVorgaenge,
    DashboardWidgetUrlaub,
    DashboardRechnungswesen,
    DashboardMarketing,
    DashboardPlanung,
    DashboardVertrieb,
    DashboardVerwaltung,
  },
  data() {
    return {};
  },
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    begruessung() {
      const now = new Date();
      let stunde = now.getHours();

      if (stunde < 10) {
        return "Guten Morgen ";
      }
      if (stunde >= 10 && stunde < 18) {
        return "Moin ";
      }
      if (stunde >= 18) {
        return "Guten Abend ";
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss"></style>
