<template>
  <v-container fluid>
    <v-row v-if="isListRoute">
      <v-col cols="12" md="2">
        <v-sheet outlined rounded>
          <v-list rounded nav>
            <v-list-item
              v-for="(navItem, index) in navItems"
              :key="`nav-item-${index}`"
              dense
            >
              <v-list-item-icon>
                <v-icon :color="navItem.iconColor">{{ navItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="{ 'mb-3': navItem.children }">
                  <router-link
                    :to="navItem.path"
                    class="text-decoration-none"
                    active-class="font-weight-bold"
                    exact
                  >
                    {{ navItem.title }}
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="10">
        <router-view />
      </v-col>
    </v-row>
    <router-view v-else />
  </v-container>
</template>

<script>
import {
  mdiProgressWrench,
  mdiProgressCheck,
  mdiProgressHelper,
} from "@mdi/js";

export default {
  name: "LayoutBauvorhaben",
  data() {
    return {
      navItems: [
        {
          title: "Aktiv",
          path: "/backend/bauvorhaben",
          iconColor: "warning",
          icon: mdiProgressWrench,
        },
        {
          title: "Abgewickelt",
          path: "/backend/bauvorhaben/done",
          iconColor: "success",
          icon: mdiProgressCheck,
        },
        {
          title: "Alle",
          path: "/backend/bauvorhaben/all",
          iconColor: "info",
          icon: mdiProgressHelper,
        },
      ],
    };
  },
  computed: {
    isListRoute() {
      return this.$route.name.includes("list");
    },
  },
};
</script>

<style scoped></style>
