<template>
  <v-card outlined class="pa-5 fill-height overflow-y-auto" max-height="400">
    <v-card-title class="px-0 pt-0 text-h5">
      <span v-if="listValue === 'active'">Aktive&nbsp;</span
      ><span v-else>Erledigte&nbsp;</span>Vorgänge ({{ total }})
      <v-spacer />
      <v-btn-toggle v-model="listValue" mandatory @change="fetchVorgaenge">
        <v-btn value="active" depressed small color="info"> Aktiv </v-btn>
        <v-btn value="done" depressed small color="info"> Erledigt </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-sheet
      v-for="(vorgang, index) in vorgaenge"
      :key="`vorgang-${index}`"
      outlined
      rounded
      class="pa-5 mb-5"
    >
      <v-row no-gutters>
        <v-btn
          v-if="
            vorgang.type ===
            'App\\Notifications\\BauvorhabenUpdatedNotification'
          "
          depressed
          small
          color="cta"
          class="mb-4 white--text"
          :to="{
            name: 'show-bauvorhaben',
            params: { id: vorgang.data.uuid },
          }"
          >Bauvorhaben: {{ vorgang.data.bNr }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-if="!vorgang.read_at"
          depressed
          small
          color="success"
          @click="markAsDone(vorgang)"
        >
          Erledigt
        </v-btn>
        <v-btn
          v-else
          depressed
          small
          color="error"
          @click="deleteVorgang(vorgang)"
        >
          Löschen
        </v-btn>
      </v-row>
      <div class="d-flex flex-grow-1 align-center justify-space-between">
        <div class="content">
          <p class="mb-0" :class="!vorgang.read_at ? 'font-weight-bold' : ''">
            {{ vorgang.data.message }}
          </p>
          <p class="caption text--disabled mb-0">
            {{ isoDateToDateTime(vorgang.created_at) }}
          </p>
        </div>
      </div>
    </v-sheet>
    <p v-if="vorgaenge.length === 0" class="mb-0">Keine Vorgänge vorhanden.</p>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import { DateTime } from "luxon";

export default {
  name: "DashboardWidgetVorgaenge",
  data: () => ({
    loading: false,
    updating: false,
    listValue: "active",
    vorgaenge: [],
    total: 0,
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
  },
  async mounted() {
    await this.fetchVorgaenge();
  },
  methods: {
    async fetchVorgaenge() {
      const payload = {
        params: {
          type: "App\\Notifications\\BauvorhabenUpdatedNotification",
          view: "dashboard",
          notifiable_id: this.authUser.id,
        },
      };

      if (this.listValue === "done") {
        payload.params.read = true;
      } else {
        payload.params.read = false;
      }

      this.loading = true;
      await ApiService.fetchActivities(payload)
        .then((res) => {
          this.vorgaenge = res.data.collection;
          this.total = res.data.meta.total;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: "Es ist ein Fehler aufgetreten: " + getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async markAsDone(vorgang) {
      this.updating = true;
      await ApiService.markAsRead(vorgang.id)
        .then(() => {
          this.fetchVorgaenge();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.updating = false;
        });
    },
    async deleteVorgang(vorgang) {
      this.updating = true;

      await ApiService.deleteActivity(vorgang.id)
        .then(() => {
          this.fetchVorgaenge();
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.updating = false;
        });
    },
    isoDateToDateTime(date) {
      return date
        ? DateTime.fromISO(date).toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS
          )
        : "";
    },
  },
};
</script>

<style scoped></style>
