<template>
  <v-dialog v-model="warningZone" max-width="800" width="100%" persistent>
    <v-card class="pa-5">
      <v-progress-linear
        absolute
        top
        color="primary"
        :value="progress"
      ></v-progress-linear>
      <v-card-title class="pt-0 px-0"> Bist Du noch da? </v-card-title>
      <p>Deine Sitzung läuft demnächst aufgrund von längerer Inaktivität ab.</p>
      <p>Der automatische Logout erfolgt in:</p>
      <v-avatar color="info" size="62" class="white--text display-1">
        {{ timerCount }}
      </v-avatar>
      <!--      <p class="primary&#45;&#45;text display-1">-->
      <!--        {{ timerCountFormatted }}-->
      <!--      </p>-->
      <v-card-actions class="pb-0 px-0">
        <v-spacer />
        <v-btn
          large
          depressed
          color="cta"
          class="white--text"
          @click="resetTimer"
          >Eingeloggt bleiben</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "AutoLogout",
  data: () => ({
    events: ["click", "mousedown", "scroll", "keypress", "load"],
    warningTimer: null,
    logoutTimer: null,
    counterTimer: null,
    warningZone: false,
    warningTimeout: 115, // Minuten
    logoutTimeout: 120, // Minuten
    timerCount: null, // Minuten
    progress: 100,
  }),
  computed: {
    timerCountFormatted() {
      return DateTime.fromSeconds(this.timerCount).toISOTime({
        suppressMilliseconds: true,
        suppressSeconds: false,
        includeOffset: false,
        includePrefix: false,
        format: "basic",
      });
    },
  },
  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    }, this);

    this.setTimers();
  },
  destroyed() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    }, this);

    this.clearTimers();
  },
  methods: {
    setTimers() {
      this.warningTimer = setTimeout(
        this.warningMessage,
        this.warningTimeout * 60 * 1000
      );
      this.logoutTimer = setTimeout(
        this.onLogout,
        this.logoutTimeout * 60 * 1000
      );
      this.warningZone = false;
      this.timerCount = 1;
    },
    warningMessage() {
      this.warningZone = true;
      this.counterTimer = setInterval(() => {
        if (this.timerCount > 0) {
          this.timerCount--;
          this.progress = this.progress - (1 / (this.timerCount * 60)) * 100;
        } else {
          this.warningZone = false;
        }
      }, 1000);
    },
    resetTimer() {
      this.clearTimers();
      this.setTimers();
    },
    clearTimers() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      clearInterval(this.counterTimer);
      this.progress = 100;
    },
    async onLogout() {
      this.warningZone = false;
      await this.$store.dispatch("loading/logout", true);
      await ApiService.logout()
        .then(() => {
          this.$store.dispatch("search/reset");
          this.$store.dispatch("activities/reset");
          this.$store.dispatch("templates/reset");
          this.$store.dispatch("user/resetState");
          this.$store.commit("userStatus/SET_STATUSES", []);
          this.$router.push({
            path: "/login",
            query: { redirect: this.$route.fullPath },
          });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.$store.dispatch("loading/logout", false);
        });
    },
  },
};
</script>

<style scoped></style>
