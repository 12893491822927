<template>
  <v-card flat outlined min-height="300" class="pa-5 fill-height">
    <v-card-title class="text-h5 pt-0 px-0">
      <router-link to="/backend/katalogbestellungen">
        Kataloganfragen
      </router-link>
      <v-spacer />
      <v-menu
        v-model="menuStatus"
        :close-on-content-click="false"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="datumFormatiert"
            clearable
            outlined
            dense
            :disabled="loadingKatalogbestellungen"
            label="Ab Datum"
            readonly
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datum"
          color="cta"
          locale="de-de"
          :first-day-of-week="1"
          :max="maxDate"
          no-title
          scrollable
          show-adjacent-months
          show-current
          @input="fetchKatalogbestellungen"
        ></v-date-picker>
      </v-menu>
    </v-card-title>
    <v-overlay
      v-if="loadingKatalogbestellungen"
      absolute
      color="white"
      opacity="1"
    >
      <v-progress-circular color="cta" indeterminate></v-progress-circular>
    </v-overlay>
    <BarChart
      v-else
      :chart-data="chartData"
      :chart-options="chartOptions"
      :styles="{ height: '300px' }"
    />
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import { DateTime } from "luxon";
import BarChart from "@/components/charts/BarChart.vue";

export default {
  name: "DashboardWidgetKatalogbestellungen",
  components: { BarChart },
  data: () => ({
    chartData: {
      labels: [],
      datasets: [
        {
          label: "Katalogbestellungen Talis",
          backgroundColor: "#f9b000",
          data: [],
        },
        // {
        //   label: "Katalogbestellungen Tchibo",
        //   backgroundColor: "#415c73",
        //   data: [],
        // },
      ],
    },
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          color: "white",
        },
      },
    },
    datum: DateTime.now().toISODate(),
    maxDate: DateTime.now().minus({ days: 7 }).toISODate(),
    menuStatus: false,
    loadingKatalogbestellungen: false,
  }),
  computed: {
    datumFormatiert() {
      if (this.datum) {
        return DateTime.fromISO(this.datum).toLocaleString(
          DateTime.DATE_MED_WITH_WEEKDAY
        );
      } else {
        return DateTime.now().toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
      }
    },
  },
  async mounted() {
    await this.fetchKatalogbestellungen();
  },
  methods: {
    async fetchKatalogbestellungen() {
      this.menuStatus = false;

      const payload = {
        all: true,
      };

      const now = DateTime.now().toISODate();

      if (this.datum !== now) {
        payload.from = this.datum;
      }

      this.loadingKatalogbestellungen = true;
      await ApiService.getKatalogbestellungen(payload)
        .then((res) => {
          // const range = JSON.parse(res.data.meta.range);
          const rangeTalis = JSON.parse(res.data.meta.range_talis);
          // const rangeTchibo = JSON.parse(res.data.meta.range_tchibo);
          const labels = [];
          const valuesTalis = [];
          // const valuesTchibo = [];

          Object.keys(rangeTalis).forEach((date) => {
            labels.push(
              DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
            );
          });

          Object.values(rangeTalis).forEach((count) => {
            valuesTalis.push(count);
          });

          // Object.values(rangeTchibo).forEach((count) => {
          //   valuesTchibo.push(count);
          // });

          this.chartData.labels = labels;
          this.chartData.datasets[0].data = valuesTalis;
          // this.chartData.datasets[1].data = valuesTchibo;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingKatalogbestellungen = false;
        });
    },
    isoDateToDateTime(date) {
      return date
        ? DateTime.fromISO(date).toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS
          )
        : "";
    },
  },
};
</script>

<style scoped></style>
