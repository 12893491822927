import LayoutTimesheets from "@/views/backend/timesheets/LayoutTimesheets.vue";

export default [
  {
    path: "timesheets",
    meta: {
      requiresAuth: true,
    },
    component: LayoutTimesheets,
    children: [
      {
        path: "/",
        name: "list-timesheets",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/timesheets/ListTimesheets.vue"),
        props: true,
      },
      {
        path: "new",
        name: "new-timesheet",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/timesheets/NewTimesheet.vue"),
      },
      {
        path: ":id",
        name: "show-timesheet",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/timesheets/ListTimesheets.vue"),
        props: true,
      },
      {
        path: ":id/edit",
        name: "edit-timesheet",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/timesheets/EditTimesheet.vue"),
        props: true,
      },
    ],
  },
];
