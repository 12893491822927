<template>
  <div class="notifications-container">
    <v-slide-y-transition group>
      <NotificationAlert
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      />
    </v-slide-y-transition>
  </div>
</template>

<script>
import NotificationAlert from "@/components/notifications/NotificationAlert.vue";

export default {
  name: "NotificationsContainer",
  components: { NotificationAlert },
  computed: {
    notifications() {
      return this.$store.state.notifications.notifications;
    },
  },
};
</script>

<style scoped lang="scss">
.notifications-container {
  position: fixed;
  top: 20px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  z-index: 99999;
}
@media screen and (min-width: 1264px) {
  .notifications-container {
    max-width: 500px;
  }
}
</style>
