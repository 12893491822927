import InvoicesLayout from "@/views/backend/invoices/InvoicesLayout.vue";

export default [
  {
    path: "invoices",
    meta: {
      requiresAuth: true,
    },
    component: InvoicesLayout,
    children: [
      {
        path: "all",
        name: "list-invoices",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/invoices/ListInvoices.vue"),
      },
      {
        path: "draft",
        name: "list-invoice-drafts",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/invoices/ListInvoices.vue"),
      },
      {
        path: "created",
        name: "list-invoices-created",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/invoices/ListInvoices.vue"),
      },
      {
        path: "sent",
        name: "list-invoices-sent",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/invoices/ListInvoices.vue"),
      },
      {
        path: "pending",
        name: "list-invoices-pending",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/invoices/ListInvoices.vue"),
      },
      {
        path: "overdue",
        name: "list-invoices-overdue",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/invoices/ListInvoices.vue"),
      },
      {
        path: "incoming",
        name: "list-incoming-invoices",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/invoices/incoming/ListIncomingInvoices.vue"),
      },
      {
        path: "incoming/new",
        name: "new-incoming-invoice",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/invoices/incoming/NewIncomingInvoice.vue"),
      },
      {
        path: "incoming/:id",
        name: "show-incoming-invoice",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/invoices/incoming/ShowIncomingInvoice.vue"),
        props: true,
      },
      {
        path: "incoming/:id/edit",
        name: "edit-incoming-invoice",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/invoices/incoming/EditIncomingInvoice.vue"),
        props: true,
      },
      {
        path: "payments",
        name: "list-invoices-payments",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/invoices/payments/ListPayments.vue"),
      },
      {
        path: "reminders",
        name: "list-invoices-reminders",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/invoices/reminders/ListInvoiceReminders.vue"),
      },
      {
        path: ":id",
        name: "show-invoice",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/invoices/ShowInvoice.vue"),
        props: true,
      },
      {
        path: ":id/edit",
        name: "edit-invoice",
        meta: {
          requiresAuth: true,
        },
        component: () => import("@/views/backend/invoices/EditInvoice.vue"),
        props: true,
      },
      {
        path: ":id/invoice-reminders/new",
        name: "new-invoice-reminder",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/invoices/reminders/NewInvoiceReminder.vue"),
        props: true,
      },
      {
        path: ":id/invoice-reminders/:reminderId",
        name: "show-invoice-reminder",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/invoices/reminders/ShowInvoiceReminder.vue"),
        props: true,
      },
      {
        path: ":id/invoice-reminders/:reminderId/edit",
        name: "edit-invoice-reminder",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/invoices/reminders/EditInvoiceReminder.vue"),
        props: true,
      },
    ],
  },
];
