<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        rounded
        large
        color="grey lighten-3"
        style="text-transform: none"
        :loading="loading"
        v-bind="attrs"
        v-on="on"
        min-width="184"
      >
        <v-icon :color="activeStatus.color">{{ icons.mdiCircleMedium }}</v-icon>
        <span class="grey--text text--darken-1 font-weight-bold">{{
          activeStatus.status
        }}</span>
        <v-icon color="grey darken-1">{{ icons.mdiMenuDown }}</v-icon>
      </v-btn>
    </template>
    <v-sheet>
      <v-list-item-group v-model="activeStatus" active-class="">
        <v-list-item
          v-for="(status, index) in userStatuses"
          :key="`status-${index}`"
          :value="status"
          link
          @click="onUpdateStatus"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon :color="status.color">{{ icons.mdiCircleMedium }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ status.status }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon v-if="active">{{ icons.mdiCheck }}</v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
      <v-divider />
      <v-list v-if="$hasPermission(authUser, 'Admin')">
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Status hinzufügen </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
import { mdiCircleMedium, mdiMenuDown, mdiCheck, mdiPencil } from "@mdi/js";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "UserStatusButton",
  data: () => ({
    icons: {
      mdiCircleMedium,
      mdiMenuDown,
      mdiCheck,
      mdiPencil,
    },
    userStatuses: [],
    activeStatus: null,
    loading: false,
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    // userStatuses() {
    //   return this.$store.state.userStatus.statuses;
    // },
  },
  created() {
    this.fetchUserStatuses();
    this.activeStatus = this.authUser.status;
  },
  methods: {
    async fetchUserStatuses() {
      this.loading = true;
      await ApiService.fetchUserStatuses()
        .then((res) => {
          this.userStatuses = res.data;
          // this.$store.commit("userStatus/SET_STATUSES", res.data);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler (UserStatusButton)",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onUpdateStatus() {
      this.$nextTick(() => {
        const data = {
          status: this.activeStatus.status,
          uuid: this.authUser.uuid,
        };
        this.$store.dispatch("user/updateStatus", data);
      });
    },
  },
};
</script>

<style scoped></style>
