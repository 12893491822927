<template>
  <v-card flat outlined class="pa-5">
    <v-progress-linear
      v-if="loading"
      absolute
      top
      color="primary"
      indeterminate
    />
    <v-card-title class="text-h5 pt-0 px-0">
      Wiedervorlagen für diese Woche
    </v-card-title>
    <v-sheet outlined tile max-height="400" class="overflow-y-auto">
      <v-data-table
        :headers="tableHeaders"
        :items="wiedervorlagen"
        hide-default-footer
        no-data-text="Keine Wiedervorlagen für diese Woche."
        class="table-hover"
        @click:row="showTableItem"
      >
        <template v-slot:item.created_at="{ item }">
          <span :class="setClass(item)"> {{ formatDate(item.datum) }}</span>
        </template>
        <template v-slot:item.grund="{ item }">
          <span :class="setClass(item)">{{ item.grund }}</span>
        </template>
        <template v-slot:item.name="{ item }">
          <span :class="setClass(item)">
            {{ item.user.nachname }}, {{ item.user.vorname }}</span
          >
        </template>
      </v-data-table>
    </v-sheet>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import { DateTime } from "luxon";
import { mdiArrowCollapseLeft, mdiArrowCollapseRight } from "@mdi/js";

export default {
  name: "DashboardWidgetWiedervorlagen",
  data: () => ({
    loading: false,
    error: null,
    wiedervorlagen: [],
    tableHeaders: [
      {
        text: "Datum",
        value: "created_at",
        sortable: false,
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Grund",
        value: "grund",
        sortable: false,
      },
    ],
    dataTableFooterProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: mdiArrowCollapseLeft,
      lastIcon: mdiArrowCollapseRight,
      itemsPerPageOptions: [10, 20, 50, -1],
    },
  }),
  async mounted() {
    await this.fetchWiedervorlagen();
  },
  methods: {
    async fetchWiedervorlagen() {
      const query = {
        view: "dashboard",
      };

      this.loading = true;
      await ApiService.fetchWiedervorlagen(query)
        .then((res) => {
          this.wiedervorlagen = res.data.collection;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setClass(item) {
      if (item.user) {
        return item.user.unread ? "font-weight-bold" : "";
      }
      return "";
    },
    showTableItem(item) {
      this.$router.push({
        name: "show-interessent-details",
        params: { id: item.user.uuid },
      });
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_HUGE);
    },
  },
};
</script>

<style scoped></style>
