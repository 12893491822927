<template>
  <Bar
    v-if="!loading"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :styles="{ height: '300px' }"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  ChartDataLabels
);

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      required: true,
      default: () => ({
        labels: [],
        datasets: [],
      }),
    },
    chartOptions: {
      type: Object,
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
      }),
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>

<style scoped></style>
