<template>
  <v-dialog :value="value" width="100%" max-width="800" persistent>
    <v-card class="pa-5" min-height="450">
      <v-card-title class="pt-0 px-0">
        <template v-if="$props.event"> Termin bearbeiten </template>
        <template v-else> Neuer Termin </template>
        <v-spacer />
        <v-btn icon @click="resetEventData">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-text-field
        v-model="singleEvent.name"
        placeholder="Titel und Uhrzeit einfügen"
        outlined
        autofocus
        clearable
        class="text-h5"
      ></v-text-field>
      <template
        v-if="singleEvent.startDate < singleEvent.endDate && singleEvent.timed"
      >
        <v-row dense align="center">
          <!-- Datepicker startdate -->
          <v-col cols="12" md="4">
            <v-menu ref="startDateMenu" max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedStartDate"
                  dense
                  outlined
                  readonly
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.startDate"
                locale="de"
                first-day-of-week="1"
                :min="minDate"
                no-title
                scrollable
                @input="onUpdateStartDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
          <v-col cols="12" md="4">
            <v-row no-gutters justify="center" align="center">
              <v-col cols="5">
                <!-- Datepicker starttime -->
                <v-autocomplete
                  v-model="singleEvent.startTime"
                  :items="timeSlots"
                  append-icon=""
                  dense
                  outlined
                  hide-details
                  :menu-props="{ auto: true, minWidth: '150px' }"
                  @input="onUpdateStartTime"
                  @keydown.enter="onUpdateStartTime"
                ></v-autocomplete>
                <!-------------------------->
              </v-col>
              <v-col cols="2" class="text-center">
                <span aria-label=" bis ">–</span>
              </v-col>
              <v-col cols="5">
                <!-- Autocomplete endtime -->
                <v-autocomplete
                  v-model="singleEvent.endTime"
                  :items="timeSlots"
                  append-icon=""
                  dense
                  outlined
                  hide-details
                  :menu-props="{ auto: true, minWidth: '150px' }"
                ></v-autocomplete>
                <!------------------------>
              </v-col>
            </v-row>
          </v-col>
          <!-- Datepicker enddate -->
          <v-col cols="12" md="4">
            <v-menu max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedEndDate"
                  dense
                  outlined
                  hide-details
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.endDate"
                locale="de"
                first-day-of-week="1"
                :min="minDate"
                no-title
                scrollable
                @input="onUpdateEndDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
        </v-row>
      </template>
      <!-- TIMED -->
      <template v-else-if="singleEvent.timed">
        <v-row align="center">
          <!-- Datepicker startdate -->
          <v-col cols="12" md="6">
            <v-menu ref="startDateMenu" max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedStartDate"
                  dense
                  outlined
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.startDate"
                locale="de"
                first-day-of-week="1"
                :min="minDate"
                no-title
                scrollable
                @input="onUpdateStartDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
          <v-col cols="12" md="6">
            <v-row no-gutters justify="center" align="center">
              <v-col cols="5">
                <!-- Datepicker starttime -->
                <v-autocomplete
                  v-model="singleEvent.startTime"
                  :items="timeSlots"
                  append-icon=""
                  dense
                  outlined
                  hide-details
                  :prepend-icon="icons.mdiCalendarClock"
                  :menu-props="{ auto: true, minWidth: '150px' }"
                  @input="onUpdateStartTime"
                ></v-autocomplete>
                <!-------------------------->
              </v-col>
              <v-col cols="2" class="text-center">
                <span aria-label=" bis ">–</span>
              </v-col>
              <v-col cols="5">
                <!-- Autocomplete endtime -->
                <v-autocomplete
                  v-model="singleEvent.endTime"
                  :items="timeSlots"
                  append-icon=""
                  dense
                  outlined
                  hide-details
                  :prepend-icon="icons.mdiCalendarClock"
                  :menu-props="{ auto: true, minWidth: '150px' }"
                ></v-autocomplete>
                <!------------------------>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row align="center">
          <!-- Datepicker startdate -->
          <v-col cols="12" md="6">
            <v-menu ref="startDateMenu" max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedStartDate"
                  dense
                  outlined
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.startDate"
                locale="de"
                first-day-of-week="1"
                no-title
                scrollable
                @input="onUpdateStartDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
          <!-- Datepicker enddate -->
          <v-col cols="12" md="6">
            <v-menu max-width="100%">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedEndDate"
                  dense
                  outlined
                  hide-details
                  :prepend-icon="icons.mdiCalendar"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="singleEvent.endDate"
                locale="de"
                first-day-of-week="1"
                :min="minDate"
                no-title
                scrollable
                @input="onUpdateEndDate"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-------------------------->
        </v-row>
      </template>
      <v-row no-gutters justify-md="space-between" align-md="center">
        <v-checkbox
          v-model="singleEvent.timed"
          label="Ganztägig?"
          class="d-inline-block"
          :true-value="false"
          :false-value="true"
          @change="onUpdateAllDay"
        ></v-checkbox>
        <div class="colors">
          <v-btn
            v-for="color in colors"
            :key="color"
            icon
            small
            @click="onUpdateColor(color)"
          >
            <v-icon :color="color">{{ icons.mdiCircle }}</v-icon>
          </v-btn>
        </div>
      </v-row>
      <!-- Benachrichtigung -->
      <v-row v-if="singleEvent.timed" align="center" dense class="mb-4">
        <v-col cols="12" md="5">
          <v-select
            v-model="singleEvent.notificationType"
            dense
            hide-details
            outlined
            :prepend-icon="icons.mdiBell"
            :items="['E-Mail', 'Benachrichtigung']"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model.number="singleEvent.notificationValue"
            dense
            hide-details
            outlined
            type="number"
            @input="onUpdateNotificationValue"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="singleEvent.notificationUnit"
            dense
            hide-details
            outlined
            :items="units"
            item-text="name"
            item-value="value"
            @change="onUpdateNotificationUnit"
          ></v-select>
        </v-col>
        <v-col cols="12" md="1">vorher um</v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            v-model="singleEvent.notificationTime"
            :items="timeSlots"
            append-icon=""
            dense
            hide-details
            outlined
            :menu-props="{ auto: true, minWidth: '150px' }"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-else dense class="mb-4">
        <v-col cols="12" md="5">
          <v-select
            v-model="singleEvent.notificationType"
            dense
            hide-details
            outlined
            :prepend-icon="icons.mdiBell"
            :items="['E-Mail', 'Benachrichtigung']"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model.number="singleEvent.notificationValue"
            dense
            hide-details
            outlined
            type="number"
            @input="onUpdateNotificationValue"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="singleEvent.notificationUnit"
            dense
            hide-details
            outlined
            :items="units"
            item-text="name"
            item-value="value"
            @change="onUpdateNotificationUnit"
          ></v-select>
        </v-col>
      </v-row>
      <!-- Gast -->
      <v-btn
        v-if="!showGuestTextField"
        block
        depressed
        class="mb-4"
        @click="displayGuestTextField"
      >
        <v-icon>{{ icons.mdiAccountMultiple }}</v-icon> Person hinzufügen
      </v-btn>
      <template v-else>
        <v-autocomplete
          v-model="singleEvent.guest"
          :items="guestItems"
          label="Teilnehmer"
          item-text="name"
          item-value="id"
          :loading="fetchingSearchResults"
          :disabled="fetchingSearchResults"
          :search-input.sync="searchQuery"
          clearable
          hide-details
          outlined
          dense
          return-object
          class="mb-3 align-center"
          @click:clear="onResetSearch"
        >
          <template v-slot:append-outer>
            <v-btn icon @click="hideGuestTextField">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
      </template>
      <v-text-field
        v-if="user"
        outlined
        disabled
        readonly
        color="primary"
        class="mb-4"
        hide-details
        :value="guestName"
      >
      </v-text-field>
      <!-- Ort -->
      <v-btn
        v-if="!showPlaceField"
        block
        depressed
        class="mb-4"
        @click="showPlaceField = true"
      >
        <v-icon>{{ icons.mdiMapMarker }}</v-icon> Ort hinzufügen
      </v-btn>
      <v-select
        v-else
        v-model="singleEvent.place"
        label="Ort"
        hide-details
        outlined
        dense
        :items="places"
        class="mb-4 align-center"
      >
        <template v-slot:append-outer>
          <v-btn icon @click="hidePlaceField">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </template>
      </v-select>

      <!-- Description -->
      <v-btn
        v-if="!showDescriptionField"
        block
        depressed
        class="mb-4"
        @click="showDescriptionField = true"
      >
        <v-icon>{{ icons.mdiText }}</v-icon> Beschreibung hinzufügen
      </v-btn>
      <v-textarea
        v-else
        ref="descriptionField"
        v-model="singleEvent.description"
        outlined
      >
        <template v-slot:append-outer>
          <v-btn icon @click="showDescriptionField = false">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </template>
      </v-textarea>
      <v-card-actions class="px-0">
        <v-spacer />
        <v-btn large depressed class="mr-md-4" @click="resetEventData"
          >Abbrechen</v-btn
        >
        <v-btn
          :disabled="!singleEvent.name || saving"
          large
          depressed
          :loading="saving"
          class="white--text"
          color="cta"
          @click="saveEvent"
          >Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiCalendar,
  mdiCalendarClock,
  mdiCircle,
  mdiBell,
  mdiMapMarker,
  mdiText,
  mdiAccountMultiple,
  mdiMagnify,
} from "@mdi/js";
import { DateTime } from "luxon";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import debounce from "lodash-es/debounce";

export default {
  name: "EventDialog",
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    user: {
      type: Object,
      default: () => null,
      required: false,
    },
    guestModel: {
      type: String,
      default: "Interessent",
    },
    value: {
      type: Boolean,
      default: false,
    },
    fetchUser: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
      required: false,
    },
  },
  data: () => ({
    icons: {
      mdiClose,
      mdiCalendar,
      mdiCalendarClock,
      mdiCircle,
      mdiBell,
      mdiMapMarker,
      mdiText,
      mdiAccountMultiple,
      mdiMagnify,
    },
    singleEvent: {
      color: "primary",
      description: null,
      end: null,
      endDate: null,
      endTime: null,
      guest: null,
      name: null,
      notificationDateTime: DateTime.now().toFormat("yyyy-LL-dd 09:00"),
      notificationType: "Benachrichtigung",
      notificationUnit: "minute",
      notificationValue: 10,
      place: null,
      searchQuery: null,
      start: null,
      startDate: null,
      startTime: null,
      timed: false,
      user: null,
    },
    saving: false,
    timeSlots: [],
    colors: [
      "primary",
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    searchResults: [],
    places: [
      "Firma",
      "Beim Kunden",
      "Außerhalb",
      "Auf der Baustelle",
      "Home Office",
      "Online (Videocall)",
    ],
    notificationUnits: [
      { name: "Minuten", value: "minute" },
      { name: "Stunden", value: "hour" },
      { name: "Tage", value: "day" },
      { name: "Wochen", value: "week" },
    ],
    minDate: DateTime.now().toISODate(),
    showGuestTextField: false,
    searchQuery: null,
    showPlaceField: false,
    showDescriptionField: false,
    fetchingSearchResults: false,
  }),
  computed: {
    units() {
      if (!this.singleEvent.timed) {
        return this.notificationUnits.map((unitItem) => {
          return unitItem.name === "Tage" || unitItem.name === "Wochen"
            ? unitItem
            : null;
        });
      } else {
        return this.notificationUnits;
      }
    },
    guestName() {
      return this.user ? `${this.user.vorname} ${this.user.nachname}` : "";
    },
    guestItems() {
      return this.searchResults.map((user) => {
        return {
          id: user.id,
          uuid: user.uuid,
          name: `${user.vorname} ${user.nachname}`,
        };
      });
    },
    formattedStartDate() {
      return this.singleEvent.startDate
        ? DateTime.fromISO(this.singleEvent.startDate)
            .setLocale("de-DE")
            .toFormat("cccc, d. LLLL")
        : "";
    },
    formattedEndDate() {
      return this.singleEvent.endDate
        ? DateTime.fromISO(this.singleEvent.endDate)
            .setLocale("de-DE")
            .toFormat("cccc, d. LLLL")
        : "";
    },
  },
  mounted() {
    if (this.timeSlots.length === 0) {
      this.generateTimeSlots();
    }
  },
  watch: {
    value(val) {
      if (val === true) {
        if (this.event && this.event.startDate) {
          this.initializeFields();
        } else {
          this.createBlankEvent();
        }
      }
    },
    searchQuery(val) {
      if (this.searchResults.length > 0) {
        return;
      }

      if (this.fetchingSearchResults) {
        return;
      }

      this.debouncedSearch(val);
    },
  },
  methods: {
    createBlankEvent() {
      this.setStartDate(DateTime.now().toISODate());
      this.setEndDate(DateTime.now().toISODate());

      if (this.singleEvent.startTime === null) {
        this.setStartTime(this.getTimeSlotNearCurrentTime());

        this.setEndTime(
          DateTime.fromISO(this.singleEvent.startTime)
            .plus({ hours: 1 })
            .toLocaleString(DateTime.TIME_24_SIMPLE)
        );
      }

      this.setStart(this.singleEvent.startDate, this.singleEvent.startTime);
      this.setEnd(this.singleEvent.endDate, this.singleEvent.endTime);

      this.singleEvent.guest = this.user;

      this.singleEvent.notificationValue = 10;
      this.singleEvent.notificationUnit = "minute";
      this.singleEvent.notificationDateTime = DateTime.fromSQL(
        this.singleEvent.start
      )
        .minus({ minutes: this.singleEvent.notificationValue })
        .toSQL({ includeOffset: false });

      this.$emit("event:blank", this.singleEvent);
    },
    displayGuestTextField() {
      this.showGuestTextField = true;
    },
    async fetchSingleUser() {
      await ApiService.getSingleUser(this.$route.params.id)
        .then((res) => {
          this.$store.commit("user/SET_USER", res.data.data);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        });
    },
    generateTimeSlots() {
      const startTime = DateTime.local().startOf("day");
      const endTime = DateTime.local().endOf("day");

      let currentTime = startTime;

      while (currentTime <= endTime) {
        this.timeSlots.push(
          currentTime.toLocaleString(DateTime.TIME_24_SIMPLE)
        );
        currentTime = currentTime.plus({ minutes: 15 });
      }
    },
    getTimeSlotNearCurrentTime() {
      const currentDateTime = DateTime.now();
      let nearestTime = this.timeSlots[0];
      let timeDiff = Math.abs(
        currentDateTime.diff(DateTime.fromISO(nearestTime)).as("minutes")
      );

      for (const time of this.timeSlots) {
        const optionDateTime = DateTime.fromISO(time);
        const diff = Math.abs(
          currentDateTime.diff(optionDateTime).as("minutes")
        );

        if (diff < timeDiff) {
          nearestTime = time;
          timeDiff = diff;
        }
      }

      return nearestTime;
    },
    hideGuestTextField() {
      this.searchQuery = null;
      this.showGuestTextField = false;
      this.singleEvent.guest = null;
    },
    hidePlaceField() {
      this.showPlaceField = false;
      this.singleEvent.place = null;
    },
    initializeFields() {
      Object.entries(this.$props.event).forEach(([key, value]) => {
        if (key === "startDate") {
          this.setStartDate(DateTime.fromISO(value).toISODate());
        } else if (key === "endDate") {
          this.setEndDate(DateTime.fromISO(value).toISODate());
        } else if (key === "startTime") {
          this.setStartTime(
            DateTime.fromISO(value).toLocaleString(DateTime.TIME_24_SIMPLE)
          );
        } else if (key === "endTime") {
          this.setEndTime(
            DateTime.fromISO(value).toLocaleString(DateTime.TIME_24_SIMPLE)
          );
        } else if (key === "start") {
          this.setStart(
            this.$props.event.startDate,
            this.$props.event.startTime
          );
        } else if (key === "end") {
          this.setEnd(this.$props.event.endDate, this.$props.event.endTime);
        } else {
          this.singleEvent[key] = value;
        }
      });

      if (this.singleEvent.timed) {
        this.setStartTime(
          DateTime.fromISO(this.singleEvent.startTime).toLocaleString(
            DateTime.TIME_24_SIMPLE
          )
        );

        this.setEndTime(
          DateTime.fromISO(this.singleEvent.startTime)
            .plus({ hours: 1 })
            .toLocaleString(DateTime.TIME_24_SIMPLE)
        );
      } else {
        this.setStartTime("00:00");
        this.setEndTime("23:59");
      }
    },
    onResetSearch() {
      this.searchQuery = null;
      this.searchResults = [];
    },
    onUpdateAllDay() {
      if (this.singleEvent.timed === true) {
        if (this.singleEvent.endDate === null) {
          this.setEndDate(this.singleEvent.startDate);
        }

        this.setStartTime(this.getTimeSlotNearCurrentTime());
        this.setEndTime(
          DateTime.fromISO(this.singleEvent.startTime)
            .plus({ hours: 1 })
            .toLocaleString(DateTime.TIME_24_SIMPLE)
        );

        this.setStart(this.singleEvent.startDate, this.singleEvent.startTime);
        this.setEnd(this.singleEvent.endDate, this.singleEvent.endTime);

        // this.singleEvent.start = `${this.singleEvent.startDate} ${this.singleEvent.startTime}`;
        // this.singleEvent.end = `${this.singleEvent.endDate} ${this.singleEvent.endTime}`;

        this.singleEvent.notificationValue = 10;
        this.singleEvent.notificationUnit = "minute";
        this.singleEvent.notificationDateTime = DateTime.fromSQL(
          this.singleEvent.start
        )
          .minus({ minutes: this.singleEvent.notificationValue })
          .toSQL({ includeOffset: false });
      } else {
        this.setStartTime(null);
        this.setEndTime(null);
        this.setStart(this.singleEvent.startDate);
        this.setEnd(this.singleEvent.endDate);

        this.singleEvent.notificationValue = 1;
        this.singleEvent.notificationUnit = "day";
        this.singleEvent.notificationTime = "09:00";
        this.singleEvent.notificationDateTime = DateTime.fromISO(
          this.singleEvent.start
        )
          .minus({ days: this.singleEvent.notificationValue })
          .toFormat("yyyy-LL-dd 09:00");
      }
    },
    onUpdateColor(color) {
      this.singleEvent.color = color;
      // this.setEvent(this.singleEvent);
    },
    onUpdateEndDate() {
      this.setEnd(this.singleEvent.endDate);
    },
    onUpdateNotificationUnit() {
      if (this.singleEvent.notificationUnit === "minute") {
        this.singleEvent.notificationDateTime = DateTime.fromISO(
          `${this.singleEvent.startDate}T${this.singleEvent.startTime}:00`
        )
          .minus({ minutes: this.singleEvent.notificationValue })
          .toFormat("yyyy-LL-dd HH:mm");
      } else if (this.singleEvent.notificationUnit === "hour") {
        this.singleEvent.notificationDateTime = DateTime.fromISO(
          `${this.singleEvent.startDate}T${this.singleEvent.startTime}:00`
        )
          .minus({ hours: this.singleEvent.notificationValue })
          .toFormat("yyyy-LL-dd HH:mm");
      } else if (this.singleEvent.notificationUnit === "day") {
        this.singleEvent.notificationDateTime = DateTime.fromISO(
          this.singleEvent.start
        )
          .minus({ days: this.singleEvent.notificationValue })
          .toFormat(`yyyy-LL-dd ${this.singleEvent.notificationTime}`);
      } else {
        this.singleEvent.notificationDateTime = DateTime.fromISO(
          this.singleEvent.start
        )
          .minus({ weeks: this.singleEvent.notificationValue })
          .toFormat(`yyyy-LL-dd ${this.singleEvent.notificationTime}`);
      }
    },
    onUpdateNotificationValue() {
      this.$nextTick(() => {
        if (this.singleEvent.notificationValue <= 0) {
          this.singleEvent.notificationValue = 0;
        }
      });
    },
    onUpdateStartDate() {
      if (this.singleEvent.timed === false) {
        if (
          DateTime.fromISO(this.singleEvent.startDate).ts >
          DateTime.fromISO(this.singleEvent.endDate).ts
        ) {
          this.setEndDate(this.singleEvent.startDate);
        }
        this.setStartTime(null);
        this.setEndTime(null);
        this.setStart(this.singleEvent.startDate);
        this.setEnd(this.singleEvent.endDate);
      } else {
        this.setEndDate(this.singleEvent.startDate);
        this.setStart(this.singleEvent.startDate, this.singleEvent.startTime);
        this.setEnd(this.singleEvent.endDate, this.singleEvent.endTime);
      }
    },
    onUpdateStartTime() {
      this.setStartTime(
        DateTime.fromISO(this.singleEvent.startTime).toLocaleString(
          DateTime.TIME_24_SIMPLE
        )
      );

      this.setEndTime(
        DateTime.fromISO(this.singleEvent.startTime)
          .plus({ hours: 1 })
          .toLocaleString(DateTime.TIME_24_SIMPLE)
      );

      this.setStart(this.singleEvent.startDate, this.singleEvent.startTime);
      this.setEnd(this.singleEvent.endDate, this.singleEvent.endTime);
    },
    resetEventData() {
      this.singleEvent = {
        color: "primary",
        description: null,
        end: null,
        endDate: null,
        endTime: null,
        guest: null,
        name: null,
        notificationDateTime: DateTime.now().toFormat("yyyy-LL-dd 09:00"),
        notificationType: "Benachrichtigung",
        notificationUnit: "minute",
        notificationValue: 10,
        place: null,
        searchQuery: null,
        start: null,
        startDate: null,
        startTime: null,
        timed: false,
        user: null,
      };

      this.searchQuery = null;
      this.searchResults = [];

      this.$emit("cancel");
    },
    roundTime(time, down = true) {
      const roundTo = 15;
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    async saveEvent() {
      if (this.guestModel) {
        this.singleEvent.guest_model = this.guestModel;
      }

      this.saving = true;

      if (this.event && this.event.id) {
        await this.updateEvent();
      } else {
        await this.storeEvent();
      }

      this.saving = false;
    },
    async storeEvent() {
      await ApiService.storeEvent(this.singleEvent)
        .then(() => {
          this.$emit("submit");

          const notification = {
            type: "success",
            title: "Erledigt",
            message: "Termin erfolgreich gespeichert.",
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.resetEventData();
        });
    },
    async updateEvent() {
      await ApiService.updateEvent(this.singleEvent)
        .then(() => {
          this.$emit("submit");

          const notification = {
            type: "success",
            title: "Erledigt",
            message: "Termin erfolgreich aktualisiert.",
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.resetEventData();
        });
    },
    debouncedSearch: debounce(async function (query) {
      const data = {
        query: query,
        model: "User",
        associatedOnly: true,
      };

      if (query && query.length > 1) {
        this.fetchingSearchResults = true;
        await ApiService.search(data)
          .then((res) => {
            this.searchResults = res.data.collection;
          })
          .catch((err) => {
            const notification = {
              type: "error",
              title: "Fehler",
              error: getError(err),
            };

            this.$store.commit("notifications/ADD", notification);
          })
          .finally(() => {
            this.fetchingSearchResults = false;
          });
      }
    }, 500),
    setEnd(date, time) {
      const endDate = DateTime.fromISO(date).toISODate();
      const endTime = time
        ? DateTime.fromISO(time).toLocaleString(DateTime.TIME_24_SIMPLE)
        : "23:59";

      this.singleEvent.end = `${endDate} ${endTime}`;
    },
    setEndDate(date) {
      this.singleEvent.endDate = date;
    },
    setEndTime(time) {
      this.singleEvent.endTime = time;
    },
    setGuest(guest) {
      this.singleEvent.guest = guest;
      // this.$nextTick(() => {
      //   this.singleEvent.searchQuery = `${this.singleEvent.guest.vorname} ${this.singleEvent.guest.nachname}`;
      // });
    },
    setStart(date, time) {
      const startDate = DateTime.fromISO(date).toISODate();
      const startTime = time
        ? DateTime.fromISO(time).toLocaleString(DateTime.TIME_24_SIMPLE)
        : "00:00";
      this.singleEvent.start = `${startDate} ${startTime}`;
    },
    setStartDate(date) {
      this.singleEvent.startDate = date;
    },
    setStartTime(time) {
      this.singleEvent.startTime = time;
    },
  },
};
</script>

<style scoped></style>
