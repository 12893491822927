import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  tickets: [],
  recentTickets: [],
  ticket: {},
  categories: [],
  category: {},
  priorities: [],
  statuses: [],
  singleComment: {},
  showReasonDialog: false,
  loading: false,
  loadingCategories: false,
  loadingPriorities: false,
  loadingStatuses: false,
  showDialog: false,
  showNewTicketDialogContent: false,
  showTicketDetailsDialogContent: false,
  showEditTicketDialogContent: false,
  newTicketFormValid: false,
  editTicketFormValid: false,
  editTicketFormDirty: false,
};

export const mutations = {
  SET_TICKETS(state, tickets) {
    state.tickets = tickets;
  },
  SET_RECENT_TICKETS(state, tickets) {
    state.recentTickets = tickets;
  },
  SET_TICKET(state, ticket) {
    state.ticket = ticket;
  },
  ADD_TICKET(state, ticket) {
    state.tickets.push(ticket);
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_CATEGORY(state, category) {
    state.category = category;
  },
  ADD_CATEGORY(state, category) {
    state.categories.push(category);
  },
  ADD_COMMENT(state, comment) {
    state.ticket.comments.push(comment);
  },
  REMOVE_COMMENT(state, comment) {
    state.ticket.comments = state.ticket.comments.filter(
      (commentItem) => commentItem.id === comment.id
    );
  },
  SET_PRIORITIES(state, priorities) {
    state.priorities = priorities;
  },
  SET_STATUSES(state, statuses) {
    state.statuses = statuses;
  },
  SHOW_REASON_DIALOG(state, value) {
    state.showReasonDialog = value;
  },
  SET_SINGLE_COMMENT(state, comment) {
    state.singleComment = comment;
  },
  SET_SHOW_DIALOG(state, status) {
    state.showDialog = status;
  },
  SET_SHOW_NEW_TICKET_DIALOG_CONTENT(state, status) {
    state.showNewTicketDialogContent = status;
    state.showTicketDetailsDialogContent = false;
    state.showEditTicketDialogContent = false;
  },
  SET_SHOW_TICKET_DETAILS_DIALOG_CONTENT(state, status) {
    state.showTicketDetailsDialogContent = status;
    state.showNewTicketDialogContent = false;
    state.showEditTicketDialogContent = false;
  },
  SET_SHOW_EDIT_TICKET_DIALOG_CONTENT(state, status) {
    state.showEditTicketDialogContent = status;
    state.showNewTicketDialogContent = false;
    state.showTicketDetailsDialogContent = false;
  },
  SET_NEW_TICKET_FORM_VALID(state, valid) {
    state.newTicketFormValid = valid;
  },
  SET_EDIT_TICKET_FORM_VALID(state, valid) {
    state.editTicketFormValid = valid;
  },
  SET_EDIT_TICKET_FORM_DIRTY(state, dirty) {
    state.editTicketFormDirty = dirty;
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_LOADING_CATEGORIES(state, payload) {
    state.loadingCategories = payload;
  },
  SET_LOADING_PRIORITIES(state, payload) {
    state.loadingPriorities = payload;
  },
  SET_LOADING_STATUSES(state, payload) {
    state.loadingStatuses = payload;
  },
  RESET_STATE(state) {
    state.tickets = [];
    state.recentTickets = [];
    state.ticket = {};
    state.categories = [];
    state.category = {};
    state.priorities = [];
    state.statuses = [];
    state.singleComment = {};
    state.showReasonDialog = false;
    state.loading = false;
    state.loadingCategories = false;
    state.loadingPriorities = false;
    state.loadingStatuses = false;
    state.showDialog = false;
    state.showNewTicketDialogContent = false;
    state.showTicketDetailsDialogContent = false;
    state.showEditTicketDialogContent = false;
    state.newTicketFormValid = false;
    state.editTicketFormValid = false;
    state.editTicketFormDirty = false;
  },
};

export const actions = {
  async fetchTickets({ commit, dispatch }) {
    dispatch("loading/loadingTickets", true, { root: true });
    await ApiService.fetchTickets()
      .then((res) => {
        commit("SET_TICKETS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingTickets", false, { root: true });
      });
  },
  async fetchTicket({ commit, dispatch }, ticket) {
    dispatch("loading/loadingTicket", true, { root: true });
    await ApiService.fetchTicket(ticket)
      .then((res) => {
        commit("SET_TICKET", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingTicket", false, { root: true });
      });
  },
  async fetchRecentTickets({ commit, dispatch }, data) {
    dispatch("loading/loadingRecentTickets", true, { root: true });
    ApiService.fetchRecentTickets(data)
      .then((res) => {
        commit("SET_RECENT_TICKETS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingRecentTickets", false, { root: true });
      });
  },
  readTicket({ commit, dispatch }, data) {
    ApiService.readTicket(data)
      .then((res) => {
        commit("SET_TICKETS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message:
            "Error reading ticket: " + (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      });
  },
  addTicket({ commit }, ticket) {
    commit("ADD_TICKET", ticket);
  },
  createTicket({ commit, dispatch }, data) {
    dispatch("loading/awaitingResponse", true, { root: true });
    ApiService.storeTicket(data)
      .then((res) => {
        commit("SET_TICKET", data);
        commit("ADD_TICKET", res.data);
        dispatch("fetchTickets");
        const notification = {
          type: "success",
          title: "Erfolg",
          message: "Das Ticket wurde erstellt.",
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },
  updateTicket({ commit, dispatch }, { ticket, data }) {
    dispatch("loading/loadingUpdateTicket", true, { root: true });
    ApiService.updateTicket(ticket, data)
      .then((res) => {
        commit("SET_TICKET", res.data);
        commit("SET_SHOW_DIALOG", false);
        commit("SET_SHOW_EDIT_TICKET_DIALOG_CONTENT", false);
        dispatch("fetchTickets");
        const notification = {
          type: "success",
          title: "Erledigt",
          message: "Ticket erfolgreich aktualisiert.",
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingUpdateTicket", false, { root: true });
      });
  },
  commentTicket({ commit, dispatch }, { ticket, comment }) {
    dispatch("loading/loadingTicketNewComment", true, { root: true });
    ApiService.commentTicket(ticket, { content: comment })
      .then((res) => {
        commit("SET_TICKET", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingTicketNewComment", false, { root: true });
      });
  },
  delete({ dispatch }, ticket) {
    ApiService.destroyTicket(ticket)
      .then(() => {
        dispatch("fetchTickets");
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      });
  },

  setTickets({ commit }, tickets) {
    commit("SET_TICKETS", tickets);
  },
  setTicket({ commit }, ticket) {
    commit("SET_TICKET", ticket);
  },

  updateComment({ dispatch }, { ticket, comment, data }) {
    // dispatch("loading/loadingTicketNewComment", true, { root: true });
    ApiService.updateComment(ticket, comment, data)
      .then(() => {
        dispatch("fetchTicket", ticket);
        const notification = {
          type: "success",
          title: "Erledigt",
          message: "Antwort aktualisiert.",
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        // dispatch("loading/loadingTicketNewComment", false, { root: true });
      });
  },
  deleteComment({ commit, dispatch }, { ticket, comment }) {
    dispatch("loading/loadingTicketNewComment", true, { root: true });
    ApiService.deleteComment(ticket, comment)
      .then((res) => {
        commit("SET_TICKET", res.data);
        const notification = {
          type: "success",
          title: "Erledigt",
          message: "Antwort gelöscht.",
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingTicketNewComment", false, { root: true });
      });
  },
  setComment({ commit }, comment) {
    commit("SET_SINGLE_COMMENT", comment);
  },
  addComment({ commit, dispatch }, { ticket, comment }) {
    commit("ADD_COMMENT", comment);
    dispatch("commentTicket", { ticket: ticket, comment: comment });
  },
  removeComment({ commit, dispatch }, { ticket, comment }) {
    commit("REMOVE_COMMENT", comment);
    dispatch("deleteComment", { ticket: ticket, comment: comment });
  },
  async fetchTicketCategories({ commit, dispatch }) {
    dispatch("loadingTicketCategories", true);
    await ApiService.fetchTicketCategories()
      .then((res) => {
        commit("SET_CATEGORIES", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loadingTicketCategories", false);
      });
  },
  async fetchTicketCategory({ commit, dispatch }, category) {
    dispatch("loadingTicketCategories", true);
    await ApiService.fetchTicket(category)
      .then((res) => {
        commit("SET_CATEGORY", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loadingTicketCategories", false);
      });
  },
  addCategory({ commit }, category) {
    commit("ADD_CATEGORY", category);
  },
  setCategory({ commit }, category) {
    commit("SET_CATEGORY", category);
  },
  createTicketCategory({ commit, dispatch }, data) {
    dispatch("loading/awaitingResponse", true, { root: true });
    ApiService.storeTicketCategory(data)
      .then((res) => {
        commit("SET_CATEGORY", data);
        commit("ADD_CATEGORY", res.data);
        const notification = {
          type: "success",
          title: "Erfolg",
          message: "Die Kategorie wurde gespeichert.",
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },
  updateTicketCategory({ commit, dispatch }, { category, data }) {
    dispatch("loading/awaitingResponse", true, { root: true });
    ApiService.updateTicketCategory(category, data)
      .then((res) => {
        commit("SET_CATEGORIES", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },
  deleteCategory({ dispatch }, category) {
    ApiService.destroyTicketCategory(category)
      .then(() => {
        dispatch("fetchTicketCategories");
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      });
  },

  async fetchTicketPriorities({ commit, dispatch }) {
    dispatch("loadingTicketPriorities", true);
    await ApiService.fetchTicketPriorities()
      .then((res) => {
        commit("SET_PRIORITIES", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loadingTicketPriorities", false);
      });
  },
  async fetchTicketStatuses({ commit, dispatch }) {
    dispatch("loadingTicketStatuses", true);
    await ApiService.fetchTicketStatuses()
      .then((res) => {
        commit("SET_STATUSES", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: err.message,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loadingTicketStatuses", false);
      });
  },
  setShowReasonDialog({ commit }, val) {
    commit("SHOW_REASON_DIALOG", val);
  },
  setValidStatus({ commit }, payload) {
    commit("SET_NEW_TICKET_FORM_VALID", payload);
  },
  setValidStatusEdit({ commit }, payload) {
    commit("SET_EDIT_TICKET_FORM_VALID", payload);
  },
  setShowDialog({ commit }, payload) {
    commit("SET_SHOW_DIALOG", payload);
  },
  setShowTicketDetailsDialogContent({ commit }, payload) {
    commit("SET_SHOW_TICKET_DETAILS_DIALOG_CONTENT", payload);
  },
  setShowNewTicketDialogContent({ commit }, payload) {
    commit("SET_SHOW_NEW_TICKET_DIALOG_CONTENT", payload);
  },
  setShowEditTicketDialogContent({ commit }, payload) {
    commit("SET_SHOW_EDIT_TICKET_DIALOG_CONTENT", payload);
  },
  setDirtyStatus({ commit }, payload) {
    commit("SET_EDIT_TICKET_FORM_DIRTY", payload);
  },
  loadingTickets({ commit }, payload) {
    commit("SET_LOADING", payload);
  },
  loadingTicketCategories({ commit }, payload) {
    commit("SET_LOADING_CATEGORIES", payload);
  },
  loadingTicketPriorities({ commit }, payload) {
    commit("SET_LOADING_PRIORITIES", payload);
  },
  loadingTicketStatuses({ commit }, payload) {
    commit("SET_LOADING_STATUSES", payload);
  },
  reset({ commit }) {
    commit("RESET_STATE");
  },
};

export const getters = {
  newTicketsCount: (state) => {
    const count = state.tickets.filter((ticket) => {
      return ticket.read_at === null && ticket.status.id !== 4;
    });
    return count.length;
  },
  openTickets: (state) => {
    return state.tickets.filter(function (ticket) {
      return ticket.status.id !== 4;
    });
  },
  completedTickets: (state) => {
    return state.tickets.filter(function (ticket) {
      return ticket.status.id === 4;
    });
  },
};
