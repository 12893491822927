<template>
  <v-card class="pa-5">
    <v-progress-linear
      v-if="loadActivities"
      absolute
      indeterminate
      top
      color="primary"
    ></v-progress-linear>
    <v-card-title class="pt-0 px-0">
      Mitteilungen
      <v-spacer />
      <v-menu offset-x offset-y>
        <template v-slot:activator="{ on: cardMenu }">
          <v-btn icon v-on="cardMenu">
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            link
            @click="markAllAsRead"
            :disabled="unreadActivitiyNotifications.length === 0"
          >
            <v-list-item-icon>
              <v-icon>{{ icons.mdiCheckCircle }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Alle als gelesen markieren
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="removeAll"
            :disabled="activitiyNotifications.length === 0"
          >
            <v-list-item-icon>
              <v-icon>{{ icons.mdiDelete }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Alle entfernen </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-divider class="mb-4" />
    <p v-if="unreadActivitiyNotifications.length === 0" class="mb-0">
      Momentan nichts los.
    </p>
    <template v-else>
      <v-list v-if="!loadActivities" nav dense>
        <SingleActivity
          v-for="activity in activitiyNotifications"
          :key="activity.id"
          :activity="activity"
        />
      </v-list>
      <v-skeleton-loader v-else type="paragraph@2"></v-skeleton-loader>
    </template>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiCheckCircle, mdiDelete } from "@mdi/js";
import { mapActions } from "vuex";
import SingleActivity from "@/components/activities/SingleActivity.vue";

export default {
  name: "ActivitiesContainer",
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiCheckCircle,
        mdiDelete,
      },
    };
  },
  components: { SingleActivity },
  computed: {
    activitiyNotifications() {
      return this.$store.state.activities.activities;
    },
    unreadActivitiyNotifications() {
      return this.$store.getters["activities/unreadActivities"];
    },
    loadActivities() {
      return this.$store.state.loading.activityNotifications;
    },
  },
  methods: {
    ...mapActions("activities", ["markAllAsRead", "removeAll"]),
  },
};
</script>

<style scoped></style>
