var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-5 fill-height overflow-y-auto",attrs:{"outlined":"","max-height":"400"}},[_c('v-card-title',{staticClass:"px-0 pt-0 text-h5"},[(_vm.listValue === 'active')?_c('span',[_vm._v("Aktive ")]):_c('span',[_vm._v("Erledigte ")]),_vm._v("Vorgänge ("+_vm._s(_vm.total)+") "),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":""},on:{"change":_vm.fetchVorgaenge},model:{value:(_vm.listValue),callback:function ($$v) {_vm.listValue=$$v},expression:"listValue"}},[_c('v-btn',{attrs:{"value":"active","depressed":"","small":"","color":"info"}},[_vm._v(" Aktiv ")]),_c('v-btn',{attrs:{"value":"done","depressed":"","small":"","color":"info"}},[_vm._v(" Erledigt ")])],1)],1),_vm._l((_vm.vorgaenge),function(vorgang,index){return _c('v-sheet',{key:`vorgang-${index}`,staticClass:"pa-5 mb-5",attrs:{"outlined":"","rounded":""}},[_c('v-row',{attrs:{"no-gutters":""}},[(
          vorgang.type ===
          'App\\Notifications\\BauvorhabenUpdatedNotification'
        )?_c('v-btn',{staticClass:"mb-4 white--text",attrs:{"depressed":"","small":"","color":"cta","to":{
          name: 'show-bauvorhaben',
          params: { id: vorgang.data.uuid },
        }}},[_vm._v("Bauvorhaben: "+_vm._s(vorgang.data.bNr))]):_vm._e(),_c('v-spacer'),(!vorgang.read_at)?_c('v-btn',{attrs:{"depressed":"","small":"","color":"success"},on:{"click":function($event){return _vm.markAsDone(vorgang)}}},[_vm._v(" Erledigt ")]):_c('v-btn',{attrs:{"depressed":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteVorgang(vorgang)}}},[_vm._v(" Löschen ")])],1),_c('div',{staticClass:"d-flex flex-grow-1 align-center justify-space-between"},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"mb-0",class:!vorgang.read_at ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(vorgang.data.message)+" ")]),_c('p',{staticClass:"caption text--disabled mb-0"},[_vm._v(" "+_vm._s(_vm.isoDateToDateTime(vorgang.created_at))+" ")])])])],1)}),(_vm.vorgaenge.length === 0)?_c('p',{staticClass:"mb-0"},[_vm._v("Keine Vorgänge vorhanden.")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }