import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { abilitiesPlugin } from "@casl/vue";
// ACL (CASL)
import ability from "@/acl/ability";

// Custom directives, mixins and properties
import "@/instance/properties";

// import global styles
// require("@/assets/scss/styles.scss");
import "@/scss/main.scss";

// Laravel echo
import "@/config/laravel-echo";

import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.use(abilitiesPlugin, ability);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
