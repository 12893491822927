import axios from "axios";
import store from "../store";
import router from "../router";

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  withXSRFToken: true,
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (process.env.NODE_ENV === "development") {
      console.log("Intercepted: ", error.response);
    }
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      Object.keys(store.state.user.authUser).length > 0
    ) {
      if (error.response.status === 401) {
        if (router.currentRoute.name !== "verify-email") {
          router.push({ name: "login" }).then(() => {
            store.dispatch("user/resetState");
            const notification = {
              type: "error",
              title: "Keine Berechtigung",
              message:
                "Bitte logge dich ein, um die gewünschte Seite aufzurufen.",
            };

            store.commit("notifications/ADD", notification);
          });
        }
      } else if (error.response.status === 419) {
        router.push({ name: "login" }).then(() => {
          store.dispatch("user/resetState");
          const notification = {
            type: "error",
            title: "Sitzung abgelaufen",
            message:
              "Deine Sitzung ist abgelaufen. Bitte logge dich erneut ein.",
          };

          store.commit("notifications/ADD", notification);
        });
      }
    }
    return Promise.reject(error.response);
  }
);
