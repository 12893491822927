<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2">
        <v-sheet outlined rounded>
          <v-list rounded nav>
            <v-list-item
              v-for="(navItem, index) in navItems"
              :key="`nav-item-${index}`"
            >
              <v-list-item-icon>
                <v-icon>{{ navItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="mb-3">
                  <router-link
                    :to="navItem.path"
                    class="text-decoration-none"
                    active-class="font-weight-bold"
                    exact
                  >
                    {{ navItem.title }}
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="10">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiAccount, mdiAccountCancel } from "@mdi/js";

export default {
  name: "LayoutInteressenten",
  data() {
    return {
      navItems: [
        {
          title: "Aktiv",
          path: "/backend/users/interessenten",
          icon: mdiAccount,
        },
        {
          title: "Inaktiv",
          path: "/backend/users/interessenten/inactive",
          icon: mdiAccountCancel,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
