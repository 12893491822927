import LayoutFiles from "@/views/backend/files/LayoutFiles.vue";

export default [
  {
    path: "files",
    navigationName: "Dateiablage",
    meta: { requiresAuth: true },
    component: LayoutFiles,
    children: [
      {
        path: "/",
        name: "list-files",
        meta: { requiresAuth: true },
        component: () => import("@/views/backend/files/FileManager.vue"),
      },
      // {
      //   path: "upload",
      //   name: "upload-files",
      //   meta: { requiresAuth: true },
      //   component: () => import("@/views/backend/files/UploadFiles.vue"),
      // },
    ],
  },
];
