import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
export const namespaced = true;

export const state = () => ({
  bauvorhaben: [],
  singleBauvorhaben: {},
});

export const mutations = {
  SET_BAUVORHABEN(state, bauvorhaben) {
    state.bauvorhaben = bauvorhaben;
  },
  SET_SINGLE_BAUVORHABEN(state, bauvorhaben) {
    state.singleBauvorhaben = bauvorhaben;
  },
};

export const actions = {
  async fetchBauvorhaben({ commit }) {
    commit("loading/LOADING_BAUVORHABEN", true, { root: true });
    await ApiService.fetchBauvorhaben()
      .then((res) => {
        commit("SET_BAUVORHABEN", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        commit("loading/LOADING_BAUVORHABEN", false, { root: true });
      });
  },
  async fetchSingleBauvorhaben({ commit }, id) {
    commit("loading/LOADING_BAUVORHABEN", true, { root: true });
    await ApiService.fetchSingleBauvorhaben(id)
      .then((res) => {
        commit("SET_SINGLE_BAUVORHABEN", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        commit("loading/LOADING_BAUVORHABEN", false, { root: true });
      });
  },
};
