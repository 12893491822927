<template>
  <v-row class="fill-height">
    <v-col cols="12" md="6">
      <DashboardWidgetKatalogbestellungen />
    </v-col>
    <v-col cols="12" md="6">
      <DashboardWidgetTermineAllgemein />
    </v-col>
  </v-row>
</template>

<script>
import DashboardWidgetKatalogbestellungen from "@/components/dashboards/widgets/DashboardWidgetKatalogbestellungen.vue";
import DashboardWidgetTermineAllgemein from "@/components/dashboards/widgets/DashboardWidgetTermineAllgemein.vue";

export default {
  name: "DashboardVerwaltung",
  components: {
    DashboardWidgetTermineAllgemein,
    DashboardWidgetKatalogbestellungen,
  },
};
</script>

<style scoped></style>
