<template>
  <v-row>
    <v-col cols="12" md="2">
      <v-sheet outlined rounded class="overflow-hidden position-sticky">
        <v-list nav>
          <template v-for="(navItem, index) in navItems">
            <v-list-item
              :key="`nav-item-${index}`"
              v-if="!navItem.gate || $can(navItem.gate)"
              link
              exact
              :to="navItem.path"
            >
              <v-list-item-icon>
                <v-icon v-if="checkIfFinished(navItem)" color="success">{{
                  icons.mdiCheckCircle
                }}</v-icon>
                <v-icon v-else>{{ navItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'success--text': checkIfFinished(navItem) }"
                  active-class="font-weight-bold"
                >
                  {{ navItem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-sheet>
    </v-col>
    <v-col cols="12" md="10">
      <router-view :id="$props.id" :loading-bauvorhaben="loadingBauvorhaben" />
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiAccount,
  mdiHomeSearch,
  mdiViewDashboardVariant,
  mdiCash,
  mdiAirFilter,
  mdiProgressCheck,
  mdiCheckCircle,
  mdiFormatHorizontalAlignCenter,
} from "@mdi/js";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "LayoutEditBauvorhaben",
  props: ["id"],
  data() {
    return {
      icons: {
        mdiCheckCircle,
      },
      loadingBauvorhaben: false,
    };
  },
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    singleBauvorhaben() {
      return this.$store.state.bauvorhaben.singleBauvorhaben;
    },
    navItems() {
      return [
        {
          title: "Stammdaten",
          path: {
            name: "edit-bauvorhaben",
            params: { id: this.$props.id },
          },
          icon: mdiAccount,
        },
        {
          title: "Bauplanung",
          path: {
            name: "edit-bauvorhaben-bauplanung",
            params: { id: this.$props.id },
          },
          icon: mdiHomeSearch,
        },
        {
          title: "Statik",
          path: {
            name: "edit-bauvorhaben-statik",
            params: { id: this.$props.id },
          },
          icon: mdiFormatHorizontalAlignCenter,
          gate: "bauvorhaben_edit_statik",
        },
        {
          title: "Massen",
          path: {
            name: "edit-bauvorhaben-massen",
            params: { id: this.$props.id },
          },
          icon: mdiViewDashboardVariant,
        },
        {
          title: "Vertragssummen",
          path: {
            name: "edit-bauvorhaben-finanzen",
            params: { id: this.$props.id },
          },
          icon: mdiCash,
          gate: "bauvorhaben_edit_finanzen",
        },
        {
          title: "Zusatzgewerke",
          path: {
            name: "edit-bauvorhaben-zusatzgewerke",
            params: { id: this.$props.id },
          },
          icon: mdiAirFilter,
        },
        {
          title: "Abwicklung",
          path: {
            name: "edit-bauvorhaben-abwicklung",
            params: { id: this.$props.id },
          },
          icon: mdiProgressCheck,
        },
      ];
    },
  },
  async created() {
    await this.fetchSingleBauvorhaben();
  },
  methods: {
    async fetchSingleBauvorhaben() {
      this.loadingBauvorhaben = true;
      await ApiService.fetchSingleBauvorhaben(this.$props.id)
        .then((res) => {
          this.$store.commit("bauvorhaben/SET_SINGLE_BAUVORHABEN", res.data);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingBauvorhaben = false;
        });
    },
    checkIfFinished(item) {
      if (
        (this.singleBauvorhaben.bauplanung_erledigt_at &&
          item.title === "Bauplanung") ||
        (this.singleBauvorhaben.massen_erledigt_at &&
          item.title === "Massen") ||
        (this.singleBauvorhaben.finanzen_erledigt_at &&
          item.title === "Finanzen") ||
        (this.singleBauvorhaben.zusatzgewerke_erledigt_at &&
          item.title === "Zusatzgewerke") ||
        (this.singleBauvorhaben.abwicklung_erledigt_at &&
          item.title === "Abwicklung")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   if (to.matched.some((route) => !route.path.includes("bauvorhaben"))) {
  //     this.$store.commit("bauvorhaben/SET_SINGLE_BAUVORHABEN", {});
  //   }
  //
  //   next();
  // },
};
</script>

<style scoped>
.position-sticky {
  position: sticky;
  top: 108px;
}
</style>
