<template>
  <v-card
    v-if="showResultsCard || value"
    absolute
    offset-y
    class="pa-5 overflow-y-auto"
    min-height="300"
    max-height="720"
  >
    <h3 class="mb-2 ml-2">
      <span v-if="$props.model === 'User'">Interessenten</span>
      <span v-else-if="$props.model === 'Customer'">Kunden</span>
      <span v-else-if="$props.model === 'Bauvorhaben'">Bauvorhaben</span>
    </h3>
    <v-list nav class="ma-0 pa-0">
      <v-list-item
        v-for="(item, index) in searchResults"
        :key="`item-${index}`"
        color="cta"
        link
        @click="selectAndNavigate(item)"
      >
        <v-list-item-content
          v-if="$props.model === 'User' || $props.model === 'Customer'"
        >
          <p
            class="mb-0"
            v-html="
              highlightText(
                `${item.id} - ${
                  item.firma ? item.firma : item.vorname + ' ' + item.nachname
                } ${
                  item.old_id
                    ? '(' +
                      item.old_id +
                      `)${
                        item.verkaeufer
                          ? ' | ' +
                            item.verkaeufer.vorname +
                            ' ' +
                            item.verkaeufer.nachname
                          : ''
                      }`
                    : ''
                }`
              )
            "
          ></p>
          <p
            class="caption mb-0"
            v-html="
              highlightText(
                `${item.strasse} ${item.hausnr}, ${item.plz} ${item.ort}`
              )
            "
          ></p>
          <p
            v-if="item.telefonnr"
            class="caption mb-0"
            v-html="highlightText(`${item.email} | ${item.telefonnr}`)"
          ></p>
          <p
            v-else
            class="caption mb-0"
            v-html="highlightText(`${item.email}`)"
          ></p>
        </v-list-item-content>
        <v-list-item-content v-else-if="$props.model === 'Bauvorhaben'">
          <p
            class="mb-0"
            v-html="
              highlightText(
                `${item.bv_nr} - ${item.customer.vorname} ${item.customer.nachname}`
              )
            "
          ></p>
          <p
            class="caption mb-0"
            v-html="
              highlightText(
                `${item.strasse} ${item.hausnr}, ${item.plz} ${item.ort}`
              )
            "
          ></p>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <p v-if="searchResults.length === 0">
      <v-icon class="mr-5">{{ icons.mdiMagnify }}</v-icon> Es wurden keine
      Ergebnisse für "<span class="font-weight-bold">{{ searchQuery }}</span
      >" gefunden.
    </p>

    <v-overlay color="white" :opacity="1" absolute :value="awaitingResponse">
      <v-progress-circular
        color="cta"
        indeterminate
        size="48"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import { mapActions } from "vuex";

export default {
  name: "SearchResults",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    model: {
      type: String,
      default: "User",
    },
    searchQuery: {
      type: String,
      required: false,
    },
    searchResults: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiMagnify,
      },
    };
  },
  computed: {
    // searchResults() {
    //   return this.$store.state.search.results;
    // },
    showResultsCard() {
      return (
        this.$store.state.search.showResults &&
        this.$route.name !== "tickets-list"
      );
    },
    awaitingResponse() {
      return this.$store.state.loading.awaitResponse;
    },
  },
  methods: {
    highlightText(text) {
      if (text) {
        if (this.searchQuery === "") {
          return text;
        } else {
          return text.replace(new RegExp(this.searchQuery, "gi"), (match) => {
            return (
              '<span class="cta--text font-weight-medium">' + match + "</span>"
            );
          });
        }
      }
    },
    selectAndNavigate(item) {
      let routeObject = {};

      if (this.$props.model === "User") {
        routeObject = {
          name: "show-interessent-details",
          params: { id: item.uuid },
        };
      } else if (this.$props.model === "Customer") {
        routeObject = {
          name: "show-customer-details",
          params: { id: item.uuid },
        };
      } else if (this.$props.model === "Bauvorhaben") {
        routeObject = { name: "show-bauvorhaben", params: { id: item.uuid } };
      }

      this.$emit("selected");

      this.$router.push(routeObject);
    },
    ...mapActions("search", ["showResults", "reset"]),
    ...mapActions("user", ["setUser"]),
    ...mapActions("tickets", ["setTicket"]),
  },
};
</script>

<style scoped></style>
