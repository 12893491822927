export const namespaced = true;

export const state = () => ({
  waehlbareLaender: [
    {
      text: "Deutschland",
      value: "Deutschland",
      valueNumberCode: "DE",
      phoneNumberCode: "+49",
      selectItemText: "(DE) +49",
    },
    {
      // 4 stellig
      text: "Österreich",
      value: "Österreich",
      valueNumberCode: "AT",
      phoneNumberCode: "+43",
      selectItemText: "(AT) +43",
    },
    {
      // 4 stellig
      text: "Schweiz",
      value: "Schweiz",
      valueNumberCode: "CH",
      phoneNumberCode: "+41",
      selectItemText: "(CH) +41",
    },
    {
      // 4 stellig
      text: "Dänemark",
      value: "Dänemark",
      valueNumberCode: "DK",
      phoneNumberCode: "+45",
      selectItemText: "(DK) +45",
    },
    {
      // 5 stellig
      text: "Tschechien",
      value: "Tschechien",
      valueNumberCode: "CZ",
      phoneNumberCode: "+420",
      selectItemText: "(CZ) +420",
    },
    {
      // 5 stellig
      text: "Slowakei",
      value: "Slowakei",
      valueNumberCode: "SK",
      phoneNumberCode: "+421",
      selectItemText: "(SK) +421",
    },
    {
      // 4 stellig
      text: "Belgien",
      value: "Belgien",
      valueNumberCode: "BE",
      phoneNumberCode: "+32",
      selectItemText: "(BE) +32",
    },
    {
      // 4 stellig
      text: "Luxemburg",
      value: "Luxemburg",
      valueNumberCode: "LU",
      phoneNumberCode: "+352",
      selectItemText: "(LU) +352",
    },
    {
      // 4 stellig
      text: "Niederlande",
      value: "Niederlande",
      valueNumberCode: "NL",
      phoneNumberCode: "+31",
      selectItemText: "(NL) +31",
    },
    {
      // 5 stellig
      text: "Schweden",
      value: "Schweden",
      valueNumberCode: "SE",
      phoneNumberCode: "+46",
      selectItemText: "(SE) +46",
    },
    {
      // 4 stellig
      text: "Norwegen",
      value: "Norwegen",
      valueNumberCode: "NOR",
      phoneNumberCode: "+47",
      selectItemText: "(NOR) +47",
    },
    {
      // 4 stellig
      text: "Frankreich",
      value: "Frankreich",
      valueNumberCode: "FR",
      phoneNumberCode: "+33",
      selectItemText: "(FR) +33",
    },
    {
      // 4 stellig
      text: "Finnland",
      value: "Finnland",
      valueNumberCode: "FI",
      phoneNumberCode: "+358",
      selectItemText: "(FI) +358",
    },
  ],
});
