<template>
  <v-card class="pa-5">
    <v-card-title class="pt-0 px-0"> E-Mail Adresse verifizieren </v-card-title>
    <p class="text-h5">
      {{ message }}
      <v-icon v-if="verificationStatus === 0" color="error">{{
        icons.mdiCloseCircleOutline
      }}</v-icon>
      <v-icon
        v-else-if="verificationStatus === 1 || verificationStatus === 2"
        color="success"
        size="32"
        >{{ icons.mdiCheckCircleOutline }}</v-icon
      >
    </p>
    <v-card-actions class="pb-0 px-0">
      <v-btn
        v-if="showResendButton"
        large
        depressed
        color="primary"
        @click="resendVerificationMail"
        >Neue Bestätigungsmail anfordern</v-btn
      >
    </v-card-actions>
    <v-overlay
      v-if="confirming || resending"
      absolute
      opacity="1"
      color="white"
      class="text-center"
    >
      <v-progress-circular color="primary" indeterminate> </v-progress-circular>
      <p v-if="confirming" class="mt-4 blue-grey--text">
        E-Mail Adresse wird verifiziert...
      </p>
      <p v-else-if="resending" class="mt-4 blue-grey--text">
        Bestätigungsmail wird gesendet...
      </p>
    </v-overlay>
  </v-card>
</template>

<script>
import { mdiCloseCircleOutline, mdiCheckCircleOutline } from "@mdi/js";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "VerifyEmail",
  data: () => ({
    icons: {
      mdiCloseCircleOutline,
      mdiCheckCircleOutline,
    },
    message: "",
    verificationStatus: -1,
    showResendButton: false,
    confirming: false,
    resending: false,
    verificationUrl: null,
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
  },
  async mounted() {
    if (this.$route.query.verification_url) {
      this.verificationUrl = this.$route.query.verification_url;
      await this.verifyEmail();
    } else if (this.authUser.email) {
      this.message =
        "Klicke den Button, um eine neue Bestätigungsmail zu erhalten.";
      this.showResendButton = true;
    } else {
      this.message =
        "Du hast noch keine E-Mail Adresse hinterlegt. Bitte wende Dich an den Administrator.";
    }
  },
  methods: {
    async verifyEmail() {
      if (this.verificationUrl) {
        this.confirming = true;
        await ApiService.verifyEmail(this.verificationUrl)
          .then((res) => {
            this.$store.commit("user/SET_AUTH_USER", res.data);
            this.message = "E-Mail Adresse erfolgreich verifiziert.";
            this.verificationStatus = 1;

            if (!this.authUser.loginCredentialsSetAt) {
              setTimeout(() => {
                this.$router.push("/first-run/change-password");
              }, 1500);
            } else if (!this.authUser.twoFactorConfirmedAt) {
              setTimeout(() => {
                this.$router.push("/first-run/two-factor-authentication");
              }, 1500);
            } else {
              setTimeout(() => {
                this.$router.push("/login");
              }, 1500);
            }
          })
          .catch((err) => {
            if (err.status === 403) {
              this.message =
                "Link abgelaufen. Du kannst mit einem Klick auf den Button eine neue Bestätigungsmail anfordern.";
              this.verificationStatus = 0;
              this.showResendButton = true;
            }
          })
          .finally(() => {
            this.confirming = false;
          });
      }
    },
    async resendVerificationMail() {
      this.resending = true;
      await ApiService.sendEmailVerificationNotification()
        .then(() => {
          this.message =
            "Wir haben dir eine E-Mail mit einem Aktivierungslink gesendet. Bitte öffne die E-Mail und klicke auf den Link, um den Verifizierungsprozess abzuschließen.";
          this.showResendButton = false;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            message: "Beim Senden der E-Mail ist ein Fehler aufgetreten.",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.resending = false;
        });
    },
  },
};
</script>

<style scoped></style>
