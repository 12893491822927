/* eslint-disable no-console */

import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Backend from "@/layouts/Backend.vue";
import Frontend from "@/layouts/Frontend.vue";

import Dashboard from "@/views/backend/Dashboard.vue";
import FirstRun from "@/views/FirstRun.vue";

import BauberatungenLayout from "@/views/backend/akquise/BauberatungenLayout.vue";

import isEmpty from "lodash-es/isEmpty";

import Ability from "@/acl/ability";

// Routes
import users from "@/router/routes/users";
import settings from "@/router/routes/settings";
import tickets from "@/router/routes/tickets";
import apps from "@/router/routes/apps";
import bauvorhaben from "@/router/routes/bauvorhaben";
import invoices from "@/router/routes/invoices";
import suppliers from "@/router/routes/suppliers";
import timesheets from "@/router/routes/timesheets";
import KatalogbestellungenLayout from "@/views/backend/akquise/KatalogbestellungenLayout.vue";
import ApiService from "@/services/ApiService";
import statistics from "@/router/routes/statistics";
import vacation from "@/router/routes/vacation";
import files from "@/router/routes/files";

Vue.use(VueRouter);
const routes = [
  {
    /**********************************
     * Frontend routes
     **********************************/
    path: "/",
    navigationName: "Start",
    component: Frontend,
    children: [
      {
        path: "/",
        name: "home",
        navigationName: "Login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "login",
        name: "login",
        navigationName: "Login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/login/two-factor-challenge",
        name: "login-two-factor-challenge",
        component: () => import("@/views/TwoFactorChallenge.vue"),
        props: (route) => ({
          query: route.query,
        }),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import("@/views/ForgotPassword.vue"),
      },
      {
        path: "reset-password/:token",
        name: "reset-password",
        component: () => import("@/views/ResetPassword.vue"),
        props: true,
      },
      {
        path: "first-run",
        name: "first-run",
        component: FirstRun,
        children: [
          {
            path: "verify-email",
            name: "verify-email",
            component: () => import("@/components/init/VerifyEmail.vue"),
          },
          {
            path: "change-password",
            name: "first-run-change-password",
            component: () =>
              import("@/components/init/FirstLoginChangePassword.vue"),
          },
          {
            path: "two-factor-authentication",
            name: "first-run-enable-two-factor-auth",
            component: () =>
              import("@/components/init/FirstLoginTwoFactorAuth.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/backend",
    meta: { requiresAuth: true },
    component: Backend,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        navigationName: "Dashboard",
        meta: { requiresAuth: true },
        component: Dashboard,
      },
      {
        path: "dashboard/tchibo",
        name: "dashboard-tchibo",
        navigationName: "Dashboard",
        meta: { requiresAuth: true },
        component: () =>
          import("@/views/backend/dashboards/DashboardTchibo.vue"),
      },
      {
        path: "katalogbestellungen",
        navigationName: "Katalogbestellungen",
        meta: { requiresAuth: true },
        component: KatalogbestellungenLayout,
        children: [
          {
            path: "/",
            name: "list-katalogbestellungen",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/backend/akquise/Katalogbestellungen.vue"),
          },
          {
            path: "anschreiben",
            name: "list-katalogbestellungen-anschreiben",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/backend/akquise/ListKatalogAnschreiben.vue"),
          },
        ],
      },
      {
        path: "katalogbestellungen/tchibo",
        name: "list-katalogbestellungen-tchibo",
        meta: { requiresAuth: true },
        component: () =>
          import("@/views/backend/tchibo/TchiboKatalogbestellungen.vue"),
      },
      {
        path: "bauberatungen",
        meta: { requiresAuth: true },
        component: BauberatungenLayout,
        children: [
          {
            path: "/",
            name: "list-bauberatungen",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/backend/akquise/Bauberatungen.vue"),
          },
          {
            path: "finished",
            name: "list-bauberatungen-finished",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/backend/akquise/Bauberatungen.vue"),
          },
          {
            path: "unfinished",
            name: "list-bauberatungen-unfinished",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/backend/akquise/Bauberatungen.vue"),
          },
          {
            path: "confirmed",
            name: "list-bauberatungen-confirmed",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/backend/akquise/Bauberatungen.vue"),
          },
          {
            path: "unconfirmed",
            name: "list-bauberatungen-unconfirmed",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/backend/akquise/Bauberatungen.vue"),
          },
          {
            path: ":id",
            name: "show-bauberatung",
            meta: { requiresAuth: true },
            component: () =>
              import("@/views/backend/akquise/ShowBauberatung.vue"),
            props: true,
          },
        ],
      },
      {
        path: "bauberatungen/tchibo",
        name: "list-bauberatungen-tchibo",
        meta: { requiresAuth: true },
        component: () =>
          import("@/views/backend/tchibo/TchiboBauberatungen.vue"),
      },
      {
        path: "imports/tchibo",
        name: "list-imports-tchibo",
        meta: { requiresAuth: true },
        component: () => import("@/views/backend/tchibo/TchiboImports.vue"),
      },
      {
        path: "wiedervorlagen",
        name: "wiedervorlagen",
        navigationName: "Wiedervorlagen",
        meta: { requiresAuth: true },
        component: () => import("@/views/backend/akquise/Wiedervorlagen.vue"),
      },
      {
        path: "referenzen",
        name: "referenzen",
        navigationName: "Referenzen",
        meta: { requiresAuth: true },
        component: () => import("@/views/backend/akquise/Referenzen.vue"),
      },
      {
        path: "commissions",
        name: "list-commissions",
        navigationName: "Provisionen",
        meta: { requiresAuth: true },
        component: () =>
          import("@/views/backend/commissions/ListCommissions.vue"),
      },
      {
        path: "commissions/account",
        name: "show-commission-account",
        navigationName: "Provisionen",
        meta: { requiresAuth: true },
        component: () =>
          import("@/views/backend/commissions/ShowCommissionAccount.vue"),
      },
      ...users,
      ...settings,
      ...tickets,
      ...apps,
      ...bauvorhaben,
      ...invoices,
      ...suppliers,
      ...timesheets,
      ...statistics,
      ...vacation,
      ...files,
    ],
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      requiresAuth: true,
      layout: "Frontend",
      resource: "Auth",
      action: "read",
    },
  },
  // {
  //   path: "/webmail/inbox",
  //   name: "Posteingang",
  //   navigationName: "Posteingang",
  //   meta: { requiresAuth: true },
  //   component: () => import("@/views/backend/messaging/webmail/Inbox.vue"),
  // },
  {
    path: "*",
    redirect: "error-404",
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

const abilities = Ability;

router.beforeEach(async (to, from, next) => {
  const authUser = store.state.user.authUser;
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  const authenticated = !isEmpty(authUser);
  const canNavigate = to.matched.some(
    (route) => route.meta.gate === undefined || abilities.can(route.meta.gate)
  );

  if (requiresAuth && !authenticated) {
    await ApiService.getAuthUser()
      .then((res) => {
        store.commit("user/SET_AUTH_USER", res.data.data);
        if (!store.state.user.authUser.emailVerifiedAt) {
          next({ name: "verify-email" });
        } else if (!store.state.user.authUser.loginCredentialsSetAt) {
          next({ name: "first-run-change-password" });
        } else if (!store.state.user.authUser.twoFactorSecret) {
          next({ name: "first-run-enable-two-factor-auth" });
        } else if (!store.state.user.authUser.twoFactorConfirmedAt) {
          next({ name: "login-two-factor-challenge" });
        }
      })
      .catch(() => {
        next({ name: "login", redirect: to.fullPath });
      });
  }

  if (!canNavigate) {
    const notification = {
      type: "warning",
      title: "Keine Berechtigung",
      message:
        "Du hast nicht die benötigte Berechtigung, um diese Seite aufzurufen.",
    };

    store.commit("notifications/ADD", notification);

    if (authenticated) {
      return next({ name: "dashboard" });
    }

    return next({ name: "login" });
  }

  next();
});

export default router;
