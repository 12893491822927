import Vue from "vue";

Vue.prototype.$hasPermission = function (user, roleNameToCheck) {
  if (user.roles) {
    if (Array.isArray(roleNameToCheck)) {
      if (
        user.roles.includes("Admin") ||
        user.roles.includes("Geschäftsführung")
      ) {
        return true;
      }
      return user.roles.some((role) => roleNameToCheck.includes(role));
    } else {
      return user.roles.some((role) => role === roleNameToCheck);
    }
  }
  return false;
};
