import Layout from "@/views/backend/settings/Layout.vue";
import SystemSettingsLayout from "@/views/backend/settings/system/SystemSettingsLayout.vue";
import LayoutTemplates from "@/views/backend/settings/vorlagen/LayoutTemplates.vue";

export default [
  {
    path: "einstellungen",
    navigationName: "Einstellungen",
    meta: { requiresAuth: true, layout: "Backend" },
    component: Layout,
    children: [
      {
        path: "profil",
        name: "settings-profile",
        meta: { requiresAuth: true, layout: "Backend" },
        component: () => import("@/views/backend/settings/Profile.vue"),
      },
      {
        path: "allgemein",
        name: "settings-global",
        meta: { requiresAuth: true, layout: "Backend" },
        component: () =>
          import("@/views/backend/settings/system/GlobalSettings.vue"),
      },
      {
        path: "vorlagen",
        meta: { requiresAuth: true, layout: "Backend" },
        component: LayoutTemplates,
        children: [
          {
            path: "emails",
            name: "settings-emailtemplates",
            meta: { requiresAuth: true, layout: "Backend" },
            component: () =>
              import(
                "@/views/backend/settings/vorlagen/emails/ListEmailTemplates.vue"
              ),
          },
          {
            path: "emails/new",
            name: "settings-new-emailtemplate",
            meta: { requiresAuth: true, layout: "Backend" },
            component: () =>
              import(
                "@/views/backend/settings/vorlagen/emails/NewEmailTemplate.vue"
              ),
          },
          {
            path: "emails/:id",
            name: "settings-show-emailtemplate",
            meta: { requiresAuth: true, layout: "Backend" },
            component: () =>
              import(
                "@/views/backend/settings/vorlagen/emails/ShowEmailTemplate.vue"
              ),
            props: true,
          },
          {
            path: "emails/:id/edit",
            name: "settings-edit-emailtemplate",
            meta: { requiresAuth: true, layout: "Backend" },
            component: () =>
              import(
                "@/views/backend/settings/vorlagen/emails/EditEmailTemplate.vue"
              ),
            props: true,
          },
          {
            path: "briefe",
            name: "settings-briefe",
            meta: { requiresAuth: true, layout: "Backend" },
            component: () =>
              import(
                "@/views/backend/settings/vorlagen/briefe/ListBriefTemplates.vue"
              ),
          },
          {
            path: "briefe/new",
            name: "settings-new-brieftemplate",
            meta: { requiresAuth: true, layout: "Backend" },
            component: () =>
              import(
                "@/views/backend/settings/vorlagen/briefe/NewBriefTemplate.vue"
              ),
          },
          {
            path: "briefe/:id",
            name: "settings-show-brieftemplate",
            meta: { requiresAuth: true, layout: "Backend" },
            component: () =>
              import(
                "@/views/backend/settings/vorlagen/briefe/ShowBriefTemplate.vue"
              ),
            props: true,
          },
          {
            path: "briefe/:id/edit",
            name: "settings-edit-brieftemplate",
            meta: { requiresAuth: true, layout: "Backend" },
            component: () =>
              import(
                "@/views/backend/settings/vorlagen/briefe/EditBriefTemplate.vue"
              ),
            props: true,
          },
        ],
      },
      {
        path: "system",
        meta: { requiresAuth: true, layout: "Backend" },
        component: SystemSettingsLayout,
        children: [
          {
            path: "/",
            name: "settings-system",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/settings/system/GlobalSettings.vue"),
          },
          {
            path: "roles",
            name: "settings-roles",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/settings/system/RoleSettings.vue"),
          },
          {
            path: "roles/edit/:id",
            name: "edit-role",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/settings/system/edit/EditRoles.vue"),
            props: true,
          },
          {
            path: "roles/new",
            name: "new-role",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/settings/system/new/NewRole.vue"),
          },
          {
            path: "permissions",
            name: "settings-permissions",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/settings/system/PermissionSettings.vue"),
          },
          {
            path: "permissions/new",
            name: "new-permission",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/settings/system/new/NewPermission.vue"),
          },
          {
            path: "permissions/edit/:id",
            name: "edit-permission",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/views/backend/settings/system/edit/EditPermissions.vue"
              ),
            props: true,
          },
          {
            path: "invoices",
            name: "invoices-settings",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/settings/system/AbrechnungSettings.vue"),
            props: true,
          },
          {
            path: "upload",
            name: "upload",
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import("@/views/backend/settings/system/Upload.vue"),
          },
        ],
      },
    ],
  },
];
