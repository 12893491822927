<template>
  <v-list-item>
    <v-list-item-content>
      <p
        class="mb-0 text-body-2"
        :class="{ 'font-weight-bold': activity.read_at === null }"
        style="line-height: 1.4"
      >
        <template v-if="activity.type === 'App\\Notifications\\UserAssociated'">
          Dir wurde der Interessent
          <span
            @click="
              markAsReadAndNavigate(
                `/backend/users/interessenten/${activity.data.kunde.uuid}`,
                activity
              )
            "
            class="cta--text textlink"
          >
            {{ activity.data.kunde.vorname }} {{ activity.data.kunde.nachname }}
          </span>
          zugewiesen.
        </template>
        <template v-else-if="activity.type === 'App\\Notifications\\NewTicket'">
          Neues Ticket erhalten
          <span
            class="cta--text textlink"
            @click="
              markAsReadAndNavigate(
                `/backend/tickets/${activity.data.ticket.uuid}`,
                activity
              )
            "
            >Ticket {{ activity.data.ticket.id }}</span
          >
        </template>
        <template
          v-else-if="activity.type === 'App\\Notifications\\NewTicketComment'"
        >
          Neuer Kommentar zu
          <span
            class="cta--text textlink"
            @click="
              markAsReadAndNavigate(
                `/backend/tickets/${activity.data.ticket.uuid}`,
                activity.id
              )
            "
            >Ticket {{ activity.data.ticket.id }}</span
          >
        </template>
        <template
          v-else-if="activity.type === 'App\\Notifications\\TicketUpdated'"
        >
          <span
            class="cta--text textlink"
            @click="
              markAsReadAndNavigate(
                `/backend/tickets/${activity.data.ticket.uuid}`,
                activity
              )
            "
            >Ticket {{ activity.data.ticket.id }}</span
          >
          wurde aktualisiert.
        </template>
        <template
          v-else-if="
            activity.type ===
            'App\\Notifications\\BauvorhabenUpdatedNotification'
          "
        >
          <span
            class="cta--text textlink"
            @click="
              markAsReadAndNavigate(
                `/backend/bauvorhaben/${activity.data.uuid}`,
                activity
              )
            "
          >
            {{ activity.data.message }}</span
          >
        </template>
        <template
          v-else-if="
            activity.type === 'App\\Notifications\\VacationUpdatedNotification'
          "
        >
          {{ activity.data.message }}
        </template>
        <template
          v-else-if="
            activity.type ===
            'App\\Notifications\\BauberatungCreatedNotification'
          "
        >
          <span
            class="cta--text textlink"
            @click="
              markAsReadAndNavigate(
                `/backend/bauberatungen/${activity.data.uuid}`,
                activity
              )
            "
          >
            {{ activity.data.message }}
          </span>
        </template>
      </p>
      <p class="mb-0 caption blue--text">{{ getActivityTime(activity) }}</p>
    </v-list-item-content>
    <v-list-item-action>
      <v-menu offset-y left>
        <template v-slot:activator="{ on: submenu }">
          <v-btn icon v-on="submenu">
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>
        <v-list dense nav>
          <v-list-item
            :disabled="activity.read_at !== null"
            link
            @click="onMarkAsRead(activity)"
          >
            <v-list-item-icon>
              <v-icon>{{ icons.mdiCheckCircle }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Als gelesen markieren </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="remove(activity.id)">
            <v-list-item-icon>
              <v-icon>{{ icons.mdiDelete }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> Benachrichtigung löschen </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mdiDotsVertical, mdiCheckCircle, mdiDelete } from "@mdi/js";
import { mapActions } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "SingleActivity",
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiCheckCircle,
        mdiDelete,
      },
    };
  },
  props: {
    activity: {
      type: Object,
    },
  },
  methods: {
    getActivityTime(activity) {
      return DateTime.fromISO(activity.created_at).toRelative();
    },
    markAsReadAndNavigate(path, activity) {
      if (activity.read_at === null) {
        this.markAsRead(activity.id);
      }
      if (
        this.$route.path.includes("users") ||
        this.$route.path.includes("tickets")
      ) {
        this.$router.replace(path);
      } else {
        this.$router.push(path);
      }
    },
    onMarkAsRead(activity) {
      if (activity.read_at === null) {
        this.markAsRead(activity.id);
      }
    },
    ...mapActions("activities", ["markAsRead", "remove"]),
  },
};
</script>

<style scoped></style>
