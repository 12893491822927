import { render, staticRenderFns } from "./Backend.vue?vue&type=template&id=28513e60&scoped=true"
import script from "./Backend.vue?vue&type=script&lang=js"
export * from "./Backend.vue?vue&type=script&lang=js"
import style0 from "./Backend.vue?vue&type=style&index=0&id=28513e60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28513e60",
  null
  
)

export default component.exports