<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="2">
        <v-sheet outlined rounded>
          <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="primary"
          ></v-progress-linear>
          <v-list rounded nav>
            <template v-for="(navItem, index) in navItems">
              <v-list-item
                v-if="!navItem.gate || $can(navItem.gate)"
                :key="`nav-item-${index}`"
              >
                <v-list-item-icon>
                  <v-icon>{{ navItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :class="{ 'mb-3': navItem.children }">
                    <router-link
                      :to="navItem.path"
                      class="text-decoration-none"
                      active-class="font-weight-bold"
                    >
                      {{ navItem.title }}
                    </router-link>
                  </v-list-item-title>
                  <template v-if="!loading">
                    <template v-for="(child, index) in navItem.children">
                      <v-list-item-subtitle
                        v-if="showNavChildItem(child)"
                        :key="`child-${index}`"
                        class="mb-2"
                      >
                        <router-link
                          :to="child.path"
                          class="text-decoration-none"
                          active-class="font-weight-bold"
                        >
                          {{ child.title }}
                          <span v-if="child.title === 'Entwürfe'"
                            >({{ draftCount }})</span
                          ><span v-else-if="child.title === 'Erstellt'"
                            >({{ createdCount }})</span
                          ><span v-else-if="child.title === 'Verschickt'"
                            >({{ sentCount }})</span
                          ><span v-else-if="child.title === 'Offen'"
                            >({{ pendingCount }})</span
                          ><span v-else-if="child.title === 'Überfällig'"
                            >({{ overdueCount }})</span
                          >
                        </router-link></v-list-item-subtitle
                      >
                    </template>
                  </template>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="10">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mdiCalendarCheck,
  mdiFileDocumentOutline,
  mdiFileDocumentMinusOutline,
  mdiFileDocumentAlertOutline,
} from "@mdi/js";
import { mapActions } from "vuex";
export default {
  name: "InvoicesLayout",
  data() {
    return {
      icons: {
        mdiCalendarCheck,
        mdiFileDocumentOutline,
      },
      navItems: [
        {
          title: "Rechnungen",
          path: "/backend/invoices/all",
          icon: mdiFileDocumentOutline,
          children: [
            { title: "Entwürfe", path: "/backend/invoices/draft" },
            { title: "Erstellt", path: "/backend/invoices/created" },
            { title: "Verschickt", path: "/backend/invoices/sent" },
            { title: "Offen", path: "/backend/invoices/pending" },
            { title: "Überfällig", path: "/backend/invoices/overdue" },
          ],
          gate: "invoices_access",
        },
        {
          title: "Ausgaben",
          path: "/backend/invoices/incoming",
          icon: mdiFileDocumentMinusOutline,
          gate: "incoming_invoices_index",
        },
        {
          title: "Mahnungen",
          path: "/backend/invoices/reminders",
          icon: mdiFileDocumentAlertOutline,
          gate: "invoice_reminders_create",
        },
        {
          title: "Zahlungen",
          path: "/backend/invoices/payments",
          icon: mdiCalendarCheck,
          gate: "payments_store",
        },
      ],
    };
  },
  computed: {
    draftCount() {
      return this.$store.state.invoices.draftCount;
    },
    createdCount() {
      return this.$store.state.invoices.createdCount;
    },
    pendingCount() {
      return this.$store.state.invoices.pendingCount;
    },
    sentCount() {
      return this.$store.state.invoices.sentCount;
    },
    overdueCount() {
      return this.$store.state.invoices.overdueCount;
    },
    loading() {
      return this.$store.state.loading.invoices;
    },
  },
  async mounted() {
    if (this.createdCount === 0 && this.sentCount === 0) {
      await this.fetchInvoiceMetaData();
    }
  },
  methods: {
    showNavChildItem(item) {
      let show = false;

      if (item.title === "Entwürfe" && this.draftCount !== 0) {
        show = true;
      } else if (item.title === "Erstellt" && this.createdCount !== 0) {
        show = true;
      } else if (item.title === "Verschickt" && this.sentCount !== 0) {
        show = true;
      } else if (item.title === "Offen" && this.pendingCount !== 0) {
        show = true;
      } else if (item.title === "Überfällig" && this.overdueCount !== 0) {
        show = true;
      }

      return show;
    },
    ...mapActions("invoices", ["fetchInvoiceMetaData"]),
  },
};
</script>

<style scoped></style>
