export const namespaced = true;

export const state = {
  sidebar: false,
  sidebarMini: false,
  sidebarFilters: false,
  dialog: {
    show: false,
    title: null,
    message: null,
  },
  snackbar: {
    show: false,
    message: null,
    color: "white",
    timeout: 5000,
  },
};

export const mutations = {
  SET_SIDEBAR(state, payload) {
    state.sidebar = payload;
  },
  SET_SIDEBAR_MINI(state, payload) {
    state.sidebarMini = payload;
  },
  SET_SIDEBAR_FILTERS(state, payload) {
    state.sidebarFilters = payload;
  },
  SET_DIALOG(state, payload) {
    state.dialog = payload;
  },
  SET_SNACKBAR(state, payload) {
    state.snackbar = payload;
  },
};

export const actions = {
  setSidebar({ commit }, payload) {
    commit("SET_SIDEBAR", payload);
  },
  setSidebarMini({ commit }, payload) {
    commit("SET_SIDEBAR_MINI", payload);
  },
  setSidebarFilters({ commit }, payload) {
    commit("SET_SIDEBAR_FILTERS", payload);
  },
  setDialog({ commit }, payload) {
    commit("SET_DIALOG", payload);
  },
  setSnackbar({ commit }, payload) {
    commit("SET_SNACKBAR", payload);
  },
};

export const getters = {
  sidebar: (state) => state.sidebar,
  sidebarMini: (state) => state.sidebarMini,
  showSidebarFilters: (state) => state.sidebarFilters,
};
