import Vue from "vue";
import Vuex from "vuex";

import * as layout from "@/store/modules/Layout";
import * as countries from "@/store/modules/Countries";
import * as user from "@/store/modules/User";
import * as duplicates from "@/store/modules/Duplicates";
import * as userStatus from "@/store/modules/UserStatus";
import * as notifications from "@/store/modules/Notifications";
import * as loading from "@/store/modules/Loading";
import * as rolesPermissions from "@/store/modules/RolesPermissions";
import * as templates from "@/store/modules/Templates";
import * as search from "@/store/modules/Search";
import * as activities from "@/store/modules/Activities";
import * as tickets from "@/store/modules/Tickets";
import * as settings from "@/store/modules/Settings";
import * as calendar from "@/store/modules/Calendar";
import * as sorting from "@/store/modules/SortingPagination";
import * as bauvorhaben from "@/store/modules/Bauvorhaben";
import * as invoices from "@/store/modules/Invoices";
import * as bauberatungen from "@/store/modules/Bauberatungen";
import * as catalogueRequests from "@/store/modules/CatalogueRequests";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
    countries,
    user,
    duplicates,
    userStatus,
    notifications,
    loading,
    rolesPermissions,
    templates,
    search,
    activities,
    tickets,
    settings,
    calendar,
    sorting,
    bauvorhaben,
    invoices,
    bauberatungen,
    catalogueRequests,
  },
});
