import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = {
  statuses: [],
};

export const mutations = {
  SET_STATUSES(state, statuses) {
    state.statuses = statuses;
  },
};

export const actions = {
  async fetchUserStatuses({ commit }) {
    await ApiService.fetchUserStatuses().then((res) => {
      commit("SET_STATUSES", res.data);
    });
  },
  storeNewUserStatus({ dispatch }, status) {
    ApiService.storeNewUserStatus(status).then((res) => {
      const notification = {
        type: res.data.type,
        title: res.data.title,
        message: res.data.message,
      };

      dispatch("notifications/add", notification, { root: true });
      dispatch("fetchUserStatuses");
    });
  },
};
