<template>
  <v-dialog :value="value" persistent max-width="800">
    <v-card class="pa-5">
      <v-card-title class="px-0 pt-0">
        <slot name="card-title"></slot>
        <v-spacer />
        <v-btn :disabled="loading" icon @click="close">
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <slot></slot>
      <v-card-actions class="mt-5 px-0 pb-0">
        <v-spacer />
        <v-btn
          depressed
          :disabled="loading"
          :loading="loading"
          large
          class="mr-2"
          @click="close"
          ><slot name="abort-button-text">Abbrechen</slot></v-btn
        >
        <v-btn
          depressed
          :disabled="loading"
          :loading="loading"
          large
          color="cta"
          class="white--text"
          @click="submit"
          ><slot name="action-button-text">Ok</slot></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
  name: "ConfirmationDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
    };
  },
  methods: {
    submit() {
      this.$emit("dialog:action");
    },
    close() {
      this.$emit("dialog:close");
    },
  },
};
</script>

<style scoped></style>
