<template>
  <div>
    <v-row class="fill-height">
      <v-col cols="12" md="6">
        <DashboardWidgetTermineAllgemein />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardWidgetTermineAllgemein from "@/components/dashboards/widgets/DashboardWidgetTermineAllgemein.vue";

export default {
  name: "DashboardPlanung",
  components: {
    DashboardWidgetTermineAllgemein,
  },
};
</script>

<style scoped></style>
