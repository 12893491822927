<template>
  <v-dialog :value="value" persistent width="800">
    <v-form @submit.prevent="submit">
      <v-card class="pa-5">
        <v-card-title class="px-0 pt-0">
          Neue E-Mail
          <v-spacer />
          <v-btn icon @click="closeDialog">
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-select
          dense
          outlined
          label="Vorlagenart"
          :items="types"
          v-model="type"
          @change="onSelectType"
        ></v-select>
        <v-select
          v-if="template"
          v-model="template"
          dense
          outlined
          label="Vorlage"
          :items="templates"
          item-text="bezeichnung"
          item-value="id"
          return-object
          @change="selectTemplate"
        ></v-select>
        <!-- Auswahl Blanko oder Vorlage -->
        <template v-if="type === null"></template>
        <!-- Auswahl aus Vorlagenliste -->
        <template v-else-if="type === 'template'">
          <!-- Kein Template ausgewählt -->
          <template v-if="template === null">
            <v-row v-if="loading">
              <v-col v-for="n in 3" :key="n" cols="4">
                <v-skeleton-loader type="card" />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                v-for="(vorlage, index) in templates"
                :key="`template-${index}`"
                cols="4"
              >
                <v-card class="pa-5" @click="selectTemplate(vorlage)">
                  <v-card-title class="pt-0 px-0 text-h6">
                    {{ vorlage.bezeichnung }}
                  </v-card-title>
                  <v-card-text class="px-0">
                    {{ cutString(vorlage.nachricht, 100) }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <!-- Template ausgewählt -->
          <template v-else>
            <v-row no-gutters>
              <v-text-field
                dense
                outlined
                label="Empfänger"
                disabled
                :value="user.email"
              >
                <template v-slot:append-outer>
                  <v-btn
                    :disabled="additionalRecipients.length > 0"
                    icon
                    color="success"
                    @click="addRecipient"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-row>
            <v-row
              v-for="(recipient, index) in additionalRecipients"
              :key="`recipient-${index}`"
              no-gutters
            >
              <v-text-field
                v-model="recipient.email"
                dense
                outlined
                :label="`Empfänger ${index + 1}`"
                :rules="[rules.email]"
              >
                <template v-slot:append-outer>
                  <v-btn icon color="success" @click="addRecipient(recipient)">
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                  <v-btn icon color="error" @click="removeRecipient(recipient)">
                    <v-icon>{{ icons.mdiMinus }}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-row>
            <v-text-field
              dense
              outlined
              label="Betreff"
              v-model="subject"
            ></v-text-field>
            <p class="text--disabled">
              {{ anrede }}{{ user.anrede }} {{ user.nachname }},
            </p>
            <v-textarea
              auto-grow
              dense
              outlined
              label="Nachricht"
              v-model="message"
              rows="10"
            ></v-textarea>
            <p class="text--disabled">
              Mit freundlichen Grüßen<br />
              {{ authUser.vorname }} {{ authUser.nachname }}
            </p>
            <p class="text--disabled mb-0">Telefon: {{ authUser.telefonnr }}</p>
            <p v-if="authUser.mobilnr" class="text--disabled mb-0">
              Mobil: {{ authUser.mobilnr }}
            </p>
            <p class="text--disabled">E-Mail: {{ authUser.email }}</p>
            <v-divider class="mb-5" />
            <p class="caption text--disabled">
              Talis<sup>&reg;</sup> Holzhäuser<br />
              Gehrmann u. Hinrichs GmbH & Co. KG<br />
              Johannes-Mejer-Str. 20<br />
              25813 Husum<br />
              Handelsregister Husum HRA 1841<br />
              Geschäftsführer: Bernd Hinrichs, Dipl.-Ing. Peer Gehrmann, Rafael
              Gettler<br /><br />
              www.talishaus.de<br />
              www.facebook.com/talishaus<br />
              www.instagram.com/talis.haus
            </p>
            <v-divider class="mb-5" />
            <h3 class="mb-3">Anhänge hinzufügen</h3>
            <DropzoneFiles
              :allowed-file-types="allowedFileTypes"
              :infotext="dropzoneInfotext"
              @change="addFiles"
            />
            <template v-if="hasAttachments">
              <v-row no-gutters>
                <p class="caption">
                  {{ attachmentsCount }} Dateien ({{ attachmentSize }} MB
                  insgesamt)
                </p>
                <v-spacer />
                <v-btn depressed small color="warning" @click="removeAllFiles"
                  ><v-icon class="mr-2">{{ icons.mdiDelete }}</v-icon
                  >Alle entfernen</v-btn
                >
              </v-row>
              <v-divider class="mb-5" />
              <v-chip
                v-for="(file, index) in currentAttachments"
                :key="`currentAttachment-${index}`"
                class="mx-2 mb-2"
                close
                @click:close="removeFile(currentAttachments, file)"
              >
                {{ file.original_name }}
                <v-icon small class="ml-2">{{ icons.mdiCloseCircle }}</v-icon>
              </v-chip>
              <v-chip
                v-for="(file, index) in attachments"
                :key="`attachment-${index}`"
                class="mx-2 mb-2"
                close
                @click:close="removeFile(attachments, file)"
              >
                {{ file.name }}
                <v-icon small class="ml-2">{{ icons.mdiCloseCircle }}</v-icon>
              </v-chip>
            </template>
          </template>
        </template>
        <!-- Blanko -->
        <template v-else>
          <v-row no-gutters>
            <v-text-field
              dense
              outlined
              label="Empfänger"
              disabled
              :value="user.email"
            >
              <template v-slot:append-outer>
                <v-btn
                  :disabled="additionalRecipients.length > 0"
                  icon
                  color="success"
                  @click="addRecipient"
                >
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-row>
          <v-row
            v-for="(recipient, index) in additionalRecipients"
            :key="`recipient-${index}`"
            no-gutters
          >
            <v-text-field
              v-model="recipient.email"
              dense
              outlined
              :label="`Empfänger ${index + 1}`"
              :rules="[rules.email]"
            >
              <template v-slot:append-outer>
                <v-btn icon color="success" @click="addRecipient(recipient)">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
                <v-btn icon color="error" @click="removeRecipient(recipient)">
                  <v-icon>{{ icons.mdiMinus }}</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-row>
          <v-text-field
            dense
            outlined
            label="Betreff"
            v-model="subject"
          ></v-text-field>
          <p class="text--disabled">
            {{ anrede }}{{ user.anrede }} {{ user.nachname }},
          </p>
          <v-textarea
            dense
            auto-grow
            outlined
            label="Nachricht"
            v-model="message"
            rows="10"
          ></v-textarea>
          <p class="text--disabled">
            Mit freundlichen Grüßen<br />
            {{ authUser.vorname }} {{ authUser.nachname }}
          </p>
          <p class="text--disabled mb-0">Telefon: {{ authUser.telefonnr }}</p>
          <p v-if="authUser.mobilnr" class="text--disabled mb-0">
            Mobil: {{ authUser.mobilnr }}
          </p>
          <p class="text--disabled">E-Mail: {{ authUser.email }}</p>
          <v-divider class="mb-5" />
          <p class="caption text--disabled">
            Talis<sup>&reg;</sup> Holzhäuser<br />
            Gehrmann u. Hinrichs GmbH & Co. KG<br />
            Johannes-Mejer-Str. 20<br />
            25813 Husum<br />
            Handelsregister Husum HRA 1841<br />
            Geschäftsführer: Bernd Hinrichs, Dipl.-Ing. Peer Gehrmann, Rafael
            Gettler<br /><br />
            www.talishaus.de<br />
            www.facebook.com/talishaus<br />
            www.instagram.com/talis.haus
          </p>
          <v-divider class="mb-5" />
          <h3 class="mb-3">Anhänge hinzufügen</h3>
          <DropzoneFiles
            :allowed-file-types="allowedFileTypes"
            :infotext="dropzoneInfotext"
            @change="addFiles"
          />
          <template v-if="hasAttachments">
            <v-row no-gutters>
              <p class="caption">
                {{ attachmentsCount }} Dateien ({{ attachmentSize }} MB
                insgesamt)
              </p>
              <v-spacer />
              <v-btn depressed small color="warning" @click="removeAllFiles"
                ><v-icon class="mr-2">{{ icons.mdiDelete }}</v-icon
                >Alle entfernen</v-btn
              >
            </v-row>
            <v-divider class="mb-5" />
            <v-chip
              v-for="(file, index) in currentAttachments"
              :key="`currentAttachment-${index}`"
              class="mx-2 mb-2"
              close
              @click:close="removeFile(currentAttachments, file)"
            >
              {{ file.original_name }}
              <v-icon small class="ml-2">{{ icons.mdiCloseCircle }}</v-icon>
            </v-chip>
            <v-chip
              v-for="(file, index) in attachments"
              :key="`attachment-${index}`"
              class="mx-2 mb-2"
              close
              @click:close="removeFile(attachments, file)"
            >
              {{ file.name }}
              <v-icon small class="ml-2">{{ icons.mdiCloseCircle }}</v-icon>
            </v-chip>
          </template>
        </template>
        <v-card-actions class="px-0 pb-0">
          <v-spacer />
          <v-btn depressed @click="closeDialog">Abbrechen</v-btn>
          <v-btn
            :disabled="!hasTypeSubjectMessageAndIsNotSending"
            depressed
            type="submit"
            color="cta"
            class="white--text"
            >Senden</v-btn
          >
        </v-card-actions>
        <v-overlay
          v-if="sending"
          absolute
          color="white"
          opacity="1"
          class="text-center blue-grey--text"
        >
          <v-progress-circular color="cta" indeterminate></v-progress-circular>
          <p class="mt-5">Sende E-Mail...</p>
        </v-overlay>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiPlus, mdiMinus, mdiDelete, mdiFileUpload } from "@mdi/js";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import DropzoneFiles from "@/components/filemanager/DropzoneFiles.vue";

const mailSizeLimit = 16777216;

export default {
  name: "NewEmailDialog",
  components: { DropzoneFiles },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    userType: {
      type: String,
      default: "interessent",
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
        mdiPlus,
        mdiMinus,
        mdiDelete,
        mdiFileUpload,
      },
      subject: null,
      message: null,
      adressAuswahl: ["Heimadresse", "Baustellenadresse"],
      selectedAdresstyp: "Heimadresse",
      selectedBauvorhaben: {},
      additionalRecipients: [],
      currentAttachments: [],
      attachments: [],
      allowedFileTypes: [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.presentation",
      ],
      dropzoneInfotext:
        "Zugelassen sind max. 10 Anlagen mit bis zu 16 MB insgesamt und die Formate .jpg, .png und .pdf.",
      dragging: false,
      types: [
        { text: "Blanko", value: "blank" },
        { text: "Vorlage", value: "template" },
      ],
      rules: {
        required: (value) => !!value || "Dies ist ein Pflichtfeld.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Keine gültige E-Mail Adresse.";
        },
        attachments: (attachments) => {
          let fileSizeRaw = 0;
          attachments.forEach((attachment) => {
            fileSizeRaw += attachment.size;
          });

          if (
            attachments.length !== 0 &&
            attachments.some((attachment) => {
              return (
                attachment.type === "application/pdf" ||
                attachment.type === "image/jpeg" ||
                attachment.type === "image/png"
              );
            }) === false
          ) {
            return "Ungültiges Dateiformat erkannt. Erlaubt sind nur die Dateiformate .jpg, .jpeg, .png und .pdf.";
          } else if (
            attachments.length !== 0 &&
            fileSizeRaw >= mailSizeLimit &&
            attachments.length > 4
          ) {
            return "Erlaubt sind max. 4 Dateien mit insgesamt max. 16 MB.";
          } else if (attachments.length !== 0 && fileSizeRaw >= mailSizeLimit) {
            return "Maximale Gesamtgröße von 16 MB überschritten.";
          } else if (attachments.length > 4) {
            return "Erlaubt sind max. 4 Dateien.";
          } else {
            return true;
          }
        },
      },
      type: null,
      templates: [],
      template: null,
      loading: false,
      sending: false,
    };
  },
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    anrede() {
      let anrede = "";

      if (this.$props.user.anrede && this.$props.user.anrede.includes("Herr")) {
        anrede = "Sehr geehrter ";
      } else {
        anrede = "Sehr geehrte ";
      }

      return anrede;
    },
    hasAttachments() {
      return this.currentAttachments.length > 0 || this.attachments.length > 0;
    },
    attachmentsCount() {
      return this.currentAttachments.length + this.attachments.length;
    },
    attachmentSizeRaw() {
      let size = 0;
      this.currentAttachments.forEach((attachment) => {
        size += attachment.size;
      });
      this.attachments.forEach((attachment) => {
        size += attachment.size;
      });
      return size;
    },
    attachmentSize() {
      return (this.attachmentSizeRaw / (1024 * 1024)).toFixed(2);
    },
    hasTypeSubjectMessageAndIsNotSending() {
      return (
        this.type && this.subject && this.message && this.sending === false
      );
    },
  },
  methods: {
    async fetchEmailTemplates() {
      const query = {
        templateType: "email",
        view: "personal",
      };

      this.loading = true;
      await ApiService.fetchTemplates(query)
        .then((res) => {
          this.templates = res.data.collection;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            message: "Beim Laden der Vorlagen ist ein Fehler aufgetreten:",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectTemplate(template) {
      this.template = template;
      this.attachments = [];
      this.initializeFields();
    },
    cutString(text, maxlength) {
      if (text.length <= maxlength) {
        return text;
      }
      return text.substr(0, maxlength) + "\u2026";
    },
    addRecipient() {
      if (this.additionalRecipients.length <= 2) {
        this.additionalRecipients.push({ email: "" });
      } else {
        const notification = {
          type: "warning",
          title: "Maximale Empfänger erreicht.",
          message: "Es können maximal 3 weitere Empfänger hinzugefügt werden.",
        };

        this.$store.commit("notifications/ADD", notification);
      }
    },
    removeRecipient(recipient) {
      this.additionalRecipients.splice(
        this.additionalRecipients.indexOf(recipient),
        1
      );
    },
    initializeFields() {
      this.subject = this.template.betreff;
      this.message = this.template.nachricht;
      this.currentAttachments = this.template.files.map((file) => {
        return {
          id: file.id,
          name: file.name,
          original_name: file.original_name,
          path: file.path,
          size: file.size,
          type: file.type,
        };
      });
    },
    onSelectType() {
      if (this.type === "template") {
        this.fetchEmailTemplates();
      } else {
        this.resetTemplate();
      }
    },
    resetTemplate() {
      this.template = null;
      this.subject = null;
      this.message = null;
      this.currentAttachments = [];
      this.attachments = [];
    },
    addFiles(files) {
      this.handleFilesAndNotifications(files);
    },
    handleFilesAndNotifications(files) {
      let notification = {
        type: "success",
        title: "Erfolg",
        message:
          files.length === 1
            ? `${files.length} Datei wurde hinzugefügt.`
            : `${files.length} Dateien wurden hinzugefügt.`,
      };

      Array.from(files).forEach((file) => {
        if (this.attachments.find((f) => f.name === file.name)) {
          notification = {
            type: "error",
            title: "Fehler",
            message: `Datei ${file.name} existiert bereits.`,
          };
        } else if (!this.allowedFileTypes.includes(file.type)) {
          notification = {
            type: "error",
            title: "Fehler",
            message: `Dateityp ${file.type} nicht erlaubt.`,
          };
        } else {
          this.attachments.push(file);
        }
      });

      this.$store.commit("notifications/ADD", notification);
    },
    removeFile(attachmentsArray, file) {
      attachmentsArray.splice(attachmentsArray.indexOf(file), 1);
    },
    removeAllFiles() {
      this.currentAttachments = [];
      this.attachments = [];
    },
    async submit() {
      const payload = new FormData();

      payload.append("recipientType", this.$props.userType);
      payload.append("recipientId", this.user.id);
      payload.append("mailType", this.type);
      if (this.template) payload.append("templateId", this.template.id);
      payload.append("subject", this.subject);
      payload.append("message", this.message);

      for (let i = 0; i < this.additionalRecipients.length; i++) {
        payload.append(
          "additionalRecipients[]",
          this.additionalRecipients[i].email
        );
      }

      for (let i = 0; i < this.attachments.length; i++) {
        payload.append("attachments[]", this.attachments[i]);
      }

      this.sending = true;
      await ApiService.sendMail(payload)
        .then(() => {
          this.$emit("sent");
          this.resetTemplate();
          this.type = null;
          const notification = {
            type: "success",
            title: "E-Mail versendet",
            message: "Die E-Mail wurde erfolgreich gesendet.",
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler beim Senden der E-Mail",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.sending = false;
        });
    },
    closeDialog() {
      this.type = null;
      this.resetTemplate();
      this.$emit("dialog:close");
    },
  },
};
</script>

<style scoped>
#dropzone {
  position: relative;
  border: 2px dashed #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 25px;
  text-align: center;
  margin-bottom: 25px;
}
</style>
