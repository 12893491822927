import ApiService from "@/services/ApiService";
import { DateTime } from "luxon";

export const namespaced = true;

export const state = {
  calendar: {
    view: "month",
    displayEvents: "own",
  },
  events: [],
  event: {
    name: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    start: null,
    end: null,
    color: "primary",
    allDay: true,
    timed: false,
    user: null,
    place: null,
    description: null,
    guest: null,
    userId: null,
    notificationType: "Benachrichtigung",
    notificationUnit: "day",
    notificationValue: 1,
    notificationTime: "09:00",
    notificationDateTime: DateTime.now().toFormat("yyyy-LL-dd 09:00"),
  },
  eventNotifications: [],
};

export const mutations = {
  SET_CALENDAR(state, calendar) {
    state.calendar = calendar;
  },
  SET_CALENDAR_VIEW(state, view) {
    state.calendar.view = view;
  },
  SET_CALENDAR_EVENTS_DISPLAY(state, displayEvents) {
    state.calendar.displayEvents = displayEvents;
  },
  SET_EVENTS(state, events) {
    state.events = events;
  },
  SET_EVENT(state, event) {
    state.event = event;
  },
  ADD_EVENT(state, event) {
    state.events.push(event);
  },
  REMOVE_EVENT(state, eventToRemove) {
    const index = state.events.indexOf(eventToRemove);
    if (index !== -1) {
      state.events.splice(index, 1);
    }
  },
};

export const actions = {
  async fetchCalendar({ commit, dispatch }) {
    try {
      dispatch("loading/loadingCalendarEvents", true, { root: true });
      const res = await ApiService.fetchCalendar();
      commit("SET_CALENDAR", res.data.data);
    } catch (err) {
      //
    } finally {
      dispatch("loading/loadingCalendarEvents", false, { root: true });
    }
  },
  async fetchEvents({ commit, dispatch }) {
    try {
      dispatch("loading/loadingCalendarEvents", true, { root: true });
      const res = await ApiService.fetchCalendarEvents();
      commit("SET_EVENTS", res.data.data);
    } catch (err) {
      //
    } finally {
      dispatch("loading/loadingCalendarEvents", false, { root: true });
    }
  },
  async fetchOwnEvents({ commit, dispatch }) {
    try {
      dispatch("loading/loadingCalendarEvents", true, { root: true });
      const res = await ApiService.fetchCalendarEventsOwnOnly();
      commit("SET_EVENTS", res.data.data);
    } catch (err) {
      //
    } finally {
      dispatch("loading/loadingCalendarEvents", false, { root: true });
    }
  },
  setEvent({ commit }, event) {
    commit("SET_EVENT", event);
  },
  addEvent({ commit }, event) {
    commit("ADD_EVENT", event);
  },
  removeEvent({ commit }, eventToRemove) {
    commit("REMOVE_EVENT", eventToRemove);
  },
};

export const getters = {
  eventsThisWeek: (state) => {
    const weekStart = DateTime.now().toISODate();
    const weekEnd = DateTime.now().endOf("week").toISODate();
    return state.events.map((event) => {
      return event.startDate >= weekStart && event.startDate <= weekEnd
        ? event
        : null;
    });
  },
};
