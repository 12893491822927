<template>
  <v-dialog :value="value" max-width="800" persistent>
    <FormNewWiedervorlage
      v-if="showNewForm"
      :user="user"
      :grund="grund"
      @close="closeWvDialog"
    />
    <FormEditWiedervorlage
      v-if="showEditForm"
      :user="user"
      :wiedervorlage="wiedervorlage"
      @close="closeWvDialog"
    />
  </v-dialog>
</template>

<script>
import FormEditWiedervorlage from "@/components/forms/FormEditWiedervorlage.vue";
import FormNewWiedervorlage from "@/components/forms/FormNewWiedervorlage.vue";

export default {
  components: { FormNewWiedervorlage, FormEditWiedervorlage },
  props: {
    user: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    showNewForm: {
      type: Boolean,
      required: true,
    },
    showEditForm: {
      type: Boolean,
      required: true,
    },
    wiedervorlage: {
      type: Object,
      required: false,
    },
    grund: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      newWv: false,
      editWv: false,
      selectedWv: null,
    };
  },
  methods: {
    closeWvDialog() {
      this.wvDialog = false;
      this.newWv = false;
      this.editWv = false;
      this.selectedWv = null;

      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
