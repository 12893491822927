import Layout from "@/views/backend/tickets/Layout.vue";

export default [
  {
    path: "tickets",
    navigationName: "Tickets",
    meta: { requiresAuth: true, searchFilter: true },
    component: Layout,
    children: [
      {
        path: "/",
        name: "tickets-list",
        navigationName: "Tickets",
        meta: { requiresAuth: true, searchFilter: true },
        component: () => import("@/views/backend/tickets/List.vue"),
      },
      {
        path: "new",
        name: "tickets-new",
        navigationName: "Neues Ticket",
        meta: {
          requiresAuth: true,
          layout: "Backend",
          showBackButton: true,
          backButtonPath: "/tickets",
        },
        component: () => import("@/views/backend/tickets/New.vue"),
      },
      {
        path: ":id",
        name: "tickets-show",
        navigationName: "Ticket Details",
        meta: {
          requiresAuth: true,
          layout: "Backend",
          showBackButton: true,
          backButtonPath: "/tickets",
        },
        component: () => import("@/views/backend/tickets/Show.vue"),
      },
      {
        path: ":id/edit",
        name: "tickets-edit",
        navigationName: "Ticket bearbeiten",
        meta: {
          requiresAuth: true,
          layout: "Backend",
          showBackButton: true,
          backButtonPath: "/tickets",
        },
        component: () => import("@/views/backend/tickets/Edit.vue"),
      },
    ],
  },
];
