<template>
  <v-app>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </v-main>
    <!-- Notifications -->
    <NotificationsContainer />
    <UpdateAvailableSnackbar />
  </v-app>
</template>

<script>
import NotificationsContainer from "@/components/notifications/NotificationsContainer.vue";
import UpdateAvailableSnackbar from "../components/UpdateAvailableSnackbar.vue";
export default {
  name: "Frontend",
  components: { UpdateAvailableSnackbar, NotificationsContainer },
  mounted() {
    this.$store.dispatch("loading/logout", false);
  },
};
</script>

<style scoped></style>
