var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-item',[_c('v-list-item-content',[_c('p',{staticClass:"mb-0 text-body-2",class:{ 'font-weight-bold': _vm.activity.read_at === null },staticStyle:{"line-height":"1.4"}},[(_vm.activity.type === 'App\\Notifications\\UserAssociated')?[_vm._v(" Dir wurde der Interessent "),_c('span',{staticClass:"cta--text textlink",on:{"click":function($event){return _vm.markAsReadAndNavigate(
              `/backend/users/interessenten/${_vm.activity.data.kunde.uuid}`,
              _vm.activity
            )}}},[_vm._v(" "+_vm._s(_vm.activity.data.kunde.vorname)+" "+_vm._s(_vm.activity.data.kunde.nachname)+" ")]),_vm._v(" zugewiesen. ")]:(_vm.activity.type === 'App\\Notifications\\NewTicket')?[_vm._v(" Neues Ticket erhalten "),_c('span',{staticClass:"cta--text textlink",on:{"click":function($event){return _vm.markAsReadAndNavigate(
              `/backend/tickets/${_vm.activity.data.ticket.uuid}`,
              _vm.activity
            )}}},[_vm._v("Ticket "+_vm._s(_vm.activity.data.ticket.id))])]:(_vm.activity.type === 'App\\Notifications\\NewTicketComment')?[_vm._v(" Neuer Kommentar zu "),_c('span',{staticClass:"cta--text textlink",on:{"click":function($event){return _vm.markAsReadAndNavigate(
              `/backend/tickets/${_vm.activity.data.ticket.uuid}`,
              _vm.activity.id
            )}}},[_vm._v("Ticket "+_vm._s(_vm.activity.data.ticket.id))])]:(_vm.activity.type === 'App\\Notifications\\TicketUpdated')?[_c('span',{staticClass:"cta--text textlink",on:{"click":function($event){return _vm.markAsReadAndNavigate(
              `/backend/tickets/${_vm.activity.data.ticket.uuid}`,
              _vm.activity
            )}}},[_vm._v("Ticket "+_vm._s(_vm.activity.data.ticket.id))]),_vm._v(" wurde aktualisiert. ")]:(
          _vm.activity.type ===
          'App\\Notifications\\BauvorhabenUpdatedNotification'
        )?[_c('span',{staticClass:"cta--text textlink",on:{"click":function($event){return _vm.markAsReadAndNavigate(
              `/backend/bauvorhaben/${_vm.activity.data.uuid}`,
              _vm.activity
            )}}},[_vm._v(" "+_vm._s(_vm.activity.data.message))])]:(
          _vm.activity.type === 'App\\Notifications\\VacationUpdatedNotification'
        )?[_vm._v(" "+_vm._s(_vm.activity.data.message)+" ")]:(
          _vm.activity.type ===
          'App\\Notifications\\BauberatungCreatedNotification'
        )?[_c('span',{staticClass:"cta--text textlink",on:{"click":function($event){return _vm.markAsReadAndNavigate(
              `/backend/bauberatungen/${_vm.activity.data.uuid}`,
              _vm.activity
            )}}},[_vm._v(" "+_vm._s(_vm.activity.data.message)+" ")])]:_vm._e()],2),_c('p',{staticClass:"mb-0 caption blue--text"},[_vm._v(_vm._s(_vm.getActivityTime(_vm.activity)))])]),_c('v-list-item-action',[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: submenu }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},submenu),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}])},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{attrs:{"disabled":_vm.activity.read_at !== null,"link":""},on:{"click":function($event){return _vm.onMarkAsRead(_vm.activity)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCheckCircle))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Als gelesen markieren ")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.remove(_vm.activity.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDelete))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Benachrichtigung löschen ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }