<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-stepper v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step
              :complete="currentStep > 1"
              :color="currentStep > 1 ? 'success' : 'primary'"
              step="1"
            >
              E-Mail Adresse verifizieren
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="currentStep > 2"
              :color="currentStep > 2 ? 'success' : 'primary'"
              step="2"
            >
              Passwort vergeben
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="currentStep > 3" step="3">
              Zwei-Faktor-Authentifizierung aktivieren
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <VerifyEmail />
            </v-stepper-content>
            <v-stepper-content step="2">
              <FirstLoginChangePassword />
            </v-stepper-content>
            <v-stepper-content step="3">
              <FirstLoginTwoFactorAuth />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VerifyEmail from "@/components/init/VerifyEmail.vue";
import FirstLoginChangePassword from "@/components/init/FirstLoginChangePassword.vue";
import FirstLoginTwoFactorAuth from "@/components/init/FirstLoginTwoFactorAuth.vue";

export default {
  name: "FirstRun",
  props: ["type"],
  components: {
    FirstLoginTwoFactorAuth,
    FirstLoginChangePassword,
    VerifyEmail,
  },
  data: () => ({
    currentStep: 1,
  }),
  created() {
    if (this.verifyEmailRoute) {
      this.currentStep = 1;
    } else if (this.setCredentialsRoute) {
      this.currentStep = 2;
    } else if (this.twoFactorRoute) {
      this.currentStep = 3;
    }
  },
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    verifyEmailRoute() {
      return this.$route.name === "verify-email";
    },
    setCredentialsRoute() {
      return this.$route.name === "first-run-change-password";
    },
    twoFactorRoute() {
      return this.$route.name === "first-run-enable-two-factor-auth";
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "first-run-change-password") {
      this.$nextTick(() => {
        this.currentStep = 2;
      });
    } else if (to.name === "first-run-enable-two-factor-auth") {
      this.$nextTick(() => {
        this.currentStep = 3;
      });
    }
    next();
  },
};
</script>

<style scoped></style>
