import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export const namespaced = true;

export const state = () => ({
  createdCount: 0,
  draftCount: 0,
  sentCount: 0,
  pendingCount: 0,
  overdueCount: 0,
  netOpenTotal: 0,
  grossOpenTotal: 0,
});

export const mutations = {
  SET_DRAFT_COUNT(state, payload) {
    state.draftCount = payload;
  },
  SET_CREATED_COUNT(state, payload) {
    state.createdCount = payload;
  },
  SET_SENT_COUNT(state, payload) {
    state.sentCount = payload;
  },
  SET_PENDING_COUNT(state, payload) {
    state.pendingCount = payload;
  },
  SET_OVERDUE_COUNT(state, payload) {
    state.overdueCount = payload;
  },
  SET_NET_OPEN_TOTAL(state, payload) {
    state.netOpenTotal = payload;
  },
  SET_GROSS_OPEN_TOTAL(state, payload) {
    state.grossOpenTotal = payload;
  },
};

export const actions = {
  async fetchInvoiceMetaData({ commit }) {
    commit("loading/LOADING_INVOICES", true, { root: true });
    await ApiService.fetchInvoiceMeta()
      .then((res) => {
        commit("SET_DRAFT_COUNT", res.data.draft_count);
        commit("SET_CREATED_COUNT", res.data.created_count);
        commit("SET_SENT_COUNT", res.data.sent_count);
        commit("SET_PENDING_COUNT", res.data.pending_count);
        commit("SET_OVERDUE_COUNT", res.data.overdue_count);
        commit("SET_NET_OPEN_TOTAL", res.data.net_open_total);
        commit("SET_GROSS_OPEN_TOTAL", res.data.gross_open_total);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        commit("loading/LOADING_INVOICES", false, { root: true });
      });
  },
};
