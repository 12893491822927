import StatisticsLayout from "@/views/backend/stats/StatisticsLayout.vue";

export default [
  {
    path: "statistics",
    meta: { requiresAuth: true, searchFilter: true },
    component: StatisticsLayout,
    children: [
      {
        path: "/",
        name: "stats",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/stats/StatisticsJahresumsaetze.vue"),
      },
      {
        path: "finanzauswertung",
        name: "stats-finanzauswertung",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/stats/StatisticsFinanzauswertung.vue"),
      },
      {
        path: "ertragsauswertung",
        name: "stats-ertragsauswertung",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/stats/StatisticsErtragsauswertung.vue"),
      },
      {
        path: "finanzvorschau",
        name: "stats-finanzvorschau",
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import("@/views/backend/stats/StatisticsFinanzvorschau.vue"),
      },
    ],
  },
];
