<template>
  <v-snackbar bottom right vertical :value="updateExists" :timeout="-1">
    <p class="mb-0 white--text">
      <span class="text-h5 white--text">Aktualisierung verfügbar!</span
      ><br />Klicke <strong>NEU LADEN</strong> um die aktuelle Version zu laden.
    </p>
    <template v-slot:action>
      <v-btn depressed color="success" class="white--text" @click="refreshApp"
        >Neu laden</v-btn
      >
    </template>
  </v-snackbar>
</template>

<script>
import update from "../instance/mixins/pwa/updates";

export default {
  name: "UpdateAvailableSnackbar",
  mixins: [update],
};
</script>

<style scoped></style>
