<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-sheet outlined rounded class="pa-5">
          <v-list nav rounded>
            <v-list-item
              v-for="(navItem, index) in navItems"
              :key="`nav-item-${index}`"
            >
              <v-list-item-icon>
                <v-icon>{{ navItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link
                    :to="navItem.path"
                    class="text-decoration-none"
                    exact
                    exact-active-class="font-weight-bold"
                  >
                    {{ navItem.title }}
                  </router-link>
                </v-list-item-title>
                <template v-for="(child, index) in navItem.children">
                  <v-list-item-subtitle :key="`child-${index}`" class="mb-2">
                    <router-link
                      :to="child.path"
                      class="text-decoration-none"
                      exact-active-class="font-weight-bold"
                    >
                      {{ child.title }}
                    </router-link></v-list-item-subtitle
                  >
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="10">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiBookOpenPageVariant } from "@mdi/js";

export default {
  name: "BauberatungenLayout",
  data() {
    return {
      navItems: [
        {
          title: "Alle",
          path: "/backend/katalogbestellungen",
          icon: mdiBookOpenPageVariant,
          // children: [
          //   { title: "Bestätigt", path: "/backend/bauberatungen/confirmed" },
          //   {
          //     title: "Unbestätigt",
          //     path: "/backend/bauberatungen/unconfirmed",
          //   },
          // ],
        },
        {
          title: "Anschreiben",
          path: "/backend/katalogbestellungen/anschreiben",
          icon: mdiBookOpenPageVariant,
          // children: [
          //   { title: "Bestätigt", path: "/backend/bauberatungen/confirmed" },
          //   {
          //     title: "Unbestätigt",
          //     path: "/backend/bauberatungen/unconfirmed",
          //   },
          // ],
        },
      ],
    };
  },
};
</script>

<style scoped></style>
