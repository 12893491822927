<template>
  <v-card outlined class="pa-5 fill-height overflow-y-auto" max-height="400">
    <v-card-title class="text-h5 pt-0 px-0"> Urlaub </v-card-title>
    <v-sheet outlined rounded class="overflow-y-auto">
      <v-data-table
        :headers="dataTableHeaders"
        :items="vacationCollection"
        :footer-props="dataTableFooterProps"
        :loading="fetching"
        :items-per-page="-1"
        hide-default-footer
        loading-text="Urlaubszeiten werden geladen..."
        no-data-text="Keine Urlaubszeiten für diese Woche."
      >
        <template v-slot:item.user="{ item }">
          {{ item.user.vorname }} {{ item.user.nachname }}
        </template>
        <template v-slot:item.from_date="{ item }">
          {{ formatDateToShort(item.from_date) }}
        </template>
        <template v-slot:item.to_date="{ item }">
          {{ formatDateToShort(item.to_date) }}
        </template>
      </v-data-table>
    </v-sheet>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import { DateTime } from "luxon";
import { mdiArrowCollapseLeft, mdiArrowCollapseRight } from "@mdi/js";

export default {
  name: "DashboardWidgetUrlaub",
  data: () => ({
    fetching: false,
    error: null,
    vacationCollection: [],
    dataTableHeaders: [
      { text: "Mitarbeiter", value: "user", sortable: false },
      { text: "Von", value: "from_date" },
      { text: "Bis", value: "to_date" },
      { text: "Notiz", value: "notes" },
    ],

    dataTableFooterProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: mdiArrowCollapseLeft,
      lastIcon: mdiArrowCollapseRight,
      itemsPerPageOptions: [20, 50, -1],
    },
    showNewVacationDialog: false,
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
  },
  async mounted() {
    await this.fetchVacations();
  },
  methods: {
    async fetchVacations() {
      const config = {
        params: {
          year: DateTime.now().year,
          view: "dashboard",
        },
      };

      this.fetching = true;
      await ApiService.fetchVacations(config)
        .then((res) => {
          this.vacationCollection = res.data.collection;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    formatDateToShort(date) {
      return date
        ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
        : "";
    },
    closeDialog() {
      this.showNewVacationDialog = false;
    },
  },
};
</script>

<style scoped></style>
