var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showResultsCard || _vm.value)?_c('v-card',{staticClass:"pa-5 overflow-y-auto",attrs:{"absolute":"","offset-y":"","min-height":"300","max-height":"720"}},[_c('h3',{staticClass:"mb-2 ml-2"},[(_vm.$props.model === 'User')?_c('span',[_vm._v("Interessenten")]):(_vm.$props.model === 'Customer')?_c('span',[_vm._v("Kunden")]):(_vm.$props.model === 'Bauvorhaben')?_c('span',[_vm._v("Bauvorhaben")]):_vm._e()]),_c('v-list',{staticClass:"ma-0 pa-0",attrs:{"nav":""}},_vm._l((_vm.searchResults),function(item,index){return _c('v-list-item',{key:`item-${index}`,attrs:{"color":"cta","link":""},on:{"click":function($event){return _vm.selectAndNavigate(item)}}},[(_vm.$props.model === 'User' || _vm.$props.model === 'Customer')?_c('v-list-item-content',[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
            _vm.highlightText(
              `${item.id} - ${
                item.firma ? item.firma : item.vorname + ' ' + item.nachname
              } ${
                item.old_id
                  ? '(' +
                    item.old_id +
                    `)${
                      item.verkaeufer
                        ? ' | ' +
                          item.verkaeufer.vorname +
                          ' ' +
                          item.verkaeufer.nachname
                        : ''
                    }`
                  : ''
              }`
            )
          )}}),_c('p',{staticClass:"caption mb-0",domProps:{"innerHTML":_vm._s(
            _vm.highlightText(
              `${item.strasse} ${item.hausnr}, ${item.plz} ${item.ort}`
            )
          )}}),(item.telefonnr)?_c('p',{staticClass:"caption mb-0",domProps:{"innerHTML":_vm._s(_vm.highlightText(`${item.email} | ${item.telefonnr}`))}}):_c('p',{staticClass:"caption mb-0",domProps:{"innerHTML":_vm._s(_vm.highlightText(`${item.email}`))}})]):(_vm.$props.model === 'Bauvorhaben')?_c('v-list-item-content',[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
            _vm.highlightText(
              `${item.bv_nr} - ${item.customer.vorname} ${item.customer.nachname}`
            )
          )}}),_c('p',{staticClass:"caption mb-0",domProps:{"innerHTML":_vm._s(
            _vm.highlightText(
              `${item.strasse} ${item.hausnr}, ${item.plz} ${item.ort}`
            )
          )}})]):_vm._e()],1)}),1),(_vm.searchResults.length === 0)?_c('p',[_c('v-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(_vm.icons.mdiMagnify))]),_vm._v(" Es wurden keine Ergebnisse für \""),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.searchQuery))]),_vm._v("\" gefunden. ")],1):_vm._e(),_c('v-overlay',{attrs:{"color":"white","opacity":1,"absolute":"","value":_vm.awaitingResponse}},[_c('v-progress-circular',{attrs:{"color":"cta","indeterminate":"","size":"48"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }