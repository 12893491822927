import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export const namespaced = true;

export const state = () => ({
  count: 0,
});

export const mutations = {
  SET_COUNT(state, count) {
    state.count = count;
  },
};

export const actions = {
  async fetchDuplicatesAndSetCount({ commit }) {
    await ApiService.fetchDuplicates()
      .then((res) => {
        commit("SET_COUNT", res.data.meta.count);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("notifications/ADD", notification, { root: true });
      });
  },
};
