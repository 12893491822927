<template>
  <div id="dropzone">
    <input
      ref="fileInput"
      type="file"
      :accept="allowedFileTypesJoined"
      multiple
      class="d-none"
      @change="emitChange"
    />
    <template v-if="dragging">
      <v-icon size="64" color="cta">{{ icons.mdiFileUpload }}</v-icon>
      <p class="mb-0 font-weight-bold">
        Jetzt loslassen, um Dateien hochzuladen.
      </p>
    </template>
    <template v-else>
      <v-icon size="64" color="primary">{{ icons.mdiFileUpload }}</v-icon>
      <p class="mb-0 font-weight-bold">
        Hier klicken oder Dateien hier ablegen.
      </p>
    </template>
    <p v-if="infotext" class="caption">
      {{ infotext }}
    </p>
    <v-overlay v-if="uploading" absolute color="white" opacity="1">
      <div>
        <p>Upload läuft. Bitte warten...</p>
        <v-progress-circular
          :rotate="-90"
          :size="100"
          :width="15"
          :value="uploadProgress"
          color="primary"
        >
          <span class="font-weight-bold">{{ uploadProgress }}%</span>
        </v-progress-circular>
      </div>
    </v-overlay>
    <v-overlay
      v-else
      absolute
      opacity="0"
      @click="onClickDropzone"
      @dragenter.prevent="onDragEnter"
      @dragover.prevent="onDragEnter"
      @dragleave.prevent="onDragLeave"
      @drop.prevent="emitChange"
    ></v-overlay>
  </div>
</template>

<script>
import { mdiFileUpload } from "@mdi/js";
export default {
  name: "DropzoneFiles",
  props: {
    allowedFileTypes: {
      type: Array,
      default: () => [
        "application/pdf",
        "image/jpeg",
        "image/png",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.presentation",
      ],
    },
    infotext: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      icons: {
        mdiFileUpload,
      },
      dragging: false,
      uploading: false,
      uploadProgress: 0,
    };
  },
  computed: {
    allowedFileTypesJoined() {
      return this.allowedFileTypes.join(", ");
    },
  },
  methods: {
    onClickDropzone() {
      this.$nextTick(() => {
        this.$refs.fileInput.calue = "";
        this.$refs.fileInput.click();
      });
    },
    onDragEnter() {
      this.dragging = true;
    },
    onDragLeave() {
      this.dragging = false;
    },
    emitChange(e) {
      if (e.target.files) {
        this.$emit("change", e.target.files);
      }

      if (e.dataTransfer.files) {
        this.$emit("change", e.dataTransfer.files);
      }

      this.onDragLeave();
    },
  },
};
</script>

<style scoped>
#dropzone {
  position: relative;
  background-color: #fff;
  border: 2px dashed #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 50px 25px;
  text-align: center;
  margin-bottom: 25px;
}
</style>
