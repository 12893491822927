import ApiService from "@/services/ApiService";
import axios from "axios";
import router from "@/router";
import { DateTime } from "luxon";
import { getError } from "@/util/helpers";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;

export const namespaced = true;

export const state = {
  authUser: {},
  users: [],
  interessenten: [],
  kunden: [],
  wiedervorlagen: [],
  katalogbestellungen: [],
  bauberatungen: [],
  // Users Liste für Dropdown / Auflistung
  verkaeufer: [],
  planer: [],
  sachbearbeiter: [],
  personal: [],
  user: {
    katalogbestellungen: [],
    bauberatungen: [],
    events: [],
    wiedervorlagen: [],
  },
  causedActions: [],
  userType: "",
  sortBy: null,
  sortDesc: false,
  automatisch_zuweisen: false,
  kontaktpraeferenzen: [
    "Keine Angabe",
    "E-Mail",
    "Telefon",
    "Videocall",
    "Kein Kontakt gewünscht",
  ],
  gruppen: [
    "Bauleitung",
    "Buchhaltung",
    "Fertigung",
    "Geschäftsführung",
    "Montage",
    "Planung",
    "Verkauf",
    "Verwaltung",
  ],
  pagination: {
    paginationCurrentPage: 0,
    perPageItems: [10, 20, 50, 100],
    perPage: 20,
    from: 1,
    to: 1,
    lastPage: 0,
    path: "",
    links: [],
    total: 0,
  },
  editMode: false,
};

export const mutations = {
  SET_AUTH_USER(state, payload) {
    state.authUser = payload;
  },
  SET_USERS(state, payload) {
    if (payload.meta) {
      state.users = payload.data;
    } else {
      state.users = payload;
    }
  },
  SET_INTERESSENTEN(state, payload) {
    if (payload.meta) {
      state.interessenten = payload.data;
    } else {
      state.interessenten = payload;
    }
  },
  SET_KUNDEN(state, payload) {
    if (payload.meta) {
      state.kunden = payload.data;
    } else {
      state.kunden = payload;
    }
  },
  SET_PERSONAL(state, payload) {
    if (payload.data) {
      state.personal = payload.data;
      this.commit("user/SET_PAGINATION", payload.data);
    } else {
      state.personal = payload;
    }
  },
  SET_VERKAEUFER(state, payload) {
    state.verkaeufer = payload;
  },
  SET_PLANER(state, payload) {
    state.planer = payload;
  },
  SET_SACHBEARBEITER(state, payload) {
    state.sachbearbeiter = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_USER_TYPE(state, type) {
    state.userType = type;
  },
  SET_CAUSED_ACTIONS(state, payload) {
    if (payload.data) {
      state.causedActions = payload.data;
      this.commit("user/SET_PAGINATION", payload.data);
    } else {
      state.causedActions = payload;
    }
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  },
  SET_AUTOMATISCH_ZUWEISEN(state, payload) {
    state.automatisch_zuweisen = payload;
  },
  SET_WIEDERVORLAGEN(state, payload) {
    state.wiedervorlagen = payload;
  },
  SET_KATALOGBESTELLUNGEN(state, payload) {
    state.katalogbestellungen = payload;
  },
  SET_BAUBERATUNGEN(state, payload) {
    state.bauberatungen = payload;
  },
  RESET_AUTH_USER(state) {
    state.authUser = null;
  },
  SET_EDIT_MODE(state, payload) {
    state.editMode = payload;
  },
  SET_PER_PAGE(state, payload) {
    state.pagination.perPage = payload;
  },
  SET_CURRENT_PAGE(state, payload) {
    state.pagination.currentPage = payload;
  },
  SET_PAGINATION(state, payload) {
    if (payload) {
      state.pagination.currentPage = payload.current_page;
      state.pagination.perPage = payload.per_page;
      state.pagination.from = payload.from;
      state.pagination.to = payload.to;
      state.pagination.lastPage = payload.last_page;
      state.pagination.path = payload.path;
      state.pagination.links = payload.links;
      state.pagination.total = payload.total;
    }
  },
};

export const actions = {
  async createNewUser({ commit, dispatch }, payload) {
    dispatch("loading/awaitingResponse", true, { root: true });
    await ApiService.createNewUser(payload)
      .then((res) => {
        let notification;

        if (res.data.type === "possible_duplicate") {
          notification = {
            type: "warning",
            title: res.data.title,
            message: `Möglicher Konflikt mit einem vorhandenen Benutzer:<br>
${res.data.user.anrede} ${res.data.user.vorname} ${res.data.user.nachname}<br>
${res.data.request.anrede} ${res.data.request.vorname} ${res.data.request.nachname}`,
            timeout: false,
          };
        } else {
          commit("SET_USER", res.data.data);
          notification = {
            type: "success",
            title: "Erfolg",
            message: `Der Benutzer ${res.data.data.vorname} ${res.data.data.nachname} wurde gespeichert.`,
          };
        }
        dispatch("notifications/add", notification, { root: true });
        router.push(`/backend/users/interessenten/${res.data.data.uuid}`);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },
  async updateSingleUser({ dispatch, commit, state }, payload) {
    dispatch("loading/awaitingResponse", true, { root: true });
    await ApiService.updateSingleUser(payload)
      .then((res) => {
        commit("SET_USER", res.data.data);
        const notification = {
          type: "success",
          title: "Erfolg",
          message: "Benutzer wurde gespeichert.",
        };
        dispatch("notifications/add", notification, { root: true });
        if (
          state.user.roles.includes("Interessent") ||
          state.user.roles.includes("Kunde")
        ) {
          router.push({
            params: { id: state.user.uuid },
            name: "user-show",
          });
        }
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },
  async updateSystemUserProfile({ dispatch, commit }, payload) {
    dispatch("loading/awaitingResponse", true, { root: true });
    await ApiService.updateSystemUserProfile(payload)
      .then((res) => {
        commit("SET_AUTH_USER", res.data.data);
        const notification = {
          type: "success",
          title: "Erfolg",
          message: "Dein Profil wurde erfolgreich aktualisiert.",
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },

  updateStatus({ dispatch }, payload) {
    ApiService.updateUserStatus(payload).catch((err) => {
      const notification = {
        type: "error",
        title: "Fehler",
        message: (err && err.message) || err.statusText,
        timeout: false,
      };
      dispatch("notifications/add", notification, { root: true });
    });
  },

  async fetchAuthUser({ commit, dispatch }) {
    commit("loading/LOADING_AUTHUSER", true, { root: true });
    await ApiService.getAuthUser()
      .then((res) => {
        commit("SET_AUTH_USER", res.data.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        commit("loading/LOADING_AUTHUSER", false, { root: true });
      });
  },
  setAuthUser({ commit }, payload) {
    commit("SET_AUTH_USER", payload);
  },
  async getUsersByUserType(
    { dispatch, commit },
    { userType, perPage = null, sortBy = null, sortDesc = false }
  ) {
    dispatch("loading/loadingUsers", true, { root: true });
    await ApiService.getUsersByUserType(userType, perPage, sortBy, sortDesc)
      .then((res) => {
        commit("SET_USERS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingUsers", false, { root: true });
      });
  },
  async getUsersByFilter({ dispatch, commit }, filters) {
    dispatch("loading/loadingUsers", true, { root: true });
    ApiService.getUsersByFilter(filters)
      .then((res) => {
        commit("SET_USERS", res.data.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingUsers", false, { root: true });
      });
  },
  async getPersonal({ dispatch, commit }, sorting = null) {
    dispatch("loading/loadingMitarbeiter", true, { root: true });
    await ApiService.getPersonal(sorting)
      .then((res) => {
        commit("SET_PERSONAL", res.data);
        commit("SET_PAGINATION", res.data.meta);
      })
      .finally(() => {
        dispatch("loading/loadingMitarbeiter", false, { root: true });
      });
  },
  async getVerkaeufer({ dispatch, commit }) {
    dispatch("loading/loadingVerkaeufer", true, { root: true });
    await ApiService.getVerkaeufer()
      .then((res) => {
        commit("SET_VERKAEUFER", res.data.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingVerkaeufer", false, { root: true });
      });
  },
  async fetchInteressenten({ dispatch, commit }, sorting = null) {
    dispatch("loading/loadingInteressenten", true, { root: true });
    try {
      let res = await ApiService.fetchInteressenten(sorting);
      commit("SET_INTERESSENTEN", res.data);
      commit("SET_PAGINATION", res.data.meta);
    } catch (err) {
      const notification = {
        type: "error",
        title: "Fehler",
        message: (err && err.message) || err.statusText,
        timeout: false,
      };
      dispatch("notifications/add", notification, { root: true });
    } finally {
      dispatch("loading/loadingInteressenten", false, { root: true });
    }
  },

  async fetchCustomers({ commit }, sorting = null) {
    commit("loading/LOADING_KUNDEN", true, { root: true });
    await ApiService.fetchCustomers(sorting)
      .then((res) => {
        commit("SET_KUNDEN", res.data);
        commit("SET_PAGINATION", res.data.meta);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          error: getError(err),
        };

        commit("notifications/ADD", notification, { root: true });
      })
      .finally(() => {
        commit("loading/LOADING_KUNDEN", false, { root: true });
      });
  },

  async getSingleUser({ dispatch, commit }, id) {
    dispatch("loading/loadingSingleUser", true, { root: true });
    return await ApiService.getSingleUser(id)
      .then((res) => {
        commit("SET_USER", res.data.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingSingleUser", false, { root: true });
      });
  },
  async getCausedActions({ dispatch, commit }, { user, perPage = null }) {
    dispatch("loading/loadingCausedActions", true, { root: true });
    await ApiService.fetchCausedActions(user.uuid, perPage)
      .then((res) => {
        commit("SET_CAUSED_ACTIONS", res.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingCausedActions", false, { root: true });
      });
  },

  async getWiedervorlagenByUser({ commit, dispatch }, id) {
    await ApiService.getWiedervorlagenByUser(id)
      .then((res) => {
        commit("SET_WIEDERVORLAGEN", res.data);
        dispatch("getSingleUser", id);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      });
  },
  storeWiedervorlage({ dispatch }, data) {
    dispatch("loading/awaitingResponse", true, { root: true });
    return ApiService.storeWiedervorlage(data)
      .then(() => {
        const notification = {
          type: "success",
          title: "Erledigt",
          message: "Neue Wiedervorlage gespeichert.",
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },
  updateWiedervorlage({ dispatch }, data) {
    dispatch("loading/awaitingResponse", true, { root: true });
    ApiService.updateWiedervorlage(data)
      .then(() => {
        const notification = {
          type: "success",
          title: "Erledigt",
          message: "Wiedervorlage gespeichert.",
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },

  async goToPage({ dispatch, commit }, data) {
    try {
      dispatch("loading/loadingUsers", true, { root: true });
      const res = await axios.get(data.url);
      if (data.type === "interessenten") {
        commit("SET_INTERESSENTEN", res.data);
      } else if (data.type === "system") {
        commit("SET_PERSONAL", res.data);
      } else if (data.type === "causedActions") {
        commit("SET_CAUSED_ACTIONS", res.data);
      }
      commit("SET_PAGINATION", res.data.meta);
    } catch (err) {
      const notification = {
        type: "error",
        title: "Fehler",
        message: (err && err.message) || err.statusText,
        timeout: false,
      };
      await dispatch("notifications/add", notification, { root: true });
    } finally {
      await dispatch("sorting/fetchSortings", null, { root: true });
      await dispatch("loading/loadingUsers", false, { root: true });
    }
  },
  setPaginationItemsPerPage({ commit }, data) {
    commit("SET_PER_PAGE", data);
  },
  async getBauberatungen({ dispatch, commit }) {
    dispatch("loading/loadingUsers", true, { root: true });
    await ApiService.getBauberatungen()
      .then((res) => {
        commit("SET_BAUBERATUNGEN", res.data.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingUsers", false, { root: true });
      });
  },
  async getKatalogbestellungen({ dispatch, commit }) {
    dispatch("loading/loadingUsers", true, { root: true });
    await ApiService.getKatalogbestellungen()
      .then((res) => {
        commit("SET_KATALOGBESTELLUNGEN", res.data.data);
      })
      .catch((err) => {
        const notification = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notification, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingUsers", false, { root: true });
      });
  },
  async getKatalogbestellungenByDay({ commit, dispatch }, datum) {
    dispatch("loading/loadingUsers", true, { root: true });
    await ApiService.getKatalogbestellungenByDay(datum)
      .then((res) => {
        commit("SET_KATALOGBESTELLUNGEN", res.data.data);
      })
      .catch(() => {})
      .finally(() => {
        dispatch("loading/loadingUsers", false, { root: true });
      });
  },
  async newKatalogbestellung({ dispatch }, data) {
    dispatch("loading/awaitingResponse", true, { root: true });
    await ApiService.newKatalogbestellung(data)
      .then(() => {
        const notifications = {
          type: "success",
          title: "Erfolg",
          message: "Neue Kataloganfrage erstellt.",
        };
        dispatch("notifications/add", notifications, { root: true });
      })
      .catch((err) => {
        const notifications = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notifications, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },
  removeKatalogbestellung({ dispatch }, data) {
    dispatch("loading/awaitingResponse", true, { root: true });
    ApiService.removeKatalogbestellung(data)
      .then(() => {
        const notifications = {
          type: "success",
          title: "Erfolg",
          message: "Kataloganfrage gelöscht.",
        };
        dispatch("getKatalogbestellungen", null).then(() => {
          dispatch("notifications/add", notifications, { root: true });
        });
      })
      .catch((err) => {
        const notifications = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notifications, { root: true });
      })
      .finally(() => {
        dispatch("loading/awaitingResponse", false, { root: true });
      });
  },
  async associateUsers({ dispatch, state }, data) {
    dispatch("loading/loadingAssociation", true, { root: true });
    let users;

    if (data.herkunft === "Katalogbestellung") {
      users = state.katalogbestellungen
        .filter((katalogbestellung) => {
          return katalogbestellung.verkaeufer === null;
        })
        .map((item) => {
          return {
            id: item.id,
            email: item.email,
            telefonnr: item.telefonnr,
            verkaeufer_id: item.verkaeufer_id,
            katalogbestellung: item.katalogbestellung,
            bauberatung: item.bauberatung,
          };
        });
    }
    if (data.herkunft === "Bauberatung") {
      users = state.bauberatungen;
    }

    await ApiService.associateSellers({
      herkunft: data.herkunft,
      users: users,
    })
      .then(() => {
        const notification = {
          type: "success",
          title: "Erfolg",
          message: "Alle Verkäufer erfolgreich zugewiesen.",
        };
        dispatch("notifications/add", notification, { root: true });

        if (data.herkunft === "Katalogbestellung") {
          dispatch("getKatalogbestellungen");
        }
        if (data.herkunft === "Bauberatung") {
          dispatch("getBauberatungen");
        }
      })
      .catch(() => {})
      .finally(() => {
        dispatch("loading/loadingAssociation", false, { root: true });
      });
  },
  async generateKatalogAnschreiben({ commit, dispatch }, items) {
    dispatch("loading/loadingGeneration", true, { root: true });
    const itemsLength = items.length;
    await ApiService.generateAnschreiben(items)
      .then((res) => {
        const notifications = {
          type: "success",
          title: "Erfolg",
          message: itemsLength + " Anschreiben erfolgreich erstellt.",
        };
        dispatch("notifications/add", notifications, { root: true });
        commit("SET_KATALOGBESTELLUNGEN", []);
        window.open(
          `${process.env.VUE_APP_API_URL}${res.data.filepath}`,
          "_blank"
        );
        // dispatch("getKatalogbestellungen", null);
      })
      .catch((err) => {
        const notifications = {
          type: "error",
          title: "Fehler",
          message: (err && err.message) || err.statusText,
          timeout: false,
        };
        dispatch("notifications/add", notifications, { root: true });
      })
      .finally(() => {
        dispatch("loading/loadingGeneration", false, { root: true });
      });
  },

  setUser({ commit }, user) {
    commit("SET_USER", user);
  },
  setUsers({ commit }, users) {
    commit("SET_USERS", users);
  },

  setUserType({ commit }, type) {
    commit("SET_USER_TYPE", type);
  },
  resetAuthUser({ commit }) {
    commit("RESET_AUTH_USER");
  },
  setEditMode({ commit }, payload) {
    commit("SET_EDIT_MODE", payload);
  },
  setAutomatischZuweisen({ commit }, data) {
    commit("SET_AUTOMATISCH_ZUWEISEN", data);
  },
  setSortBy({ commit }, data) {
    commit("SET_SORT_BY", data);
  },
  setSortDesc({ commit }, data) {
    commit("SET_SORT_DESC", data);
  },
  resetState({ commit }) {
    commit("SET_AUTH_USER", {});
    commit("SET_USERS", []);
    commit("SET_INTERESSENTEN", []);
    commit("SET_KUNDEN", []);
    commit("SET_WIEDERVORLAGEN", []);
    commit("SET_KATALOGBESTELLUNGEN", []);
    commit("SET_BAUBERATUNGEN", []);
    commit("SET_VERKAEUFER", []);
    commit("SET_PLANER", []);
    commit("SET_SACHBEARBEITER", []);
    commit("SET_PERSONAL", []);
    commit("SET_USER", {});
    commit("SET_CAUSED_ACTIONS", []);
    commit("SET_USER_TYPE", "");
    commit("SET_SORT_BY", null);
    commit("SET_SORT_DESC", false);
    commit("SET_AUTOMATISCH_ZUWEISEN", false);
    commit("SET_PAGINATION", {
      currentPage: 0,
      perPageItems: [10, 20, 50, 100],
      perPage: 20,
      from: 1,
      to: 1,
      lastPage: 0,
      path: "",
      links: [],
      total: 0,
    });
  },
};

export const getters = {
  authUser: (state) => (state.authUser ? state.authUser : null),
  authUserVorname: (state) => (state.authUser ? state.authUser.vorname : ""),
  wiedervorlagen: (state) => {
    return state.wiedervorlagen.filter((user) => {
      return user ? user.wiedervorlagen.length > 0 : false;
    });
  },
  newInteressenten: (state) =>
    state.interessenten.filter((interessent) => {
      const start = DateTime.now().startOf("day");
      const end = DateTime.fromISO(interessent.created_at).startOf("day");
      const difference = start.diff(end, "days").toObject();
      return difference.days <= 1;
    }),
  wiedervorlagenTotal: (state) => {
    if (state.users.length) {
      return (
        Object.keys(state.users.thisWeek).length +
        Object.keys(state.users.nextWeek).length +
        Object.keys(state.users.later).length
      );
    }
  },
};
