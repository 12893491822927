<template>
  <div class="twofa">
    <v-card outlined class="pa-5">
      <v-card-title class="pt-0 px-0">
        2-Faktor-Authentifizierung (2FA)
      </v-card-title>
      <v-chip-group v-model="twoFactorType" color="primary" class="mb-4">
        <v-chip
          value="mail"
          large
          color="primary"
          :disabled="
            authUser.twoFactorSecret !== null &&
            authUser.twoFactorConfirmedAt !== null &&
            authUser.twoFactorType === 'app'
          "
          filter
          outlined
          >E-Mail</v-chip
        >
        <v-chip
          value="app"
          large
          color="primary"
          :disabled="
            authUser.twoFactorSecret !== null &&
            authUser.twoFactorConfirmedAt !== null &&
            authUser.twoFactorType === 'mail'
          "
          filter
          outlined
          >App</v-chip
        >
      </v-chip-group>

      <v-slide-y-transition>
        <v-alert
          v-if="authUser.twoFactorConfirmedAt"
          text
          dense
          prominent
          type="success"
        >
          Die Zwei-Faktor-Authentifizierung ist aktiv. Du wirst nun
          weitergeleitet.
        </v-alert>
        <v-alert
          v-else-if="!authUser.twoFactorSecret"
          text
          dense
          prominent
          type="error"
        >
          Die Zwei-Faktor-Authentifizierung ist deaktiviert. Wählen Sie eine der
          beiden Varianten aus.
        </v-alert>
        <v-alert v-else text dense prominent type="warning">
          <span v-if="authUser.twoFactorType === 'mail'">
            Wir haben Dir eine E-Mail mit einem Bestätigungscode gesendet. Bitte
            trage diesen Code in das untenstehende Feld ein.
          </span>
          <span v-else>
            <template v-if="qrCodeHtml === ''">
              Bitte gib den aktuellen Code aus Deiner Authentifizierungs-App auf
              Deinem Smartphone in das untere Feld ein.
            </template>
            <template v-else>
              Bitte scanne diesen QR-Code mit Deiner Authentifizierungs-App auf
              Deinem Smartphone.
            </template>
          </span>
        </v-alert>
      </v-slide-y-transition>
      <template
        v-if="authUser.twoFactorSecret && !authUser.twoFactorConfirmedAt"
      >
        <div class="mb-4 text-center" v-html="qrCodeHtml"></div>
        <v-form>
          <v-otp-input
            ref="formFieldOtp"
            v-model="twoFactorAuthCode"
            :disabled="loadingTwoFactorConfirmation"
            autofocus
            @finish="confirmTwoFactorAuthentication(twoFactorAuthCode)"
          >
          </v-otp-input>
        </v-form>
        <a
          v-if="authUser.twoFactorType === 'mail'"
          href="#"
          @click.prevent="resendMail"
          >Keine E-Mail erhalten? Hier klicken</a
        >
      </template>

      <v-card-actions v-if="!authUser.twoFactorConfirmedAt" class="pb-0 px-0">
        <v-spacer />
        <v-btn class="mr-2" depressed large @click="logout">Abbrechen</v-btn>
        <v-btn
          depressed
          :disabled="!twoFactorType"
          large
          color="primary"
          @click="dialogPasswordConfirmation = true"
          >2FA aktivieren</v-btn
        >
      </v-card-actions>
      <v-overlay
        v-if="
          loadingAuthUser ||
          loadingTwoFactorAuthenticationEnable ||
          loadingQrCode ||
          loadingTwoFactorConfirmation ||
          loadingPasswordConfirmation ||
          resending
        "
        absolute
        color="white"
        opacity="0.9"
        class="text-center blue-grey--text"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
        <p v-if="loadingPasswordConfirmation" class="mt-4">
          Passwort wird bestätigt...
        </p>
        <p v-else-if="loadingTwoFactorAuthenticationEnable" class="mt-4">
          Zwei-Faktor-Authentifizierung wird aktiviert...
        </p>
        <p v-else-if="loadingQrCode" class="mt-4">QR-Code wird geladen...</p>
        <p v-else-if="resending" class="mt-4">E-Mail wird erneut gesendet...</p>
        <p v-else-if="loadingTwoFactorConfirmation" class="mt-4">
          Zwei-Faktor-Authentifizierung wird bestätigt...
        </p>
        <p v-else-if="loadingAuthUser" class="mt-4">
          Benutzerdaten werden geladen...
        </p>
      </v-overlay>
    </v-card>
    <v-dialog
      v-model="dialogPasswordConfirmation"
      persistent
      max-width="800"
      width="100%"
    >
      <v-card outlined class="pa-5">
        <v-card-title class="pt-0 px-0">
          Sicherheitsbeschränkung <v-spacer />
          <v-btn icon @click="dialogPasswordConfirmation = false"
            ><v-icon>{{ icons.mdiClose }}</v-icon></v-btn
          >
        </v-card-title>
        <p>Bitte geben Sie Ihr aktuelles Passwort ein:</p>
        <v-text-field
          v-model="passwordConfirm"
          label="Ihr Passwort"
          :disabled="loadingPasswordConfirmation"
          :type="passwordConfirmVisible ? 'text' : 'password'"
          outlined
          :append-icon="passwordConfirmVisible ? icons.mdiEye : icons.mdiEyeOff"
          @click:append="passwordConfirmVisible = !passwordConfirmVisible"
          @keydown.enter="confirmPassword(passwordConfirm)"
        ></v-text-field>
        <v-card-actions class="pb-0 px-0">
          <v-spacer />
          <v-btn
            large
            depressed
            :disabled="
              passwordConfirm.length === 0 || loadingPasswordConfirmation
            "
            color="primary"
            :loading="loadingPasswordConfirmation"
            @click="confirmPassword(passwordConfirm)"
            >Senden</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <TwoFactorAuthenticationRecoveryCodesDialog
      :value="dialogRecoveryCodes"
      :recovery-codes="recoveryCodes"
      first-run
      @dialog:close="dialogRecoveryCodes = false"
    />
  </div>
</template>

<script>
import { mdiEye, mdiEyeOff, mdiClose } from "@mdi/js";
import ApiService from "@/services/ApiService";
import { mapActions } from "vuex";
import { getError } from "@/util/helpers";
import TwoFactorAuthenticationRecoveryCodesDialog from "@/components/dialogs/TwoFactorAuthenticationRecoveryCodesDialog.vue";

export default {
  name: "FirstLoginTwoFactorAuth",
  components: { TwoFactorAuthenticationRecoveryCodesDialog },
  data: () => ({
    icons: {
      mdiEye,
      mdiEyeOff,
      mdiClose,
    },
    dialogPasswordConfirmation: false,
    dialogRecoveryCodes: false,
    passwordConfirm: "",
    passwordConfirmVisible: false,
    twoFactorAuthCode: "",
    twoFactorType: null,
    twoFactorQrCodeAvailable: false,
    twoFactorAuthConfirmed: false,
    qrCodeHtml: "",
    recoveryCodesConfirmed: false,
    recoveryCodes: [],
    loadingPasswordConfirmation: false,
    loadingTwoFactorAuthenticationEnable: false,
    loadingTwoFactorAuthenticationDisable: false,
    loadingQrCode: false,
    loadingTwoFactorConfirmation: false,
    loadingRecoveryCodes: false,
    resending: false,
    rules: {
      required: (value) => !!value || "Dies ist ein Pflichtfeld.",
    },
  }),
  // async mounted() {
  //   if (this.authUser.id === undefined) {
  //     await this.fetchAuthUser().then(async () => {
  //       if (this.authUser.twoFactorType === "mail") {
  //         this.twoFactorType = "mail";
  //         if (
  //           this.authUser.twoFactorSecret &&
  //           !this.authUser.twoFactorConfirmedAt
  //         ) {
  //           await this.resendMail();
  //         }
  //       } else if (this.authUser.twoFactorType === "app") {
  //         this.twoFactorType = "app";
  //         if (this.authUser.twoFactorConfirmedAt === null) {
  //           await this.fetchTwoFactorQrCodeSvg();
  //         }
  //       }
  //     });
  //   }
  // },
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    loadingAuthUser() {
      return this.$store.state.loading.authUser;
    },
  },
  methods: {
    async confirmPassword(password) {
      this.loadingPasswordConfirmation = true;
      await ApiService.confirmPassword({ password })
        .then(async () => {
          this.dialogPasswordConfirmation = false;
          if (this.authUser.twoFactorSecret) {
            if (!this.authUser.twoFactorConfirmedAt) {
              await this.confirmTwoFactorAuthentication(this.twoFactorAuthCode);
            }
          } else {
            await this.enableTwoFactorAuthentication();
          }
          this.twoFactorAuthCode = "";
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingPasswordConfirmation = false;
        });
    },
    async enableTwoFactorAuthentication() {
      this.loadingTwoFactorAuthenticationEnable = true;
      await ApiService.enableTwoFactorAuth({ type: this.twoFactorType })
        .then(async () => {
          if (this.twoFactorType === "app") {
            await this.fetchTwoFactorQrCodeSvg();
          }
          await this.fetchAuthUser().then(() => {
            this.loadingTwoFactorAuthenticationEnable = false;
          });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingTwoFactorAuthenticationEnable = false;
        });
    },
    async fetchTwoFactorQrCodeSvg() {
      this.loadingQrCode = true;
      await ApiService.fetchTwoFactorQrCodeSvg()
        .then(async (res) => {
          this.twoFactorQrCodeAvailable = true;
          this.qrCodeHtml = res.data.svg;
          await this.fetchAuthUser().then(() => {
            this.loadingQrCode = false;
          });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        });
    },
    async confirmTwoFactorAuthentication(code, type) {
      this.loadingTwoFactorConfirmation = true;
      await ApiService.confirmTwoFactorAuth({ code, type })
        .then(() => {
          const notification = {
            type: "success",
            title: "Erledigt",
            message: "Die Zwei-Faktor-Authentifizierung wurde aktiviert.",
          };
          this.$store.commit("notifications/ADD", notification);
          this.twoFactorAuthConfirmed = true;

          if (this.twoFactorType === "app") {
            this.fetchTwoFactorRecoveryCodes();
          } else {
            this.nextStep();
          }
        })
        .catch((err) => {
          if (err.status === 423) {
            this.dialogPasswordConfirmation = true;
          } else {
            const notification = {
              type: "error",
              title: "Fehler",
              error: getError(err),
            };

            this.$store.commit("notifications/ADD", notification);
          }
        })
        .finally(() => {
          this.loadingTwoFactorConfirmation = false;
        });
    },
    async fetchTwoFactorRecoveryCodes() {
      this.loadingRecoveryCodes = true;

      await ApiService.fetchTwoFactorRecoveryCodes()
        .then((res) => {
          this.recoveryCodes = res.data;
          this.dialogRecoveryCodes = true;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loadingRecoveryCodes = false;
        });
    },
    async resendMail() {
      this.resending = true;

      await ApiService.resendTwoFactorAuthMail()
        .then(() => {
          const notification = {
            type: "success",
            title: "Erledigt",
            message: "Die Mail wurde versendet.",
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.resending = false;
        });
    },
    nextStep() {
      this.dialogRecoveryCodes = false;
      this.$router.push({ name: "dashboard" });
    },
    async logout() {
      await ApiService.logout().then(() => {
        this.$store.dispatch("user/resetState");
        this.$router.push("/login");
      });
    },
    ...mapActions("user", ["fetchAuthUser"]),
  },
};
</script>

<style scoped></style>
