<template>
  <v-card class="pa-5">
    <v-card-title class="pt-0 px-0 text-h5"> Passwort vergeben </v-card-title>
    <p>
      Moin {{ authUser !== null ? authUser.vorname : "" }},<br />herzlich
      willkommen bei Thekla.
    </p>
    <p>
      Dies ist Dein erster Login, darum lege bitte ein
      <strong>sicheres</strong>
      Passwort fest.
    </p>

    <v-text-field
      v-model="authUser.email"
      label="E-Mail Adresse"
      disabled
      readonly
      outlined
      :rules="[rules.required]"
    ></v-text-field>
    <v-menu
      :value="showPasswordMenu"
      :close-on-click="false"
      offset-x
      nudge-right="10"
      right
      max-width="400"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="newPassword"
          v-bind="on"
          counter
          label="Neues Passwort"
          :append-icon="newPasswordVisible ? icons.mdiEye : icons.mdiEyeOff"
          :type="newPasswordVisible ? 'text' : 'password'"
          outlined
          :rules="[rules.required, rules.password]"
          validate-on-blur
          @focus="showPasswordMenu = true"
          @click:append="newPasswordVisible = !newPasswordVisible"
          @input="checkPassword"
        ></v-text-field>
      </template>
      <v-card outlined class="pa-5">
        <v-card-title class="pt-0 px-0"> Passwortregeln </v-card-title>
        <p class="font-weight-bold">Ihr sicheres Passwort sollte:</p>
        <v-list>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordHasMinLength" color="error">{{
                icons.mdiClose
              }}</v-icon>
              <v-icon v-else color="success">{{ icons.mdiCheck }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces"
              >Mindestens 8 Zeichen lang sein</v-list-item-title
            >
          </v-list-item>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordHasUppercase" color="error">{{
                icons.mdiClose
              }}</v-icon>
              <v-icon v-else color="success">{{ icons.mdiCheck }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces"
              >Groß- und Kleinschreibung enthalten</v-list-item-title
            >
          </v-list-item>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordHasNumber" color="error">{{
                icons.mdiClose
              }}</v-icon>
              <v-icon v-else color="success">{{ icons.mdiCheck }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces"
              >Zahlen enthalten</v-list-item-title
            >
          </v-list-item>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordHasSpecial" color="error">{{
                icons.mdiClose
              }}</v-icon>
              <v-icon v-else color="success">{{ icons.mdiCheck }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces"
              >Sonderzeichen (!?$§#...) enthalten</v-list-item-title
            >
          </v-list-item>
          <v-list-item style="min-height: 32px">
            <v-list-item-icon class="my-0 align-self-center">
              <v-icon v-if="!passwordsMatch" color="error">{{
                icons.mdiClose
              }}</v-icon>
              <v-icon v-else color="success">{{ icons.mdiCheck }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="white-space: break-spaces"
              >Mit der Bestätigung übereinstimmen</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-text-field
      v-model="newPasswordConfirmation"
      counter
      label="Neues Passwort bestätigen"
      :append-icon="newPasswordVisible ? icons.mdiEye : icons.mdiEyeOff"
      :type="newPasswordVisible ? 'text' : 'password'"
      outlined
      validate-on-blur
      @click:append="newPasswordVisible = !newPasswordVisible"
      :rules="[rules.required, matchingPasswords]"
      @input="checkPassword"
      @keydown.enter="onUpdatePassword"
    ></v-text-field>
    <v-card-actions class="pb-0 px-0">
      <v-spacer />
      <v-btn large depressed @click="logout">Abbrechen</v-btn>
      <v-btn
        large
        depressed
        :disabled="!passwordValid"
        color="primary"
        @click="onUpdatePassword"
        >Speichern</v-btn
      >
    </v-card-actions>
    <v-overlay
      v-if="loading"
      absolute
      opacity="0.9"
      color="white"
      class="text-center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mdiEye, mdiEyeOff, mdiClose, mdiCheck } from "@mdi/js";
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";

export default {
  name: "FirstLoginChangePassword",
  data: () => ({
    icons: {
      mdiEye,
      mdiEyeOff,
      mdiClose,
      mdiCheck,
    },
    loading: false,
    username: null,
    showPasswordMenu: null,
    newPassword: "",
    newPasswordConfirmation: "",
    newPasswordVisible: false,
    passwordHasNumber: false,
    passwordHasUppercase: false,
    passwordHasSpecial: false,
    passwordHasMinLength: false,
    passwordsMatch: false,
    rules: {
      required: (value) => !!value || "Dies ist ein Pflichtfeld.",
      password: (value) => {
        const pattern =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$§;%^&*+/.,-])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 Zeichen mit wenigstens einem Großbuchstaben, einer Ziffer und einem Sonderzeichen."
        );
      },
    },
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
    anrede() {
      if (this.authUser) {
        if (this.authUser.anrede) {
          return `${this.authUser.anrede} ${this.authUser.nachname}`;
        } else {
          return `${this.authUser.vorname} ${this.authUser.nachname}`;
        }
      }
      return "";
    },
    matchingPasswords() {
      return (
        this.newPassword === this.newPasswordConfirmation ||
        "Die Passwörter stimmen nicht überein."
      );
    },
    passwordValid() {
      return (
        this.passwordHasMinLength &&
        this.passwordHasUppercase &&
        this.passwordHasNumber &&
        this.passwordHasSpecial &&
        this.passwordsMatch
      );
    },
  },
  watch: {
    passwordValid() {
      if (this.passwordValid) {
        setTimeout(() => {
          this.showPasswordMenu = false;
        }, 500);
      }
    },
  },
  methods: {
    checkPassword() {
      this.passwordHasNumber = /\d/.test(this.newPassword);
      this.passwordHasUppercase =
        /[a-z]/.test(this.newPassword) && /[A-Z]/.test(this.newPassword);
      this.passwordHasSpecial = /[!#$%&?]/.test(this.newPassword);
      this.passwordHasMinLength = this.newPassword.length >= 8;
      this.passwordsMatch =
        this.newPassword.length &&
        this.newPassword === this.newPasswordConfirmation;
    },
    async onUpdatePassword() {
      this.loading = true;

      const data = {
        username: this.username,
        password: this.newPassword,
      };

      await ApiService.updatePasswordAfterFirstLogin(data)
        .then(async () => {
          await this.$store.dispatch("user/fetchAuthUser").then(() => {
            this.$router.push({ name: "first-run-enable-two-factor-auth" });
          });
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async logout() {
      await ApiService.logout().then(() => {
        this.$store.dispatch("user/resetState");
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style scoped></style>
