import Vue from "vue";
import Vuetify from "vuetify/lib";
import de from "vuetify/lib/locale/de";

// Vuetify.config.silent = true;

Vue.use(Vuetify);

const vuetify = new Vuetify({
  treeShake: true,
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      light: {
        primary: "#415c73",
        cta: "#f9b000",
      },
    },
  },
  lang: {
    locales: { de },
    current: "de",
  },
});

export default vuetify;
