<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-sheet outlined rounded class="pa-5">
          <v-list rounded nav>
            <v-list-item
              v-for="(navItem, index) in navItems"
              :key="`nav-item-${index}`"
            >
              <v-list-item-icon>
                <v-icon :color="navItem.iconColor">{{ navItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link
                    :to="navItem.path"
                    class="text-decoration-none"
                    active-class="font-weight-bold"
                    exact
                  >
                    {{ navItem.title }}
                  </router-link>
                </v-list-item-title>
                <template v-for="(child, index) in navItem.children">
                  <v-list-item-subtitle :key="`child-${index}`" class="mb-2">
                    <router-link
                      :to="child.path"
                      class="text-decoration-none"
                      active-class="font-weight-bold"
                    >
                      {{ child.title }}
                    </router-link></v-list-item-subtitle
                  >
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="10">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiInformationOutline, mdiCheckCircle, mdiCloseCircle } from "@mdi/js";

export default {
  name: "BauberatungenLayout",
  data() {
    return {
      navItems: [
        {
          title: "Alle",
          path: "/backend/bauberatungen",
          iconColor: "cta",
          icon: mdiInformationOutline,
        },
        {
          title: "Erledigt",
          path: "/backend/bauberatungen/finished",
          iconColor: "success",
          icon: mdiCheckCircle,
        },
        {
          title: "Unerledigt",
          path: "/backend/bauberatungen/unfinished",
          iconColor: "error",
          icon: mdiCloseCircle,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
