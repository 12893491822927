<template>
  <v-alert
    v-model="alert"
    border="top"
    colored-border
    elevation="1"
    prominent
    dense
    :type="notification.type"
  >
    <div class="d-flex flex-grow-1 justify-space-between align-center">
      <div class="alert--title font-weight-bold my-4">
        {{ notification.title }}
      </div>
      <v-btn
        fab
        icon
        x-small
        :color="notification.type"
        @click="removeNotification(notification)"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </div>
    <div
      v-if="notification.message"
      class="alert--message"
      v-html="notification.message"
    ></div>
    <template v-if="notification.error">
      <v-list
        v-if="getType(notification.error) === 'object'"
        key="error-list"
        class="pa-0"
      >
        <v-list-item
          v-for="key in errorKeys"
          :key="key"
          three-line
          class="px-0"
        >
          <v-list-item-content>
            <v-list-item-title>{{ key | titleCase }}</v-list-item-title>
            <v-list-item-subtitle
              v-for="(item, index) in getErrors(key)"
              :key="`error-${index}`"
            >
              {{ item }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <p v-else>{{ notification.error }}</p>
    </template>
  </v-alert>
</template>

<script>
import { mapActions } from "vuex";
import { mdiClose } from "@mdi/js";

export default {
  name: "NotificationAlert",
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiClose,
    },
    timeout: null,
    alert: true,
  }),
  computed: {
    errorKeys() {
      if (
        !this.notification.error ||
        this.getType(this.notification.error) === "string"
      ) {
        return null;
      }

      return Object.keys(this.notification.error);
    },
  },
  mounted() {
    if (this.notification.timeout === undefined) {
      this.timeout = setTimeout(() => this.remove(this.notification), 5000);
    } else if (typeof this.notification.timeout === "number") {
      this.timeout = setTimeout(
        () => this.remove(this.notification),
        parseInt(this.notification.timeout, 10)
      );
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
    getErrors(key) {
      return this.notification.error[key];
    },
    getType(obj) {
      return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
    },
    removeNotification(notificationToRemove) {
      this.remove(notificationToRemove);
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.alert = false;
    },
    ...mapActions("notifications", ["remove"]),
  },
  filters: {
    titleCase(val) {
      return val.replace("_", " ");
    },
  },
};
</script>

<style scoped>
.alert--message {
  word-break: break-word;
}
</style>
