<template>
  <v-card flat outlined class="pa-5">
    <v-card-title class="text-h5 pt-0 px-0"
      >Termine für diese Woche</v-card-title
    >
    <v-sheet outlined rounded max-height="400" class="overflow-y-auto">
      <v-data-table
        :headers="tableHeaders"
        :items="events"
        :footer-props="dataTableFooterProps"
        :loading="loading"
        loading-text="Termine werden geladen..."
        no-data-text="Keine Termine für diese Woche."
      >
        <template v-slot:item.user="{ item }">
          {{ item.user.vorname }} {{ item.user.nachname }}
        </template>
        <template v-slot:item.guest="{ item }">
          <router-link
            v-if="item.guest"
            :to="{
              name: 'show-interessent-details',
              params: { id: item.guest.uuid },
            }"
            >{{ item.guest.vorname }} {{ item.guest.nachname }}</router-link
          >
        </template>
        <template v-slot:item.start="{ item }">
          {{ formatFromSQL(item.start) }}
        </template>
        <template v-slot:item.end="{ item }">
          {{ formatFromSQL(item.end) }}
        </template>
      </v-data-table>
    </v-sheet>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import { getError } from "@/util/helpers";
import { DateTime } from "luxon";
import { mdiArrowCollapseLeft, mdiArrowCollapseRight } from "@mdi/js";

export default {
  name: "DashboardWidgetTermine",
  data: () => ({
    loading: false,
    error: null,
    events: [],
    tableHeaders: [
      { text: "Terminbezeichnung", value: "name", sortable: false },
      { text: "Kunde", value: "guest", sortable: false },
      { text: "Start", value: "start", sortable: false },
      { text: "Ende", value: "end", sortable: false },
      { text: "Ort", value: "place", sortable: false },
    ],

    dataTableFooterProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: mdiArrowCollapseLeft,
      lastIcon: mdiArrowCollapseRight,
      itemsPerPageOptions: [10, 20, 50, -1],
    },
  }),
  computed: {
    authUser() {
      return this.$store.state.user.authUser;
    },
  },
  async mounted() {
    await this.fetchCalendarEventsForThisWeek();
  },
  methods: {
    async fetchCalendarEventsForThisWeek() {
      const queryParams = {
        params: {
          view: "week",
        },
      };

      if (this.$hasPermission(this.authUser, "Verkauf")) {
        queryParams.params.userId = this.authUser.id;
      }

      this.loading = true;
      await ApiService.fetchCalendarEvents(queryParams)
        .then((res) => {
          this.events = res.data.data;
        })
        .catch((err) => {
          const notification = {
            type: "error",
            title: "Fehler",
            error: getError(err),
          };

          this.$store.commit("notifications/ADD", notification);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatFromSQL(date) {
      return DateTime.fromSQL(date).toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      );
    },
  },
};
</script>

<style scoped></style>
