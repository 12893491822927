export const namespaced = true;

export const state = {
  count: 0,
};

export const mutations = {
  SET_COUNT(state, count) {
    state.count = count;
  },
};
