import ApiService from "@/services/ApiService";

export const namespaced = true;

export const state = () => ({
  entries: [],
});

export const mutations = {
  SET_ENTRIES(state, entries) {
    state.entries = entries;
  },
};

export const actions = {
  async fetchSortings({ commit }) {
    commit("loading/LOADING_SORTINGS", true, { root: true });
    await ApiService.fetchSortings()
      .then((res) => {
        commit("SET_ENTRIES", res.data.data);
      })
      .finally(() => {
        commit("loading/LOADING_SORTINGS", false, { root: true });
      });
  },
};
