export default [
  {
    path: "suppliers",
    name: "list-suppliers",
    meta: {
      requiresAuth: true,
      gate: "suppliers_access",
    },
    component: () => import("@/views/backend/suppliers/ListSuppliers.vue"),
  },
  {
    path: "suppliers/new",
    name: "new-supplier",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/backend/suppliers/NewSupplier.vue"),
  },
  {
    path: "suppliers/:id",
    name: "show-supplier",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/backend/suppliers/ShowSupplier.vue"),
  },
  {
    path: "suppliers/:id/edit",
    name: "edit-supplier",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/backend/suppliers/EditSupplier.vue"),
    props: true,
  },
];
