export const namespaced = true;

export const state = {
  confirmedCount: 0,
  unfinsihedCount: 0,
  bauberatung: {},
};

export const mutations = {
  SET_CONFIRMED_COUNT(state, count) {
    state.confirmedCount = count;
  },
  SET_UNFINISHED_COUNT(state, count) {
    state.unfinsihedCount = count;
  },
  SET_BAUBERATUNG(state, bauberatung) {
    state.bauberatung = bauberatung;
  },
};
