<template>
  <v-container fluid>
    <router-view />
  </v-container>
</template>

<script>
export default {
  name: "LayoutTimesheets",
};
</script>

<style scoped></style>
